const $specialOffer = $('.special-offer');


$specialOffer.each(function(){
  const $this = $(this);

  var thisWrap = $this.closest('.box');
  var nav      = thisWrap.find('.special-offer__nav');

  $this.slick({
    lazyLoad: 'ondemand',
    appendArrows: nav
  });
});

$('.special-offer').on('lazyLoaded', function(e, s, img) {
   $(img).closest('a').addClass('end-loading');
});


$('.special-offer').on('lazyLoadError', function(e, s, img) {
   $(img).closest('a').addClass('end-loading');
});
