window.CartScroll = {
    duration: 500,
    getElementsOffset(section) { // crossbrowser
        const target = section.getBoundingClientRect();
        const body = document.body;
        const docEl = document.documentElement;
        const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        const clientTop = docEl.clientTop || body.clientTop || 0;
        const top = target.top + scrollTop - clientTop;
        return Math.round(top);
    },
    toSection(section) {

        const finish = document.getElementById(section);
        const page = document.body || document.documentElement; // FIREFOX SUPPORT
        const finishOffset = this.getElementsOffset(finish);
        page.scrollTop = finishOffset;
    }
};