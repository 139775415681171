$.fn.tabs = function( options ) {
  var opt = $.extend({
    tabClass: '.tabs__nav',
    tabContentClass: '.tabs__content',
    beforeChangeTab: function(){},
    beforeInit: function(){},
    afterInit: function(){},
    afterChangeTab: function(){}
  }, options);


  // https://gist.github.com/niyazpk/f8ac616f181f6042d1e0
  function updateUrlParameter(uri, key, value) {
    if ( key === undefined) return;

    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";

    if (!value) {
      // remove key-value pair if value is empty
      uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
      if (uri.slice(-1) === '?') {
        uri = uri.slice(0, -1);
      }
      // replace first occurrence of & by ? if no ? is present
      if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
    } else if (uri.match(re)) {
      uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      uri = uri + separator + key + "=" + value;
    }

    if (history.replaceState)
      history.replaceState(undefined, undefined, uri + hash);
  }


  function changeTab(wrap, elm, id) {
      var newContent = wrap.find(opt.tabContentClass + '--' + id);

      // page tabs
      wrap.find(opt.tabClass).removeClass('active');

      opt.beforeChangeTab();

      $(elm).addClass('active');

      // change content
      wrap.find(opt.tabContentClass).removeClass('active');
      newContent.addClass('active');
      opt.afterChangeTab();
  }

  // get param from url and update tab
  function updateTabFromUrl(tabName, wrap) {
    if ( tabName === undefined) return;

    var actualUrlParams = document.location.search.substring(1).split('&');
    for (var i = 0; i < actualUrlParams.length; i++) {
      var x = actualUrlParams[i].split('=');

      if (x[0] === tabName) {
        var id = parseInt(x[1]);
        changeTab(wrap, '[data-tab-id="'+id+'"]', id);
      }
    }
  }


  return $(this).each(function(){
    var self = this;
    var wrapClass = self.selector;
    var $wrap = $(self);
    var $tabName = $wrap.attr('data-tab-name');

    updateTabFromUrl($tabName, $wrap);

    $(this).on('click', opt.tabClass, function(e){
      if ($(this).attr('data-follow-link')) {
        if($(this).hasClass('active')) {
          e.preventDefault();
        }

        return;
      }

      e.preventDefault();
      var id = $(this).attr('data-tab-id');

      var $activeContent = $wrap.find(opt.tabContentClass+'--'+id);

      // event before init new section tab
      opt.beforeInit($wrap, $activeContent);

      changeTab($wrap, this, id);

      //update url
      updateUrlParameter(document.location.href, $tabName, id);

      opt.afterInit();
    });
  });
};
