"use strict";
Type.registerNamespace('Linksoft');

{
	var nop = function () { return this; };
	$.fn.ifFound = function (toDo) {
		if (this.length > 0) return this;
		var obj = new Object();
		obj.length = 0;
		obj[toDo] = nop;
		return obj;
	};
}

Linksoft.Cookies = {
	Show: function () {
		if ($('#modalDialogContents:visible').length) { return; } // už tam dialog je!
		Linksoft.Web.ShowModalDialog('<h3 class="is-modal">Nastavení Cookies</h3>'
			+ '<p>Cookies používáme k provozování našich webových stránek, k jejich vylepšování a k zobrazení obsahu v souladu s vašimi zájmy. '
			+ 'Kliknutím na tlačítko „Přijmout“ souhlasíte s jejich použitím. Pomocí "Upravit nastavení Cookies" můžete zvolit, které soubory cookie chcete přijmout.</p><br/>'
			+ '<div class="row" style="width: 100%">'
			+ '<div class="col-xs-4"><a href="/info/cookies" style="color: gray">Upravit nastavení cookies</a></div>'
			+ '<div class="col-xs-4"></div>'
			+ '<div class="col-xs-4 right"><button class="btn btn--green" onclick="Linksoft.Cookies.Allow()">Přijmout</button></div>'
			+ '</div>'
		)
	},
	Allow: function () {
		dataLayer.push({
			'event': 'cookieConsentUpdate',
			'ccuData': {
				'analytics': 'granted',
				'marketing': 'granted'
			}
		});
		Linksoft.Web.DoAjax('/account/AllowAllCookies');
	}
};

Linksoft.Oko = {
	_bottomFrom: 6,
	LoadMoreBottom: function () {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.LoadMoreBottom, { from: Linksoft.Oko._bottomFrom }, function () { Linksoft.Oko._bottomFrom += 5; })
	}
};

Linksoft.Bestsellers = {
	ChangeDisplayStyle: function (style) {
		Linksoft.Web.SetCookie("disp", style, 30);
		location.reload();
	}
};

Linksoft.Results = {
	SaveWatchDog: function (element, _articleId, _email, _variantMasks, event) {
		event.preventDefault();
		// this one must be here, because Linksoft.BtnPending is come from script from bottom page, sorry
		var btnPending = new Linksoft.BtnPending('#saveWatchDogBtn'); // from /Scripts/src/components/btnPending.js
		var $alertWrapper = element.closest('.g-item__alert');
		var $navSojka = element.closest('body').find('[data-menuitem="WatchDogs"]');
		var navSojkaCount = $navSojka.text().replace(/[()]/g, '');
		var navSojkaNumber = +navSojkaCount;
		var $activeSojkaPopUp = $alertWrapper.find('.sojka__popup.is-opened');
		var $saveWatchDogBtn = $activeSojkaPopUp.find('#saveWatchDogBtn');
		btnPending.run();

		Linksoft.Web.DoAjax({
			url: Linksoft.Web.ActionUrls.SetWatchDog,
			data: { articleId: _articleId, email: _email, fromCat: 0, variantMasks: _variantMasks },
			blanket: false,
			success: function () {
				btnPending.loadSuccess(function () { $saveWatchDogBtn.hide() }); // loading button
				$alertWrapper.addClass('active'); // shows message that sojka has been added
				$activeSojkaPopUp.removeClass('is-opened');
				$navSojka.text('(' + (navSojkaNumber + 1) + ')'); // updates sojka number in navigation
			}
		});
	}
};

Linksoft.Detail = {
	ShowBookstoreReservations: function (articleId) {
		if ($('#div_bookstore_reservation_select').length && !$('#div_bookstore_reservation_form .bookstore_reservation_done').length && $('#div_bookstore_reservation_select').data('articleid') === articleId) {
			//console.log("already loaded and not finished");
			Linksoft.Web.ShowModalDialog();
		} else {
			//console.log("loading simple");
			Linksoft.Web.DoSimpleAjax(
				Linksoft.Web.ActionUrls.BookstoreReservations,
				{ articleId: articleId },
				function (data) {
					//console.log("show");
					Linksoft.Web.ShowModalDialog();
					$('#modalDialog').data('keepContents', true);
					//console.log("prepare");
					Linksoft.Detail._PrepareBookstoreReservations();
				},
				null,
				'modalDialogContents'
			);
		}
	},
	ShowBookstoreReservationForm: function () {
		Linksoft.Forms.ReinitialiseMvcValidation();
		$('#div_bookstore_reservation_select').hide();
		$('#div_bookstore_reservation_form').show();
		Linksoft.Web.ResizeModalDialog();
	},
	ShowBookstoreReservationSelect: function (reset) {
		$('#div_bookstore_reservation_form').hide();
		$('#div_bookstore_reservation_select').show();
		if (reset) {
			event.stopPropagation(); // jinak by to zavřelo ten popup

			$('#modalDialog').data('keepContents', false);
			Linksoft.Web.HideModalDialog();
			$('#btn_ShowReservations').click();
		} else {
			Linksoft.Web.ResizeModalDialog();
		}
	},
	_PrepareBookstoreReservations: function () {
		//		console.log("preparing");
		if (window.txt_filter_bookstores) {
			$('#txt_filter_bookstores').on('keyup', function () {
				if (window.txt_filter_bookstores.value === "") {
					$('.list--bookstore .item--bookstore').show();
				} else {
					var value = window.txt_filter_bookstores.value.toLowerCase();
					$('.list--bookstore .item--bookstore').each(function (idx, obj) {
						if ($(obj).data('name').indexOf(value) > -1) {
							$(obj).show();
						} else {
							$(obj).hide();
						}
					});
				}
			});
		}

		//console.log("reservation_buttons", { count: $('.btn_bookstore_reservation').length });

		$('.btn_bookstore_reservation').on("click", function (e) {
			var $this = $(this);
			/*
						console.log('click', {
							articleId: $this.parents('.list--bookstore').data('articleid'),
							bookstoreId: $this.data('bookstoreid'),
							pickUpDeadline: $this.data('pickupdeadline'),
							pickUpExpected: $this.data('pickupexpected')
						});
			*/
			Linksoft.Web.DoSimpleAjax(
				'/Detail/AddBookstoreReservation',
				{
					articleId: $this.parents('.list--bookstore').data('articleid'),
					bookstoreId: $this.data('bookstoreid'),
					pickUpDeadline: $this.data('pickupdeadline'),
					pickUpExpected: $this.data('pickupexpected')
				},
				Linksoft.Detail.ShowBookstoreReservationForm,
				null,
				'div_bookstore_reservation_form',
				'GET'
			);
		});
	},
	HideBookstoreReservation: function () {
		Linksoft.Web.HideModalDialog();
	},
	UpdateRating: function (articleId, rating, fromCat) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.UpdateRating, { articleId: articleId, rating: rating, fromCat: fromCat }, function () { window.Linksoft.SetRating.init() });
	},
	GuestRated: function (articleId, rating, fromCat) {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.GuestRated, { articleId: articleId, rating: rating, fromCat: fromCat }, Linksoft.Web.ShowModalDialog, null, [{ from: 'logintovote', to: '#modalDialog .popupInner:first' }]);
		return false;
	},
	WriteReview: function (articleId, fromCat) {
		$.ajax({
			url: "/Detail/ReviewEditor?articleId=" + articleId + "&fromCat=" + fromCat,
		})
			.done(function (data) {
				$('#newreviewcontainer').html(data);
			});
		return false;
	},
	StoreReview: function (articleId, rating, title, text, nick, fromCat) {
		Linksoft.Web.HideModalDialog();
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.StoreReview, { articleId: articleId, rating: rating, title: title, text: text, nick: nick, fromCat: fromCat }, null, Linksoft.Web.ShowModalDialog);
		return false;
	},
	UpdateReviewRating: function (rating) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.UpdateReviewRating, { rating: rating });
	},
	VoteForUserReview: function (articleId, userReviewId, withTitle) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.VoteForUserReview, { articleId: articleId, userReviewId: userReviewId, withTitle: withTitle });
	},
	SetWatchDog: function (articleId, fromCat) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.SetWatchDog, { articleId: articleId, fromCat: fromCat }, Linksoft.Web.ShowModalDialog, null, [{ from: 'watchdog', to: '#modalDialog .popupInner:first' }]);
	},
	SaveWatchDog: function (articleId, email, fromCat) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.SaveWatchDog, { articleId: articleId, email: email, fromCat: fromCat }, Linksoft.Web.ShowModalDialog, null, [{ from: 'watchdog', to: '#modalDialog .popupInner:first' }]);
	},
	ChangeEBookFormat: function (articleId, fromEBookFormatId, fromRecap) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ChangeEBookFormat, { articleId: articleId, fromEBookFormatId: fromEBookFormatId, fromRecap: fromRecap }, Linksoft.Web.ShowModalDialog, null, [{ from: 'selectebookformat', to: '#modalDialog .popupInner:first' }]);
	},
	OpenMP3Player: function (href) {
		var url = $(href).attr("href");
		window.open(url, "mp3_player", "directories=0,height=100,width=300,location=0,menubar=0,resizable=1,scrollbars=0,status=1,toolbar=0");
	}
};

Linksoft.MujKosmas = {
	SwitchRaffle: function (orderId, raffleId) {
		var status = $('#chkRaffle').prop('checked');
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ChangeRaffleStatus, { orderId: orderId, raffleId: raffleId, status: status });
	},

	WriteReview: function (articleId, fromCat, rating) {
		if (rating !== undefined) {
			var setSelectedRatingValue = function () {
				var $stars = $('#modalDialogContents').find('.rating__stars')
				$stars.barrating('set', rating);
				$('#modalDialogContents').find('#hidden-rating').val(rating);
			};
		}
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.MujKosmasWriteReview, { articleId: articleId, fromCat: fromCat }, setSelectedRatingValue);
	},
	SetWatchDogVariantMask: function (id, variantMask) {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.SetWatchDogVariantMask, { id: id, variantMask: variantMask });
	},
	ToggleFavoritesItemInStoredList: function (itemId, typeId, storedListId) {
		Linksoft.MujKosmas.ToggleItemInStoredList(itemId, typeId, storedListId);
	},

	SubstractFavoritesItemFromFavorites: function (button) {
		const buttonId = $(button).attr('data-listid');
		const $favoritesWrapper = $(button).closest('.favorites'); // to update lists navigation
		if (!$favoritesWrapper.length) return;

		const $favoritesHeaderCount = $favoritesWrapper.find('.tabs__content.active .favorites__header-count');
		const $favoritesNavCount = $favoritesWrapper.find(`#storedList${buttonId} .favorites__nav-count`);
		let itemsCount = +$favoritesHeaderCount.text();
		itemsCount--;

		/* updating counts */
		$favoritesHeaderCount.text(itemsCount);
		$favoritesNavCount.text(itemsCount);
		const $itemOdd = $(button).closest('.odd');
		const $itemEven = $(button).closest('.even');

		/* removing actual items from list */
		if ($itemOdd.length > 0) {
			$itemOdd.remove();
		} else if ($itemEven.length > 0) {
			$itemEven.remove();
		} else {
			console.log('there is no odd or even');
		}
	},

	UpdateFavoritesInNav: function (operation) {
		let newCount = null;
		const $favoritesNavMenuItem = $("[data-menuitem='Favorites']");
		let favoritesNavMenuItemCount = parseInt($favoritesNavMenuItem.text().replace(/[()]/g, ''));
		if (operation === 'ADDED') {
			newCount = favoritesNavMenuItemCount + 1;
			//console.log('adding');
		} else if (operation === 'REMOVED') {
			newCount = favoritesNavMenuItemCount - 1;
			//console.log('substracting');
		} else {
			newCount = favoritesNavMenuItemCount;
			//console.log(`nav didn't update`);
		}
		$favoritesNavMenuItem.text(`(${newCount})`);
	},

	ToggleItemInStoredList: function (itemId, typeId, storedListId) {
		var $storedListToggler = $('#stored-list-toggler-' + itemId + "-" + typeId + "-" + storedListId);
		Linksoft.Web.DoSimpleAjax({
			url: Linksoft.Web.ActionUrls.ToggleItemInStoredList,
			data: { storedListId: storedListId, itemId: itemId },
			successHandler: function (operation) {
				var $itemCounter = $('.stored-list-itemcounter-' + storedListId + ':first');
				var itemsCount = Number($($itemCounter[0]).text());
				var $wrap = $storedListToggler.closest('.pop-up');

				/* */
				if (operation === 'ADDED') {
					$storedListToggler.addClass('active');
					$wrap.addClass('pop-up--has-content'); // make heart red in detail
					itemsCount++;
					$itemCounter.text(itemsCount);
					if (typeId === 1/*=Articles*/) {
						if (window.ga4items) {
							window.dataLayer.push({
								event: 'add_to_wishlist',
								wishlist_type: 'favorites',
								currency: 'CZK',
								value: window.ga4items[0].price,
								ecommerce: {
									items: window.ga4items
								}
							})
						}
					}
				}
				else if (operation === 'REMOVED') {
					const $favoritesListRemover = $('#favorites-item-remover_' + itemId + '-' + storedListId);

					/* substracting favorites item in the DOM */
					Linksoft.MujKosmas.SubstractFavoritesItemFromFavorites($favoritesListRemover);

					$storedListToggler.removeClass('active');
					if ($wrap.find('.active').length === 0) {
						$wrap.removeClass('pop-up--has-content');
					}
					itemsCount--;
				}
				Linksoft.MujKosmas.UpdateFavoritesInNav(operation);
				$itemCounter.text(itemsCount);
				$storedListToggler.parent().removeClass('pending');
			},
			failHandler: function () { alert("Nepodařilo se provést změnu"); },
			blanket: false
		});
	},
	CreateStoredList: function (itemId, typeId, newName) {
		var newInput = $('#stored-list-new-' + itemId + '-' + typeId);
		var storedListName = newName || newInput.val();
		var btnPending = new Linksoft.BtnPending('#btnCreateStoreList');

		btnPending.run();

		Linksoft.Web.DoSimpleAjax({
			url: Linksoft.Web.ActionUrls.CreateStoredList,
			data: { name: storedListName, type: typeId, itemId: itemId },
			successHandler: function (id) {
				// seznam je vytvořen nebo taky ne
				var $old = $("#stored-list-toggler-" + itemId + "-" + typeId + "-" + id);
				if ($old.length) {
					var $counter = $('.stored-list-itemcounter-' + id + ':first');
					$counter.text(Number($counter.text()) + 1);
					$old.addClass("active");
				} else {
					var newLiHTML = '<li><a href="" id="stored-list-toggler-{0}-' + typeId + '-' + id + '" class="{1}" onclick="Linksoft.MujKosmas.ToggleItemInStoredList({0}, ' + typeId + ', ' + id + '); return false;">'
						+ storedListName + ' (<span class="stored-list-itemcounter-' + id + '">1</span>)</a></li>';
					newInput.val(''); // vyčistit inputbox na jméno nového seznamu

					var lastLi = $('#favorites-list-' + itemId + '-' + typeId + ' li:last'); // najít poslední <li>, schovat formulář a ukázat odkaz na přidání nového seznamu
					lastLi.children('a').show();
					lastLi.children('div.form').addClass('hide');

					var $lists = $('.favorites-list-' + typeId); // všechny seznamy stejného typu --

					$.each($lists, function (i, list) {
						var otherItemId = $(list).data('itemid');
						// je tu 2x replace('{0}', otherItemId) z duvodu, ze prvni nahraji jen jedno... samozrejme, by se dalo udelat lip :)
						$(newLiHTML.replace('{0}', otherItemId).replace('{0}', otherItemId).replace('{1}', otherItemId === itemId ? 'active' : '')).insertBefore($(list).find('li:last-child'));
					});
				}

				// make button with heart active
				newInput.closest('.pop-up').addClass('pop-up--has-content');
				btnPending.loadSuccess();

				if (typeId === 1/*=Articles*/) {
					if (window.ga4items) {
						window.dataLayer.push({
							event: 'add_to_wishlist',
							wishlist_type: 'favorites',
							currency: 'CZK',
							value: window.ga4items[0].price,
							ecommerce: {
								items: window.ga4items
							}
						})
					}
				}
			},
			failHandler: function () { alert("Nepodařilo se provést změnu"); },
			blanket: false
		});
	},

	UpdateFavoritesListIconsIds: function (selectedFavInNav) { // to be able to remove correct favorites list
		const elementData = $(selectedFavInNav).attr('data-listid'); // list id that will be passed to icons in the list header
		const $favoritesWrapper = $(selectedFavInNav).closest('.favorites');
		const $favoritesHeader = $favoritesWrapper.find('.favorites__header');
		const $favoritesListActionButtons = $favoritesHeader.find('.btn-i');
		if (!$favoritesListActionButtons.length) return;

		/* update ids for each button found */
		$favoritesListActionButtons.each((index, button) => $(button).attr('data-listid', elementData));
	},

	UpdateFavoritesAfterRename: function (id, name) {
		const itemName = name;
		const $selectedFavInNav = $(`.fav__nav #storedList${id} .favorites__nav-name`);
		$selectedFavInNav.text(itemName);
		$selectedFavInNav.trigger('click');
	},

	UpdateFavoritesTitleByListItem: function (selectedFavInNav) {
		const itemsName = $(selectedFavInNav).find('.favorites__nav-name').text();
		const itemsCount = $(selectedFavInNav).find('.favorites__nav-count').text();

		const $favoritesWrapper = $(selectedFavInNav).closest('.favorites');
		if (!$favoritesWrapper.length) return;

		const $favoritesHeaderName = $favoritesWrapper.find('.favorites__header-name');
		const $favoritesHeaderCount = $favoritesWrapper.find('.favorites__header-count');

		$favoritesHeaderName.text(itemsName);
		$favoritesHeaderCount.text(itemsCount);

		Linksoft.MujKosmas.UpdateFavoritesListIconsIds(selectedFavInNav);

	},

	RemoveFavoritesList: function (button) {
		const listId = parseInt($(button).attr('data-listid'));
		Linksoft.MujKosmas.DeleteStoredList(listId);
	},

	RenameFavoritesList: function (button) {
		const listId = parseInt($(button).attr('data-listid'));
		Linksoft.MujKosmas.RenameStoredList(listId);
	},

	AddFavoritesListToCart: function (button) {
		const listId = parseInt($(button).attr('data-listid'));
		Linksoft.Cart.StoredListToCart(listId);
	},

	TriggerStoredListInput: function (listItemWithInput, listType, event) {
		event.preventDefault();
		$(listItemWithInput).addClass('active');
		$(`stored-list-new-0-${listType}`).focus();
	},

	ResetStoredListInput: function (input) {
		$(input).val('');
		$(input).closest('li').find('a').removeClass('active');
	},

	UpdateFavoritesPage: function (selectedFavInNav, gridId, listId, itemId) {
		const $selectedWrapper = $(selectedFavInNav).closest('li');
		let isFirst = $selectedWrapper.is(':first-of-type');

		const $activeTab = $selectedWrapper.closest('.tabs__content.active');
		const $favoritesHeader = $activeTab.find('.favorites__header');
		isFirst ? $favoritesHeader.addClass('default') : $favoritesHeader.removeClass('default');

		Linksoft.Grids.ChangeParameter(selectedFavInNav, gridId, listId, itemId);
		Linksoft.MujKosmas.UpdateFavoritesTitleByListItem(selectedFavInNav);
		setTimeout(() => {
			Linksoft.MujKosmas.InitializeDragAndDrop();
		}, 1000)
	},

	HandleFavoritesByKeyboard: function () {
		$(document).on('keyup', (e) => {
			if (e.keyCode === 13) {
				$('.link--plus.active ~ .form #btnCreateStoreList').trigger('click');
				$('.popupInner .btn--green').trigger('click');
			}
		});
	},

	InitializeDragAndDrop() { // will initialize after each Ajax update for dynamically created elements
		const FavoritesDragAndDrop = {
			isDragging: false,
			isNotClick: 500,
			isMouseDown: false,
			isDropArea: false,
			elementToMove: '',
			data: {},
			listId: null,
			counter: 0,
			lastMouseMove: 0,
			fps60: 17,

			getItemsData(item) {
				return {
					listId: item.dataset.listid,
					itemId: item.dataset.itemid,
					storedlistitemId: item.dataset.storedlistitemid
				}
			},

			getItemsPosition(item) {
				return {
					itemX: item.getBoundingClientRect().left,
					itemY: item.getBoundingClientRect().top
				}
			},

			getItemsDimention(item) {
				return {
					width: item.getBoundingClientRect().width,
					height: item.getBoundingClientRect().height
				}
			},

			bindDragEvents(items) {
				for (let i = 0; i < items.length; i++) {
					let item = items[i];

					item.addEventListener('mousedown', (e) => {
						this.isMouseDown = true;
						this.isMouseUp = false;
						this.position = this.getItemsPosition(item);
						this.dimentions = this.getItemsDimention(item);
						this.elementToMove = item

						/* check if dragging */
						setTimeout(() => {
							if (this.isMouseUp) return;
							this.isDragging = true;
							this.scope.classList.add('is-dragging');
							this.elementToMove.classList.add('is-dragging');
						}, this.isNotClick);

					});
				}
			},

			dragElement(e) {
				let xAxis = e.clientX - this.position.itemX - (this.dimentions.width * 0.5);
				let yAxis = e.clientY - this.position.itemY - (this.dimentions.height * 0.25);
				this.elementToMove.style.transform = `translate3d(${xAxis}px, ${yAxis}px, 0)`;
			},

			resetElement(element) {
				element.removeAttribute('style');
				element.classList.remove('is-dragging');
				this.scope.classList.remove('is-dragging');
			},

			/* todo implement this function later */
			checkDropArea(e) {
				let matches = e.target.matches ? e.target.matches('.favorites__drop') : event.target.msMatchesSelector('.favorites__drop');
				if (!matches) {
					return false;
				} else {
					return true;
				}
			},

			updateList(e, storedListItemId, oldListId) {
				let matches = e.target.matches ? e.target.matches('.favorites__drop') : event.target.msMatchesSelector('.favorites__drop');
				if (!matches) {
					console.log('cannot drop here');
					return;
				}
				let list = e.target;
				let newListId = parseInt(list.dataset.listid);
				let gridType = parseInt(this.gridTypeId);
				Linksoft.MujKosmas.DoMoveFavoritesItem(gridType, storedListItemId, oldListId, newListId);
				// setTimeout(()=> {
				//     list.classList.remove('is-dragend');
				// },500)
			},

			init() {
				this.scope = document.querySelector('.tabs.favorites')
				if (!this.scope) return;
				this.gridTypeId = parseInt(this.scope.querySelector('.tabs__nav.active ').getAttribute("data-tab-id"));
				const activeGrid = this.scope.querySelector('.tabs__content.active');
				const draggableItems = activeGrid.querySelectorAll('.draggable');
				this.bindDragEvents(draggableItems);

				this.scope.addEventListener('mouseup', (e) => {
					this.isMouseDown = false;
					this.isMouseUp = true;

					if (!this.isDragging) return;

					const draggedItem = activeGrid.querySelector('.draggable.is-dragging');
					if (!draggedItem) return;
					this.resetElement(draggedItem);
					const draggedItemData = this.getItemsData(draggedItem);
					this.updateList(e, draggedItemData.storedlistitemId, draggedItemData.listId);
					this.isDragging = false;
				});

				this.scope.addEventListener('mousemove', (e) => {
					if (this.isMouseDown && this.isDragging && !this.isMouseUp) {
						/* debounce */
						this.isMouseUp = false;
						if (this.lastMouseMove + this.fps60 > Date.now()) return;
						this.lastMouseMove = Date.now();
						this.dragElement(e);
					}
				});
			}
		};
		FavoritesDragAndDrop.init();
	},

	/* just for section Oblibene */
	AddStoredList: function (itemId, typeId, newName) {

		let listExists = false;
		let lists = [];
		const $newInput = $('#stored-list-new-' + itemId + '-' + typeId);
		const storedListName = newName || $.trim($newInput.val());
		let nameLenght = storedListName.length;
		//console.log(nameLenght);
		let isTooShort = nameLenght === 0;

		if (isTooShort) {
			alert('Zadejte název seznamu.');
			return;
		}

		/* validace */
		const $favoritesListItemsNames = $newInput.closest('.fav__nav').find('li .favorites__nav-name');

		$favoritesListItemsNames.each((index, item) => {
			let itemText = $(item).text();
			lists.push(itemText);
		});

		let isListExists = lists.indexOf(storedListName) > -1;
		if (isListExists) {
			alert(`Seznam se jménem "${storedListName}" již existuje.`);
			listExists = true;
		}

		if (listExists) return;
		const btnPending = new Linksoft.BtnPending('#btnCreateStoreList');
		const gridId = $newInput.closest('li').find('a').attr('data-gridid');
		btnPending.run();
		Linksoft.Web.DoSimpleAjax({
			url: Linksoft.Web.ActionUrls.CreateStoredList,
			data: { name: storedListName, type: typeId, itemId: itemId },
			successHandler: function (id) {

				const newList = `<li>
									<a href="#" data-listid="${id}" id="storedList${id}" onclick="Linksoft.MujKosmas.UpdateFavoritesPage(this, '${gridId}', 'listId', ${id}); return false;">
										<span class="favorites__nav-name">${storedListName}</span> (<span class="favorites__nav-count">0</span>)
									</a>
								</li>`;
				const $liWrapper = $newInput.closest('li');
				const $favoritesWrapper = $newInput.closest('.tabs__content.active').removeClass('is-onelist');
				$liWrapper.before(newList);
				$(`#storedList${id}`).trigger('click');
				btnPending.loadSuccess();
				Linksoft.MujKosmas.ResetStoredListInput($newInput);

			},
			failHandler: function () {
				alert("Nepodařilo se provést změnu");
			},
			blanket: false
		})
	},
	AddItemToStoredList: function (storedListId, itemId) {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.AddItemToStoredList, { storedListId: storedListId, itemId: itemId });
	},
	RemoveItemFromStoredList: function (storedListId, itemId) {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.RemoveItemFromStoredList, { storedListId: storedListId, itemId: itemId });
	},
	Logout: function () {
		location.href = "/odhlasit";
	},
	CancelRegistration: function () {
		location.href = "/Account/DeleteAllIdentities";
	},
	DeactivateWatchDog: function (id) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.DeactivateWatchDog, { id: id });
	},
	ActivateWatchDog: function (id) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.ActivateWatchDog, { id: id });
	},
	DeleteWatchDog: function (id) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.DeleteWatchDog, { id: id });
		var $navSojka = $('header .m-k__sojka-count');
		var navSojkaCount = +$navSojka.text();
		$navSojka.text(navSojkaCount - 1);
	},
	ComplaintCreate: function (orderId) {
		Linksoft.Web.DoAjax({ type: 'GET', url: Linksoft.Web.ActionUrls.ComplaintCreate, data: { orderId: orderId }, successHandler: Linksoft.Web.ShowModalDialog, renamings: [{ from: 'complaint', to: '#modalDialog .popupInner:first' }] });
	},
	GetComplaint: function (complaintId, updateGrid) {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.GetComplaint, { complaintId: complaintId, updateGrid: updateGrid });
	},
	AddComplaintStep: function (complaintId, newState, text, updateGrid) {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.AddComplaintStep, { complaintId: complaintId, newState: newState, text: text, updateGrid: updateGrid });
	},
	CreateVoucher: function (conversionId) {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.CreateVoucher, { conversionId: conversionId }, Linksoft.Web.ShowModalDialog, null, [{ from: 'createvoucher', to: '#modalDialog .popupInner:first' }]);
	},
	ShowLoyaltyProgramDetails: function () {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ShowLoyaltyProgramDetails, {}, Linksoft.Web.ShowModalDialog, null, [{ from: 'loyaltyprogramdetails', to: '#modalDialog .popupInner:first' }]);
	},
	DeleteStoredList: function (id) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.DeleteStoredList, { id: id },
			function () { Linksoft.Web.ShowModalDialog(); },
			null,
			"modalDialog .popupInner"
		);
	},
	RenameStoredList: function (id) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.RenameStoredList, { id: id },
			function () { Linksoft.Web.ShowModalDialog(); },
			null,
			"modalDialog .popupInner"
		);
	},
	DoRenameStoredList: function (id, name) {
		Linksoft.Web.HideModalDialog();
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.DoRenameStoredList, { id: id, name: name },
			function (msg, bar, baz) {
				Linksoft.MujKosmas.UpdateFavoritesAfterRename(id, name);
				Linksoft.Web.ShowTemporaryDialog(msg || "Seznam byl přejmenován");
			},
			function (msg) {
				Linksoft.Web.ShowTemporaryDialog(msg || "Seznam NEBYL přejmenován");
			}
		)
	},
	DoDeleteStoredList: function (id) {
		Linksoft.Web.HideModalDialog();
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.DoDeleteStoredList, { id: id },
			function (msg) {
				Linksoft.Web.ShowTemporaryDialog(msg || "Seznam byl smazán");
				var list = $('#storedList' + id);
				if (list.length > 0) {
					Linksoft.MujKosmas.ActivateNewFavoritesListAfterDelete(list);
					list.remove();
				} else {
					location.href = "/mujkosmas/kosiky/";
				}
			},
			function (msg) { Linksoft.Web.ShowTemporaryDialog(msg || "Seznam se nepodařilo smazat"); },
			"modalDialog .popupInner"
		);
	},


	MoveFavoritesItem: function (typeId, itemId) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.MoveFavoritesItem, { typeId: typeId, itemId: itemId },
			function () { Linksoft.Web.ShowModalDialog(); },
			null,
			"modalDialog .popupInner"
		);
	},

	DoMoveFavoritesItem: function (typeId, itemId, oldStoredListId, newStoredListId) {
		let counter = 0;
		let oldListId = parseInt(oldStoredListId) || parseInt($('.tabs__content.active .fav__nav li a.active').attr('data-listid')); /* todo Jendo hodil by se mi parameter oldListId z backendu */
		let newListId = parseInt(newStoredListId) || parseInt($('#move-favorites-list input:radio:checked').val());
		if (!newListId) {
			alert("Vyberte si prosím seznam, do kterého chcete tuto položku přesunout.");
			return;
		}
		// if(itemExistsInNewStoredList) {
		//    alert("Položka již existuje ve vybraném seznamu.");
		//    return;
		// }
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.DoMoveFavoritesItem, { typeId: typeId, itemId: itemId, newStoredListId: newListId },
			function () {
				/* for some reason this success handler runs twice */
				counter++;
				if (counter > 1) return;
				Linksoft.MujKosmas.UpdateFavoritesAfterMoving(oldListId, newListId);
				Linksoft.Web.HideModalDialog()
			},
			function () { Linksoft.Web.ShowTemporaryDialog("Položku se nepodařilo přesunout."); },
		);
	},

	UpdateFavoritesAfterMoving: function (oldListId, newListId) {
		const $oldListToUpdate = $(`#storedList${oldListId}`);
		const $newListToUpdate = $(`#storedList${newListId}`);

		const $oldCounter = $oldListToUpdate.find('.favorites__nav-count');
		const $newCounter = $newListToUpdate.find('.favorites__nav-count');

		let oldCount = +$oldCounter.text();
		let newCount = +$newCounter.text();

		oldCount--;
		newCount++;

		$oldCounter.text(oldCount);
		$newCounter.text(newCount);

		$newListToUpdate.trigger('click');

		/* todo remove setTimeout */
		setTimeout(() => {
			$oldListToUpdate.trigger('click');
		}, 500);

	},

	FavoritesCheckAfterTabChange: function FavoritesCheckAfterTabChange() {
		const $activeTab = $('.tabs__content.active');
		const $activeListsWrapper = $activeTab.find('.fav__nav');
		const $activeListItem = $activeListsWrapper.find('li a.active');
		Linksoft.MujKosmas.CheckIfFavoritesAreDefault($activeListsWrapper);
		Linksoft.MujKosmas.CheckIfMovingFavoritesPossible($activeListsWrapper);
		Linksoft.MujKosmas.UpdateFavoritesTitleByListItem($activeListItem);
	},

	CheckIfMovingFavoritesPossible: function (wrapper) {
		const listItemsMinCount = 2; // one list and one list with input for adding lists;
		const $allListItems = $(wrapper).find('li');
		const $activeTab = $(wrapper).closest('.tabs__content.active');
		const $listItemsCount = $allListItems.length;
		let isOneListItem = $listItemsCount <= listItemsMinCount;
		isOneListItem ? $activeTab.addClass('is-onelist') : $activeTab.removeClass('is-onelist');
	},

	CheckIfFavoritesAreDefault: function (wrapper) {
		const $firstListItem = $(wrapper).find('li:first-of-type a');
		const $favoritesHeader = $(wrapper).closest('.tabs__content.active').find('.favorites__header');
		let defaultListIsActive = $firstListItem.hasClass('active');
		defaultListIsActive ? $favoritesHeader.addClass('default') : $favoritesHeader.removeClass('default');
	},

	ActivateNewFavoritesListAfterDelete: function (element) {
		/* GET NEEDED ELEMENTS AND CHECK IF THEY EXIST */
		const $listItem = element.closest('li');
		if (!$listItem.length) return;
		const $favoritesListsWrapper = $listItem.closest('.fav__nav'); // get wrapper before removing item
		if (!$favoritesListsWrapper.length) return;
		$listItem.remove();
		const $firstFavoritesItem = $favoritesListsWrapper.find('li:first-of-type a');
		$firstFavoritesItem.trigger('click');
		Linksoft.MujKosmas.CheckIfMovingFavoritesPossible($favoritesListsWrapper);
	},
	SendFileToReader: function (id, format) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.SendFileToReader, { articleInOrderId: id, ebookFormatId: format },
			function () { Linksoft.Web.ShowModalDialog(); },
			null,
			"modalDialog .popupInner"
		);
	},
	ResendElectronicGift: function (id) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.ResendElectronicGift, { ArticlesInOrderId: id },
			function () { Linksoft.Web.ShowModalDialog(); },
			function () { Linksoft.Web.ShowTemporaryDialog("Nepodařilo se odeslat upozornění"); },
			"modalDialog .popupInner"
		);
	},
	TestVideoUrl: function () {
		if ($("#Item_VideoUrl").val() === "") {
			alert("Zadejte URL (adresu) videa.");
		} else {
			Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.OkoVideoPlayer, { url: $("#Item_VideoUrl").val() },
				function () { Linksoft.Web.ShowModalDialog(); },
				function () { Linksoft.Web.ShowTemporaryDialog("Nepodařilo se vytvořit náhled."); },
				"modalDialog .popupInner"
			);
		}
	},
	FacebookLogin: function () {
		FB.login(function (response) {
			//			console.log("FB.login", { response: response });
			if (response.authResponse) {
				FB.api('/me', function (response) {
					location.href = $.query("/account/LogOnFacebook", { name: response.name, email: response.email, returnUrl: $.query()["returnUrl"], linkAccounts: $.query()["linkAccounts"] });
				});
			}
		}, { auth_type: 'reauthorize' });
	}
}

Linksoft.Common = {
	ClickOnce: function (element, timeout) {
		//		console.log('ClickOnce', {element: element});
		if (!timeout) timeout = 5;
		var $this = $(element);
		if ($this.data('clicked')) {
			//			console.log('already clicked');
			return false;
		} else {
			//			console.log('first click');
			$this.data('clicked', true);
			if ($this.hasClass('btn')) {
				$this.addClass('btn--disable');
				window.setTimeout(function () { $this.removeClass('btn--disable'); $this.data('clicked', false); }, timeout * 1000);
			} else {
				$this.addClass('disabled');
				window.setTimeout(function () { $this.removeClass('disabled'); $this.data('clicked', false); }, timeout * 1000);
			}
			return true;
		}
	}
}

Linksoft.Cart = {

	RegisterOrLogin: function () {
		Linksoft.Web.ShowTemporaryDialog("Vaše objednávka obsahuje elektronické zboží, pro jehož nákup je nutné se přihlásit nebo zaregistrovat.", 3000);
		$('#registrationWantRegister').click().click();
		$('#registration_Email').focus();
	},

	AddArticle: function (articleId, eBookFormatId, defectTypeId, isMoreFormats, showWindowId, giftFor, sendAt, mailSubject, mailBody) { // eBookFormat -2=shut up and add -1=select  0=noebook >0=formatid
		//		console.log('AddArticle called');
		if (Linksoft.DontAddArticleWithAjax) {
			return true;
		} else if (articleId === undefined || articleId === null) {
			return true;
		} else {
			var $thisBtn = $('#btn-id-' + articleId);

			var buttonPending = function () {
				$thisBtn
					.addClass('btn--pending')
					.removeClass('btn--success')
					.text('Přidávám ...');
			}

			var buttonDone = function () {
				var thisId = $thisBtn.data('id');
				$thisBtn
					.removeClass('btn--pending').removeClass('btn--disable')
					.addClass('btn--success')
					.text('Přidáno')
					// save old event
					.data('old-event', $thisBtn.attr('onclick'))
					// changed evnt
					.attr('onclick', 'Linksoft.Cart.MiniCart.show(function(){Linksoft.Cart.MiniCart.scrollToItem(' + thisId + ')}); return false;')

			}

			if (eBookFormatId === -1) { // && isMoreFormats == 1
				return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.SelectEBookFormat, { articleId: articleId, showWindowId: showWindowId, giftFor: giftFor, sendAt: sendAt, mailSubject: mailSubject, mailBody: mailBody }, Linksoft.Web.ShowModalDialog, null, [{ from: 'selectebookformat', to: '#modalDialog .popupInner:first' }]);
			} else {
				var toDo = function () {
					buttonPending();

					return Linksoft.Web.DoAjax({
						url: Linksoft.Web.ActionUrls.AddArticle,
						data: ($("#cart").length === 0 ? {
							articleId: articleId, quantity: 1, vyklad: showWindowId, eBookFormatId: ((eBookFormatId < 0) ? 0 : eBookFormatId),
							defectTypeId: defectTypeId, giftFor: giftFor, sendAt: sendAt, mailSubject: mailSubject, mailBody: mailBody
						} : {
							articleId: articleId, quantity: 1, refreshCart: true, vyklad: showWindowId, eBookFormatId: ((eBookFormatId < 0) ? 0 : eBookFormatId),
							defectTypeId: defectTypeId, giftForn: giftFor, sendAt: sendAt, mailSubject: mailSubject, mailBody: mailBody
						}),
						blanket: false,
						successHandler: function (data) {
							// prevenet grey overlay.... magicccc
							$('#alsoSeen').html('');

							buttonDone();

							// script from /src/cart/minicart.js
							setTimeout(function () {
								Linksoft.Cart.MiniCart.showStatic(articleId, defectTypeId);
							}, 400)
						}
					});
				};

				if (defectTypeId > 0) {
					Linksoft.Web.Confirm(
						"Vkládáte do košíku knížku s označením BAZAR - " + Linksoft.Data.DefectTypes[defectTypeId].DisplayName + "<br/>\n"
						+ Linksoft.Data.DefectTypes[defectTypeId].Description,
						"Ano, rozumím", "Zpět", toDo);
					return false;
				} else {
					return toDo();
				}
			}
		}
	},
	DownloadFreeArticle: function (articleId, eBookFormatId, isMoreFormats) { // eBookFormat -2=shut up and add -1=select  0=noebook >0=formatid
		if (Linksoft.DontAddArticleWithAjax) {
			return true;
		} else if (articleId === undefined || articleId === null) {
			return true;
		} else {
			var $thisBtn = $('#btn-id-' + articleId);

			var buttonPending = function () {
				$thisBtn
					.addClass('btn--pending')
					.removeClass('btn--success')
					.text('Připravuji ...');
			};

			var buttonDone = function () {
				var thisId = $thisBtn.data('id');
				$thisBtn
					.removeClass('btn--pending')
					.text('Stáhnout e-knihu');
			};

			if (eBookFormatId === 0) { // je to MP3
				return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ShowFreeAudioBook, { articleId: articleId }, Linksoft.Web.ShowModalDialog, null, [{ from: 'showfreeaudiobook', to: '#modalDialog .popupInner:first' }]);
			} else if (isMoreFormats === 1) { // je to audiokniha
				return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.SelectEBookFormat, { articleId: articleId }, Linksoft.Web.ShowModalDialog, null, [{ from: 'selectebookformat', to: '#modalDialog .popupInner:first' }]);
			} else {
				var toDo = function () {
					buttonPending();

					return Linksoft.Web.DoAjax({
						url: Linksoft.Web.ActionUrls.AddArticle,
						data: {
							articleId: articleId, quantity: 1, eBookFormatId: ((eBookFormatId < 0) ? 0 : eBookFormatId)
						},
						blanket: false,
						successHandler: function (data) {
							// prevenet grey overlay.... magicccc
							$('#alsoSeen').html('');

							buttonDone();
						}
					});
				};

				return toDo();
			}
		}
	},
	AddElectronicGift: function (articleId, eBookFormatId, isMoreFormats, showWindowId) { // eBookFormat -1=select  0=noebook >0=formatid
		if (articleId === undefined || articleId === null) {
			return true;
		} else {
			return Linksoft.Web.DoAjax({
				url: Linksoft.Web.ActionUrls.AddElectronicGiftToCart, type: 'GET',
				data: { articleId: articleId, showWindowId: showWindowId },
				successHandler: Linksoft.Web.ShowModalDialog,
				renamings: [{ from: 'selectebookformat', to: '#modalDialog .popupInner:first' }]
			});
			//return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.AddElectronicGiftToCart, { articleId: articleId, showWindowId: showWindowId }, Linksoft.Web.ShowModalDialog, null, [{ from: 'selectebookformat', to: '#modalDialog .popupInner:first' }]);
		}
	},
	HideAddedToCart: function () { $('#alsoSeen').fadeOut(300); return false; },
	AddArticleSerie: function (serieId) {
		return Linksoft.Web.DoAjax(
			Linksoft.Web.ActionUrls.AddArticleSerie,
			{ serieId: serieId },
			function (data) {
				$('#alsoSeen').centerFixed().fadeIn(300).shadow();
			});
	},
	RemoveArticle: function (articleId, eBookFormatId, defectTypeId, giftFor) {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.RemoveArticle, { articleId: articleId, eBookFormatId: eBookFormatId, defectTypeId: defectTypeId, giftFor: giftFor });
		return false;
	},
	ChangeQuantity: function (articleId, quantity, eBookFormatId, defectTypeId) {
		return (!!Linksoft.DontAddArticleWithAjax) || Linksoft.Web.DoAjax({
			url: Linksoft.Web.ActionUrls.ChangeQuantity,
			data: { articleId: articleId, quantity: quantity, eBookFormatId: eBookFormatId, defectTypeId: defectTypeId },
			use_later: "change_quantity",
			blanket: false
		});
	},
	ChangeQuantityInStoredCart: function (id, quantity) {
		return Linksoft.Web.DoAjax({
			url: Linksoft.Web.ActionUrls.ChangeQuantityInStoredCart,
			data: { id: id, quantity: quantity },
			use_later: "change_quantity",
			blanket: false
		});
	},
	RemoveFromStoredCart: function (id) {
		return Linksoft.Web.DoAjax({
			url: Linksoft.Web.ActionUrls.RemoveFromStoredCart,
			data: { id: id },
			use_later: "remove_item",
			blanket: false,
			successHandler: function (data, jqXHR) { $("#cart_row" + id).remove(); }
		});
	},
	ChangeEFormat: function (articleId, fromEBookFormatId, eBookFormatId, fromRecap) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ChangeEFormat, { articleId: articleId, fromEBookFormatId: fromEBookFormatId, eBookFormatId: eBookFormatId, fromRecap: fromRecap });
	},
	ChangeEGiftOptions: function (articleInOrderId) {
		Linksoft.Web.HideModalDialog();
		Linksoft.Web.DoAjax({ type: 'GET', url: Linksoft.Web.ActionUrls.ChangeEGiftOptions, data: { articleInOrderId: articleInOrderId }, successHandler: Linksoft.Web.ShowModalDialog, failHandler: Linksoft.Web.ShowModalDialog });
		return false;
	},
	StoredListToCart: function (id) {
		Linksoft.Web.DoAjax(
			Linksoft.Web.ActionUrls.StoredListToCart,
			{ id: id, refreshCart: ($("#cart").length === 0 ? 'false' : 'true') },
			function (data) {
				$('#alsoSeen').centerFixed().fadeIn(300).shadow();
			});
	},
	CreateStoredCart: function (fromStatus) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.CreateStoredCart, { FromStatus: fromStatus },
			function () { Linksoft.Web.ShowModalDialog(); },
			null,
			"modalDialog .popupInner", "GET"
		);
	},
	CreatedStoredList: function (ajaxStatus) {
		var response = ajaxStatus.get_response();
		var ctype = response.getResponseHeader('Content-type');
		if (ctype.match(/^application\/json/)) {
			Linksoft.Web.HandleReplacements({}, false)(jQuery.parseJSON(ajaxStatus.get_data()));
			Linksoft.Web.HideModalDialog();
		} else {
			$('#CreateStoredListForm').html(ajaxStatus.get_data());
		}
	},
	ClearForLater: function () {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ClearForLater, {});
	},
	ClearCart: function () {
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ClearCart, {});
	},
	ChangeCountry: function (countryId) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ChangeCountry, { countryId: countryId });
	},
	ChangeGift: function (salutation, text, signature, wrappingPaperId, wrappingPaperOnlyAdd, addBillOfDelivery) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ChangeGift, { salutation: salutation, text: text, signature: signature, wrappingPaperId: wrappingPaperId, wrappingPaperOnlyAdd: wrappingPaperOnlyAdd, addBillOfDelivery: addBillOfDelivery, fromCart: $("#cartRecapitulation").length === 0 });
	},
	UpdateGift: function (renamings, successHandler) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.UpdateGift, null, successHandler, null, renamings);
	},
	ClearMessageOnClient: function () {
		$("#VzkazOsloveni").val('');
		$("#VzkazText").val('');
		$("#VzkazPodpis").val('');
		return false;
	},
	ChangeDeliveryService: function (deliveryServiceId, deliveryPointId) {
		if (!deliveryPointId) { $('.selectedPointPersonal').empty() }
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ChangeDeliveryService, { deliveryServiceId: deliveryServiceId, deliveryPointId: deliveryPointId });
	},
	ChangeDeliveryServiceExternal: function (deliveryServiceId, countryId, providerCode, externalDeliveryPointId) {
		if (!externalDeliveryPointId) { $('#selectedPointPersonal' + providerCode).empty() }
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ChangeDeliveryServiceExternal, { deliveryServiceId: deliveryServiceId, providerCode: providerCode, countryId: countryId, externalDeliveryPointId: externalDeliveryPointId });
	},
	ChangePayment: function (paymentId) {
		if (paymentId === null || paymentId === '') return;
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.ChangePayment, { paymentId: paymentId });
	},
	MoveBetweenCartAndForLater: function (articleId, moveToCart, eBookFormatId, defectTypeId) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.MoveBetweenCartAndForLater, { articleId: articleId, moveToCart: moveToCart, eBookFormatId: eBookFormatId, defectTypeId: defectTypeId });
	},
	AddVoucher: function (codeId) {
		var code = $("#" + codeId);
		var voucherCode = code.val();

		if (voucherCode === '') {
			code.addClass("input-validation-error");
			return;
		}
		else {
			return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.AddVoucher, { voucherCode: voucherCode }, function (data) {
				if (!data.IsSuccess) {
					code.addClass("input-validation-error");
				}
			});
		}
	},
	RemoveVoucher: function (voucherId) {
		return Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.RemoveVoucher, { voucherId: voucherId });
	},
	PrepareHandlers2: function (o) {
		Linksoft.Cart.PrepareHandlers(o.get_updateTarget());
	},

	PrepareHandlers: function (context) {
		$('.knihobox:not(.navbottom) .fixBottomLink', context).each(function (index) {
			$(this).css('right', function (gwidth) {
				gwidth = gwidth === null ? $(this).parent().children('.gliderNav .squares').outerWidth() : gwidth;
				gwidth = gwidth + 18 + 56 + 20 + 20;
				return gwidth;
			});
		});

		$('#modalDialog', context).appendTo(document.body);
		/*
		ADDED: 2011-16-16
		AUTHOR: Matous Havlena (matous@havlena.net)
		DESC: these 2 triggers hide dialogs when user click outside them
		*/
		$("#page").click(function () {
			if ($("#alsoSeen").is(":visible")) {
				$("#alsoSeen").fadeOut(300);
				if ($("#alsoSeen").attr('id') === "modalDialog") $('#qtip-blanket').fadeOut(300);
			}
		});

		$("#qtip-blanket").click(function () {
			if ($("#modalDialog").is(":visible") && $("#modalDialog .customMessage .is-modal").length == 0) {
				Linksoft.Web.HideModalDialog();
			}
		});


		$("#giftCloseButton", context).click(function () {
			Linksoft.Cart.ChangeGift($('#VzkazOsloveni').val(), $('#VzkazText').val(), $('#VzkazPodpis').val(), $('input[name=\'wrappingPaper\']:checked').val(), $('#wrappingPaperOnlyAdd').is(':checked'), $('input[name=\'billOfDelivery\']:checked').val() === '1');
			Linksoft.Web.HideModalDialog();
			return false;
		});

		$('.titul-showmore', context).click(function () {
			if ($(this).attr('href') === null) {
				var text = $(this).attr('content');
				$(this).parent().html(text);
			} else {
				Linksoft.Web.DoSimpleAjax({
					url: $(this).attr('href'),
					successHandler: function (a, b) {
						$('#modalDialog .popupInner:first').html(a);
						Linksoft.Web.ShowModalDialog();
					},
					dataType: 'html'
				});
			}
			return false;
		});

		// $('a.giftTarget', context).click(
		// function () {
		// 	Linksoft.Cart.UpdateGift([{ from: 'gift', to: '#modalDialog .popupInner:first' }], Linksoft.Web.ShowModalDialog);
		// 	return false;
		// });

		$(".imageToResize", context).click(
			function () {
				var item = $(this).next();
				item.animate({ width: "300px", height: "300px", left: "-110px", top: "-100px", opacity: 1 }, 300);
			});

		$(".imageFullSize", context).click(
			function () {
				var item = $(this);
				item.animate({ width: "0", height: "0", left: "40px", top: "40px", opacity: 0 }, 300);
			});

		var countryCode = $('#country').find(':selected').data('country-code');
		$('#cart-delivery-flag-img').attr('src', '/images/flags/1x1/' + countryCode + '.svg');
		$('#country', context).change(function () {
			var that = $(this);
			var countryId = that.val();
			var value = parseInt(this.value);
			Linksoft.Cart.ChangeCountry(value);

			countryCode = $(that).find(':selected').data('country-code');
			$('#cart__country-delivery-flag-img').attr('src', '/images/flags/1x1/' + countryCode + '.svg');
		});
		$('.accordion', context).click(function () { $(this).next().toggle(400); });

		setTimeout(function () { // sometimes there are many and the stupid qtip takes ages
			$('.xqtip', context).qtip({
				content: {
					text: function () {
						return $(this).children('.qtip_content').html();
					}
				},
				position: {
					my: 'top right',  // Position my top left...
					at: 'top left', // at the bottom right of...
					adjust: { x: -5, y: 0 }
				},
				show: {
					event: 'click',
					effect: function (offset) {
						$(offset.elements.target).closest('li').addClass('hover');
						$(this).slideDown(400);
					}
				},
				hide: {
					fixed: true,
					event: 'click unfocus',
					target: $('.close'),
					effect: function (offset) {
						$(this).slideUp(400);
						$(offset.elements.target).closest('li').removeClass('hover');
					}
				},
				style: {
					classes: 'ui-tooltip-ship ui-tooltip-shadow',
					tip: {
						width: 10,
						height: 20,
						corner: 'right top',
						mimic: 'center'
					}
				},
				prerender: false
			})
		}, 500);

		$("#voucherCode", context).watermark();
		$("#fulltext_query_input", context).watermark();
		$("#fulltext_query_input2", context).watermark();

		var installCount = function (inputs, updateFun) {
			inputs.focus(function () {
				$(this).select();
			});
			inputs.keypress(function (event) {
				if (event.which === 13) {
					event.preventDefault();
					this.blur();
				} else {
					$(this).data("modified", true);
				}
			});
			inputs.blur(function () {
				var $this = $(this);
				if (!$this.data("modified")) return;

				var value = parseInt(this.value);
				if (isNaN(value)) {
					$this.addClass("input-validation-error");
					$this.focus();
				}
				else {
					$this.removeClass("input-validation-error");

					if ($this.attr('max')) {
						var max = Number($this.attr('max'));
						if (value > max) {
							$this.val(max);
							value = max;
							var $msg = $this.parent().parent().find(".count_msg");
							$msg.html($this.attr('max-msg') || "<br/>Pouze " + max + (max === 1 ? " kus." : max < 5 ? " kusy." : " kusů."));
							$msg.removeClass("hide");
						}
					}

					if (value === $this.data("previous")) {
						$this.data("modified", false);
						return;
					}
					$this.data("previous", value);

					updateFun($this, value);
					$this.data("modified", false);
				}
			});
			inputs.each(function () {
				var $this = $(this);
				$this.data("previous", Number($this.val()));

				var $minus = $this.parent().parent().find('.btn_minus');
				$minus.click(function () {
					var val = Number($this.val()) - 1;
					if (val <= 0) return;
					$this.val(val);
					$this.data("modified", true);
					$this.blur();

					var $msg = $this.parent().find(".count_msg");
				});

				var $plus = $this.parent().parent().find('.btn_plus');
				$plus.click(function () {
					var val = Number($this.val()) + 1;
					$this.val(val);
					$this.data("modified", true);
					$this.blur();
				});
			});
		}
		installCount($(":input.cart-input-count[articleid]", context), function ($this, value) {
			var articleId = $this.attr("articleid");
			var eformatid = $this.attr("eformatid");
			var defectTypeId = $this.attr("defecttypeid");
			Linksoft.Cart.ChangeQuantity(articleId, value, eformatid, defectTypeId);
		});
		installCount($(":input.cart-input-count[data-id]", context), function ($this, value) {
			var id = $this.data("id");
			Linksoft.Cart.ChangeQuantityInStoredCart(id, value);
		});

		var inputs = $(":input.paymentOption-button", context);
		inputs.click(function () {
			Linksoft.Cart.ChangePayment($("input[name='paymentOption']:checked").val());
		});

		inputs = $(":input.deliveryService-button", context);
		inputs.click(function () {
			var value = $("input[name='deliveryService']:checked").val();
			var i = value.indexOf('-');
			var serviceId = value;
			var pointId = null;
			if (i !== -1) {
				serviceId = value.substring(0, i);
				pointId = value.substring(i + 1);
			}
			Linksoft.Cart.ChangeDeliveryService(serviceId, pointId);
		});

	},
	CopyAddress: function (id, prefix, list) {
		var data = list[id];
		$("#" + prefix + "_Name").val(data[0]);
		$("#" + prefix + "_Company").val(data[1]);
		$("#" + prefix + "_Street").val(data[2]);
		$("#" + prefix + "_City").val(data[3]);
		$("#" + prefix + "_CountryId").val(data[4]);
		$("#" + prefix + "_PostalCode").val(data[5]);
		$("#" + prefix + "_Phone").val(data[6]);
	},
	SelectPrimaryAddress: function (id) {
		Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.SelectPrimaryAddress, { id: id },
			function (result) {
				if (result === 'OK') {
					$('.icon--check-full').removeClass('icon--check-full').addClass('icon--check');
					$('#addr_text-' + id + ' .icon--check').removeClass('icon--check').addClass('icon--check-full');
				} else {
					Linksoft.Web.ShowTemporaryDialog('Omlouváme se, ale výběr této adresy se nezdařil. Zkuste to prosím později.');
				}
			}, function () {
				Linksoft.Web.ShowTemporaryDialog('Omlouváme se, ale výběr této adresy se nezdařil. Zkuste to prosím později.');
			});
	},
	DisableAddresses: function (id, ids) {
		Linksoft.Web.Confirm("Opravdu chcete odstranit tuto adresu ze seznamu?", function () {

			Linksoft.Web.DoSimpleAjax(Linksoft.Web.ActionUrls.DisableAddresses, { ids: ids },
				function (result) {
					if (result === 'OK') {
						$('#addr_text' + id).hide();
						$('#addr_sep' + id).hide();
						$('#addr_link' + id).hide();
					} else {
						Linksoft.Web.ShowTemporaryDialog('Omlouváme se, ale deaktivace této adresy se nezdařila. Zkuste to prosím později.');
					}
				}, function () {
					Linksoft.Web.ShowTemporaryDialog('Omlouváme se, ale deaktivace této adresy se nezdařila. Zkuste to prosím později.');
				});
		});
	}
};

Linksoft.Web.OnAjaxPerItem(Linksoft.Cart.PrepareHandlers);
Linksoft.Web.OnAjaxPerItem(function (item) {
	if (!item || item.length === 0) return;
	// Inicializace qtipu napovedy ve formularich
	item.find('.tooltip_form').ifFound("qtip").qtip({
		content: {
			text: function () {
				return $(this).next('.qtip_content').html();
			}
		},
		position: {
			my: 'left center',  // Position my top left...
			at: 'top right', // at the bottom right of...
			adjust: { x: 5, y: 14 }
		},
		show: {
			event: 'mouseenter'
		},
		hide: {
			fixed: true,
			event: 'mouseleave',
			delay: 200
		},
		style: {
			width: 100,
			classes: 'ui-tooltip-shadow ui-tooltip-form'
		},
		prerender: true
	});

});

Linksoft.Account = {
	LogOn: function () {
		if ($('#loginInCart').length) { $('#UserName').focus(); return; }
		if ($('#registration_form').length) { location.href = "/prihlasit"; return; };

		return Linksoft.Web.DoAjax({ type: 'GET', url: Linksoft.Web.ActionUrls.LogOn + "?returnUrl=" + encodeURIComponent(location.pathname + location.search) }, {}, Linksoft.Web.ShowModalDialog);
	},
	LogOnEshop: function (email) {
		return Linksoft.Web.DoAjax({
			type: 'GET',
			url: "/Account/LogOn?linkAccounts=true",
			data: { email: email }
		}, {}, Linksoft.Web.ShowModalDialog);
	},
	LogOnKlub: function (email) {
		return Linksoft.Web.DoAjax({
			type: 'GET',
			url: "/Account/LogOnInKlub?linkAccounts=true",
			data: { email: email }
		}, {}, Linksoft.Web.ShowModalDialog);
	},
	SetWarnAboutEbooks: function (warn) {
		return Linksoft.Web.DoAjax({ type: 'GET', url: Linksoft.Web.ActionUrls.SetWarnAboutEbooks + "?warn=" + (warn ? "True" : "False") });
	},
}

{
	var fun = function () {
		var messages = '';
		$('#grid_AC tr.cart-item-2nd:has(p.yellow.tip)').each(function () {
			var $this = $(this);
			var id = $this.attr('id').replace(/^info/, 'art');
			if (messages !== '') messages += "<br/>\n";
			messages += "<b><a href=\"#" + id + "\" class=\"slow\">" + $('#' + id + ' td.title a').html() + '</a></b> - ' + $('p.yellow.tip', $this).html();
		});
		if (messages !== '') {
			var block = $('#freePostageTop p');
			block.removeClass("yellow").addClass("redwarn");
			block.html(messages);
		}
		Linksoft.Web.PrepareSlowLinks(block);
	}

	Linksoft.Web.OnAjax(fun);
	$(fun);
}

$(function () {
	Linksoft.Cart.PrepareHandlers();

	$('<div id="qtip-blanket">')
		.css({
			position: 'absolute',
			top: $(document).scrollTop(),
			left: 0,
			height: $(document).height(),
			width: '100%',
			opacity: 0.7,
			backgroundColor: 'black',
			zIndex: 5000
		})
		.appendTo(document.body).hide();

	if ($("#jquery-blanket").length === 0) {
		$('<div id="jquery-blanket">')
			.css({
				position: 'absolute',
				top: $(document).scrollTop(),
				left: 0,
				height: $(document).height(),
				width: '100%',
				opacity: 0.15,
				backgroundColor: 'black',
				zIndex: 5000
			})
			.appendTo(document.body)
			.hide();
	}
})

Linksoft.Web.OnLoadAndOnAjax(function ($context) {
	{
		var playlists = $context.find(".playlist.tracks:not(.initialized)");
		if (playlists.length > 0 && playlists.playlist !== null) {
			playlists.each(function () {
				$(this).addClass("initialized");
				$(this).playlist({
					playerurl: "/Content/drPlayer/drplayer.swf",
					volumeBarWidth: 30,
					progressBarWidth: $(this).parents("div.box").length ? 120 : 380,
					barSpace: 5
				});
			})
		}
		playlists = $context.find(".playlist:not(.tracks):not(.initialized)");
		if (playlists.length > 0 && playlists.playlist !== null) {
			playlists.each(function () {
				$(this).addClass("initialized");
				$(this).playlist({
					playerurl: "/Content/drPlayer/drplayer.swf",
					volumeBarWidth: 30,
					progressBarWidth: $(this).parents("div.box").length ? 123 : 210,
					barSpace: 5
				});
			})
		}
	}
});

Linksoft.MujKosmas.HandleFavoritesByKeyboard();

/* todo remove settimeout Linksoft.Grids.AfterRefresh("SLA", function(){ ... }) */
setTimeout(() => {
	Linksoft.MujKosmas.InitializeDragAndDrop();
}, 500);


window.w = function (url, type) {
	var posWidth;
	var posHeight;
	var wname;
	switch (type) {
		case 1:
			posWidth = 1044;
			posHeight = 1044;
			wname = 'galerie-full';
			break
		case 2:
			posWidth = 900;
			posHeight = 640;
			wname = 'cizi-okno';
			break
		default:
			posWidth = 690;
			posHeight = 720;
			wname = 'galerie';
	}
	if (window.screen.availHeight < posHeight) { posHeight = window.screen.availHeight; }
	if (window.screen.availWidth < posWidth) { posWidth = window.screen.availWidth; }
	var posLeft = (window.screen.availWidth - posWidth) / 2;
	var posTop = (window.screen.availHeight - posHeight) / 2;
	var args = 'scrollbars=yes,location=no,resizable=yes,';
	args += 'width=' + posWidth + ',height=' + posHeight + ',left=' + posLeft + ',top=' + posTop;

	var win = window.open(url, wname, args);
	if (win) {
		win.focus;
		return false;
	} else return true;
}

window.reply2 = function (what, par) {
	if (!par) {
		par = 0;
	}
	document.addpost.parent.value = par;
	var text_now = document.addpost.text.value; // ziskani soucasneho obsahu
	if (text_now === "") {
		document.addpost.text.value = what;
	} else {
		document.addpost.text.value + "\n\n" + what;
	}
}

window.showCollapsible = function (this_item) {
	this_item.addClass('open');
	this_item.find('.menu li').removeClass('akt');
	this_item.parent('li').addClass('akt');
	this_item.parent('li').find('.submenu').stop(true, true).slideDown('fast');
}

window.hideCollapsible = function (this_item) {
	this_item.removeClass('open');
	this_item.parent('li').removeClass('akt');
	this_item.parent('li').find('.submenu').stop(true, true).slideUp('fast');
}

var mouse_is_inside = false;
$(function () {

	$("body").mouseup(function () {
		if (!mouse_is_inside) {
			hideCollapsible($(".menu .rozbalovaci a"));
		}
	});

	// Rozbalovaci menu
	$('.menu .rozbalovaci a.all').click(function () {
		if ($(this).hasClass('open')) hideCollapsible($(".menu .rozbalovaci a"));
		else showCollapsible($(this));
		return false;
	});


	$('.menu .rozbalovaci .submenu, .menu .rozbalovaci a').hover(function () {
		mouse_is_inside = true;
	}, function () {
		mouse_is_inside = false;
	});

	// Checkboxy, dohodlo se ze nechame defaultni
	//$('input:checkbox').checkbox();


	// Inicializace zalozek
	/*
	$('.tabs').tabs();
	$(".tabs .ui-tabs-nav a").each(function () {
		var id = $(this).attr("href");
		//		$(this).click(function (event) { var gliderNavNext = $("div" + id + " .nextSlide"); gliderNavNext.mouseover() }); // make sure the images that used to be hidden are loaded
		$(this).click(function (event) { var glider = $("div" + id + " .glider-dynamic"); glider.data("loadRest")(); var registerViews = glider.data("registerViews"); if (registerViews) registerViews(); }); // make sure the images that used to be hidden are loaded
	});
	*/

	// Inicializace qtipu kosiku
	// $('#basket').ifFound("qtip").qtip({
	// 	content: {
	// 		text: function () {
	// 			return $(this).children('.qtip_content').html();
	// 		}
	// 	},
	// 	position: {
	// 		my: 'top right',  // Position my top left...
	// 		at: 'bottom right', // at the bottom right of...
	// 		adjust: { x: 0, y: 5 }
	// 	},
	// 	show: {
	// 		event: 'none',
	// 		effect: function (offset, event) {
	// 			$(this).slideDown(400);
	// 		}
	// 	},
	// 	hide: {
	// 		fixed: true,
	// 		event: 'click unfocus',
	// 		target: $('.close'),
	// 		effect: function (offset) {
	// 			$(this).slideUp(400);
	// 		}
	// 	},
	// 	style: {
	// 		classes: 'ui-tooltip-basket ui-tooltip-shadow',
	// 		tip: {
	// 			width: 20,
	// 			height: 10,
	// 			corner: 'top right',
	// 			mimic: 'center'
	// 		}
	// 	},
	// 	prerender: true
	// });
	// $('#basket').click(function () { location.href = "/kosik"; });


	// Inicializace qtipu napovedy ve formularich
	$('.tooltip_form').ifFound("qtip").qtip({
		content: {
			text: function () {
				return $(this).next('.qtip_content').html();
			}
		},
		position: {
			my: 'left center',  // Position my top left...
			at: 'top right', // at the bottom right of...
			adjust: { x: 5, y: 14 }
		},
		show: {
			event: 'mouseenter'
		},
		hide: {
			fixed: true,
			event: 'mouseleave',
			delay: 200
		},
		style: {
			width: 100,
			classes: 'ui-tooltip-shadow ui-tooltip-form'
		},
		prerender: true
	});

	// Inicializace qtipu napovedy
	$('.tooltip').ifFound("qtip").qtip({
		content: {
			text: function () {
				return $(this).next('.qtip_content').html();
			}
		},
		position: {
			my: 'left center',  // Position my top left...
			at: 'top right', // at the bottom right of...
			adjust: { x: 10, y: 70 }
		},
		show: {
			event: 'mouseenter'
		},
		hide: {
			fixed: true,
			event: 'mouseleave',
			delay: 200
		},
		style: {
			classes: 'ui-tooltip-decription ui-tooltip-shadow',
			tip: {
				width: 10,
				height: 20
			}
		},
		prerender: true
	});


	// Inicializace qtipu vydani knihy
	$('.tooltip-book').ifFound("qtip").qtip({
		content: {
			text: function () {
				return $(this).find('.qtip_content').html();
			}
		},
		position: {
			my: 'right center',  // Position my top left...
			at: 'left top', // at the bottom right of...
			adjust: { x: -7, y: 70 }
		},
		show: {
			event: 'mouseenter'
		},
		hide: {
			event: 'mouseleave'
		},
		style: {
			classes: 'ui-tooltip-book ui-tooltip-shadow',
			tip: {
				width: 10,
				height: 20
			}
		},
		prerender: true
	});


	// Inicializace qtipu postovneho
	$('.tooltip-delivery').ifFound("qtip").qtip({
		content: {
			text: function () {
				return $(this).children('.qtip_content').html();
			}
		},
		position: {
			my: 'left center',  // Position my top left...
			at: 'right center', // at the bottom right of...
			adjust: { x: -53, y: 64 }
		},
		show: {
			event: 'click'
		},
		hide: {
			fixed: true,
			event: 'click unfocus',
			target: $('.close')
		},
		style: {
			classes: 'ui-tooltip-delivery ui-tooltip-shadow',
			tip: {
				width: 10,
				height: 20
			}
		},
		prerender: true
	});


	// Inicializace qtipu dostupnosti
	$('.tooltip-availability').ifFound("qtip").qtip({
		content: {
			text: function () {
				return $(this).children('.qtip_content').html();
			}
		},
		position: {
			my: 'right center',  // Position my top left...
			at: 'left center', // at the bottom right of...
			adjust: { x: -10, y: 0 }
		},
		show: {
			event: 'click'
		},
		hide: {
			fixed: true,
			event: 'click unfocus',
			target: $('.close')
		},
		style: {
			classes: 'ui-tooltip-availability ui-tooltip-shadow',
			tip: {
				width: 10,
				height: 20
			}
		},
		prerender: true
	});

	// Inicializace hodnoceni
	$("input.star").rating();

});

$(function () {
	$('#filtr-temata, #rating-info-top').localScroll();
});

var GliderData = new Array();
window.ShowWindowGliderPageShowed = function (id, page) {
	var gliderdata = GliderData[id];
	if (gliderdata.seen.length > page && !gliderdata.seen[page]) {
		gliderdata.seen[page] = true;
		if (gliderdata.pages.length > page) {
			//window.alert(id + ':' + page);
			$.ajax({
				dataType: 'html',
				cache: false,
				data: { showWindowId: gliderdata.windowid, articleIds: gliderdata.pages[page] },
				type: 'POST',
				url: Linksoft.Web.ActionUrls.RegisterThumbnailView
			});
			if (gliderdata.review_pages && gliderdata.review_pages[page]) {
				$.ajax({
					dataType: 'html',
					cache: false,
					data: { showWindowId: gliderdata.review_windowid, reviewIds: gliderdata.review_pages[page] },
					type: 'POST',
					url: Linksoft.Web.ActionUrls.RegisterReviewThumbnailView
				});
			}
		}
	}
}

window.addQueryToAdvancedSearch = function (theLink) {
	var query = '';
	if ($('#fulltext_query_input').val() !== '') {
		query = 'query=' + encodeURIComponent($('#fulltext_query_input').val());
	}
	if (theLink.href.indexOf('?') !== -1) {
		theLink.href = theLink.href.replace(/([?&])query=.*/, '$1' + query)
	} else if (query !== '') {
		theLink.href = theLink.href + '?' + query;
	}
}

Linksoft.FacebookLoginPending = 0;
Linksoft.FacebookStatusChange = function (response) {
	if (response.status === 'connected') {
		// the user is logged in and has authenticated your
		// app, and response.authResponse supplies
		// the user's ID, a valid access token, a signed
		// request, and the time the access token
		// and signed request each expire
		var uid = response.authResponse.userID;
		var accessToken = response.authResponse.accessToken;
		$(".fb_login").html("Odhlásit z Facebooku");
		if (Linksoft.FacebookLoginPending) window.location.reload();
	} else if (response.status === 'not_authorized') {
		// the user is logged in to Facebook,
		// but has not authenticated your app
		$(".fb_login").html("Facebook");
		if (Linksoft.FacebookLoginPending) location.href = "/Account/LogOff";
	} else {
		// the user isn't logged in to Facebook.
		$(".fb_login").html("Facebook");
		//		if (Linksoft.FacebookLoginPending) location.href = "/Account/LogOff";
	}
};

Linksoft.InitFacebook = function () {
	document.write('<div id="fb-root"></div>');

	window.fbAsyncInit = function () {
		FB.Event.subscribe('auth.statusChange', Linksoft.FacebookStatusChange);
		FB.init({ appId: Linksoft.FacebookClientId, status: true, cookie: true, xfbml: true, oauth: true, channelUrl: '/Content/Channel.html' });

		$(".fb_login").click(function () {
			FB.login(function () {
				Linksoft.FacebookLoginPending = 1;
				FB.getLoginStatus(Linksoft.FacebookStatusChange);
			});
		});
		FB.getLoginStatus(Linksoft.FacebookStatusChange);
	};
	(function (d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (!fjs) return; // something's missing?
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "//connect.facebook.net/en_US/all.js";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));
};

// schovavani policek v administraci oka
$(function () {
	var radio = /,(5|113|114|115|116),/;
	var tv = /,(6),/;
	var akce = /,(7|117|118),/;
	var $sections = $("#Item_Sections");
	if ($sections.length > 0) {
		var fun = function () {
			var list = $sections.val() || $("#Item_Sections-parent").val();
			var selected = ',' + (list === null ? '' : ('string' === typeof list) ? list.replace(/^\d+~/, '') : list.join()) + ',';
			if (selected.match(radio)) {
				$("#Item_AudioUrl").closest('div.form__input').show();
			} else {
				$("#Item_AudioUrl").closest('div.form__input').hide();
			}
			if (selected.match(tv)) {
				$("#Item_VideoUrl").closest('div.form__input').show();
				$("#image").closest('div.form__input').hide();
			} else {
				$("#Item_VideoUrl").closest('div.form__input').hide();
				$("#image").closest('div.form__input').show();
			}
			if (selected.match(akce)) {
				$("#Item_EventStartDate").closest('div.form__input').show();
				$("#Item_EventEndDate").closest('div.form__input').show();
				$("#Item_Location").closest('div.form__input').show();
			} else {
				$("#Item_EventStartDate").closest('div.form__input').hide();
				$("#Item_EventEndDate").closest('div.form__input').hide();
				$("#Item_Location").closest('div.form__input').hide();
			}
		};
		$sections.change(fun);
		fun();
	}
});

$(function () {
	$(function () { // tohle je jen pro stránky pro iPhone appku na audioknížky!!! Nemazat!
		$('#fulltext_query_input').autocomplete('/naseptavac/',
			{ selectFirst: false, delay: 200, cacheLength: 10, matchSubset: false, scrollHeight: '400px', width: 319, minChars: 2, maxChars: 200 }
		).result(function (data) { var form = $('#fulltext_query_form'); var field = $('#fulltext_autocomplete_used'); field.val(1); field.attr("disabled", null); form.submit(); });
		$('#fulltext_query_form').submit(function () {
			var has_query = ($('#fulltext_query_input').val().length >= 2);
			var too_long = ($('#fulltext_query_input').val().length > 300);
			if (!has_query) {
				Linksoft.Web.ShowTemporaryDialog("Zadejte prosím do hledacího pole minimálně 2 znaky.", 5000);
			}
			if ($('#fulltext_autocomplete_used').val() === "1") {
				var value = $('#fulltext_query_input').val();
				if (value.indexOf('"') === -1 && (value.indexOf('-') > -1 || value.indexOf('"') > -1)) {
					$('#fulltext_query_input').val('"' + value + '"');
				}
			}
			return has_query && !too_long;
		});
	});

	{
		var options = { selectFirst: false, delay: 200, cacheLength: 10, matchSubset: false, scrollHeight: '400px', width: 319, minChars: 2, maxChars: 200 };
		$('#AA_S_fulltext').autocomplete('/naseptavac_slov/?format=LINES',
			options
		).result(function (data) {
			var field = $('#AA_S_fulltext');
			AddArticleFulltext();
		}).attr('autocomplete', 'on');
		$('#AA_S_fulltext').data('autocomplete', options);
	}

	var oko_icon = $("span.ksmsiko-oko");
	if (oko_icon.length) {
		//		alert("found oko icon");
		if ($("#mediareviews1000-2").length) {
			//			alert("will scroll 1");
			oko_icon.click(function () { $('html, body').animate({ scrollTop: $("#mediareviews1000-2").offset().top }, 'slow'); })
		} else if ($("div.oko-container").length) {
			//			alert("will scroll 2");
			oko_icon.click(function () { $('html, body').animate({ scrollTop: $("div.oko-container").offset().top }, 'slow'); })
		}
	}

});

Linksoft.Fulltext = {
	EnableKeyboardPaging: function () {
		if ($('a.pager-link').length === 0) return; // just one page or not a results page

		var pageNumberBuffer = 0;
		var pageTimeout = null;
		$(document).keydown(function (e) {
			if ($(document).width() > $(window).width()) return; // page too wide
			if (e.keyCode === 37) {
				var link = $("a.pager-link-prev:visible");
				if (link.length > 0) link[0].click();
			} else if (e.keyCode === 39) {
				link = $("a.pager-link-next:visible");
				if (link.length > 0) link[0].click();
			} else if (e.keyCode >= 96 && e.keyCode <= 105) {
				pageNumberBuffer = pageNumberBuffer * 10 + (e.keyCode - 96);
				if (pageTimeout === null) pageTimeout = setTimeout(function () {
					var pages = $("a.pager-link:not(.pager-link-next)");
					var maxPage = pages[pages.length - 1].text;
					if (maxPage === "-1") maxPage = pages[pages.length - 2].text;
					maxPage = parseInt(maxPage);

					if (pageNumberBuffer >= 1 && pageNumberBuffer <= maxPage) {
						Kosmas.Fulltext.ChangePage(pageNumberBuffer);
					}
					pageNumberBuffer = 0; pageTimeout = null;
				}, 1500);
			} else if (e.keyCode === 27) {
				clearTimeout(pageTimeout);
			}
		});
	},

	SortChildren: function ($parents, child_selector, value_extractor) {
		$parents.each(function (idx, elem) {
			var $parent = $(elem);
			var $children = $parent.children(child_selector);
			$children.each(function (idx, a) {
				var value = value_extractor(a);
				$(a).data("value", value);
			});
			var items = $children.sort(function (a, b) {
				var vA = $(a).data('value');
				var vB = $(b).data('value');
				return (vA < vB) ? 1 : (vA > vB) ? -1 : 0;
			});
			$parent.append(items);
		});
	}
};

Linksoft.Web.OnLoadAndOnAjax(function ($context) {
	$context.find('.pop-up--favorite.logged-in .pop-up__toggle').click(function (e) {
		var $this = $(this).parents('.pop-up--favorite');
		var $hrd = $this.find('.pop-up__header');
		if (!$this.hasClass('pop-up--has-content')) {
			$hrd.find('.added').removeClass("hide");
			$hrd.find('.modify').addClass("hide");
			Linksoft.MujKosmas.CreateStoredList($this.data("itemid"), $this.data("typeid"), $this.data("listname"));
		} else {
			$hrd.find('.added').addClass("hide");
			$hrd.find('.modify').removeClass("hide");
		}
	});

	$context.find('ul.change_list_on_tab li').click(function () {
		var $this = $(this);
		var text = $this.data('list-text');
		var url = $this.data('list-url');
		var id = $this.parent().data('list-id');

		var $dest = $('#' + id);
		$dest.attr('href', url);
		$dest.find('span').text(text);
	});

	// prevent doubleclick
	$context.find('.click-once').off('click.once').on('click.once', function (event) {
		//		console.log('click-once'); 
		var $this = $(this);
		if ($this.data('clicked')) {
			//			console.log('already clicked');
			event.preventDefault();
			event.stopPropagation();
		} else {
			//			console.log('first click');
			$this.data('clicked', true);
			if ($this.hasClass('btn')) {
				$this.addClass('btn--disable');
				window.setTimeout(function () { $this.removeClass('btn--disable'); $this.data('clicked', false); }, 10 * 1000);
			} else {
				$this.addClass('disabled');
				window.setTimeout(function () { $this.removeClass('disabled'); $this.data('clicked', false); }, 10 * 1000);
			}
			return true;
		}
	});

	$context.find('a[href]:not([href="#"]):not(.click-once)').off('click.once').on('click.once', function (event) {
		//		console.log('a click-once');
		var $this = $(this);
		if ($this.data('clicked')) {
			//			console.log('already clicked');
			event.preventDefault();
			event.stopPropagation();
		} else {
			//			console.log('first click');
			$this.data('clicked', true);
			if ($this.hasClass('btn')) {
				$this.addClass('btn--disable');
				window.setTimeout(function () { $this.removeClass('btn--disable'); $this.data('clicked', false); }, 2 * 1000);
			} else {
				$this.addClass('disabled');
				window.setTimeout(function () { $this.removeClass('disabled'); $this.data('clicked', false); }, 2 * 1000);
			}
		}
	});

	$context.find('.special-offer__discount').each(function () {
		var start = new Date();
		var $this = $(this);
		if (!$this.data('discount-delta')) return;
		var discount = parseFloat($this.data('discount')); //alert(discount);
		var delta = parseFloat($this.data('discount-delta')); //alert(delta);
		var end = new Date($this.data('discount-end')); // YYYY-MM-DDTHH:mm:ss - diky tomu T je to v UTC
		var listprice = parseFloat($this.data('listprice')); //alert(listprice)

		var id = setInterval(function () {
			var now = new Date();
			if (now > end) {
				clearInterval(id);
				return;
			}

			var curDiscount = discount + delta * (now - start) / 1000;
			$this.find('.special-offer__percent strong').text(curDiscount.toLocaleString('cs-CZ', { maximumFractionDigits: 3, minimumFractionDigits: 3 }) + '%');
		}, 1000);
	});

	$context.find('.timer-update').each(function () {
		var $this = $(this);
		var $day = $this.find('.day');
		var day = parseInt($day.text() || "0");
		var $hour = $this.find('.hour');
		var hour = parseInt($hour.text() || "0");
		var $minute = $this.find('.minute');
		var minute = parseInt($minute.text() || "0");
		var $second = $this.find('.second');
		var second = parseInt($second.text() || "0");

		//var $tst = $('.footer__top').text('');

		var timer;
		timer = setInterval(function () {
			if (!document.contains($minute[0])) {
				clearInterval(timer);
				return;
			}
			//$tst.append('.');
			second--;
			if (second < 0) {
				second = 59;
				minute--;
				if (minute < 0) {
					minute = 59;
					hour--;
					if (hour < 0) {
						hour = 23;
						day--;
						if (day < 0) {
							clearInterval(timer);
							$this.addClass('hide');
							return;
						}
						$day.text(day);
					}
					$hour.text(hour);
				}
				$minute.text(minute);
			} else if (second === 0 && minute === 0 && hour === 0 && day === 0) {
				clearInterval(timer);
				$this.addClass('hide');
				return;
			}
			$second.text(second);
		}, 1000);
	});


	$context.find('input[data-onenter]').each(function () {
		var $this = $(this);
		$this.on("keypress", function (e) {
			if (e.which === 13) {
				e.preventDefault();
				let toDo = $this.data("onenter");
				if (toDo.startsWith("#") || toDo.startsWith(".")) {
					$(toDo).click();
				} else {
					eval(toDo);
				}
			}
		});
	});
});

Linksoft.Web.OnLoadAndOnAjax(function ($context) {
//	console.log('processing render-later');
	$('div.render-later', $context).each(function () {
//		console.log('processing render-later item', { item: this });
		var $this = $(this);
		var url = $this.data('href');
		var this_id = $this.attr('id');

		var fun = function () {
			if ($this.data('render-later-done') || $this.data('render-later-pending')) return;
			$this.data('render-later-pending', true);
			setTimeout(function () { $this.data('render-later-pending', false); }, 120000); // 120s
			Linksoft.Web.LoadByAjax(this_id, {
				url: url,
				":background": true,
				successHandler: function (section) {
					$this.data('render-later-done', true);
					$this.data('render-later-pending', false);
				}
			});
		};

		$this.data('do_render', fun);

		var $heading = $('.dropdown__trigger', $this.closest('div.dropdown').parent());
		if ($heading.length == 0) {
			$heading = $('.panel-heading', $this.closest('div.panel-collapse').parent());
		}
		if ($heading.length > 0) {
			$heading.click(fun);
			return;
		}

		$heading = $this.closest('div.grid-item');
		if ($heading.length == 0) {
			$heading = $this.closest('article.g-item--2col');
		}
		if ($heading.length > 0) { // výpisovka
			$heading.on("mouseenter", fun);
			return;
		}
	});
});

Linksoft.Web.OnAjaxPerItem(function ($context) {
	$('.render-later-reload', $context).click(function () {
		var $this = $(this).parents('.render-later');
		$this.data('render-later-done', false);
		$this.data('do_render')();
		return false;
	});
});


Linksoft.Gift = {
	giftChanged: false,

	GiftElementBlurred: function () {
		if (Linksoft.Gift.giftChanged)
			setTimeout(function () {
				var allBlured = $('.gift-detail-part:focus').length === 0;

				if (allBlured) {
					Linksoft.Gift.SaveGiftDetails();
				}
			}, 500);
	},

	SaveGiftDetails: function () {
		Linksoft.Gift.giftChanged = false;
		var _prilozit = ($('#giftMessageYes:checked').length > 0);
		var _vzkazOsloveni = $('#VzkazOsloveni').val();
		var _vzkazText = $('#VzkazText').val();
		var _vzkazPodpis = $('#VzkazPodpis').val();
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.SaveGiftDetails, { prilozit: _prilozit, vzkazOsloveni: _vzkazOsloveni, vzkazText: _vzkazText, vzkazPodpis: _vzkazPodpis });
	},

	SaveDodaciList: function () {
		var _prilozit = ($('#billOfDeliveryYes:checked').length > 0);
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.SaveDodaciList, { prilozit: _prilozit });
	},

	_wrappingPaperId: null,
	_wrappingPaperOnlyAdd: false,

	UpdateGiftPrice: function (price) {
		var $wrappingPaperOnlyAddElem = $('#paperPrice').next()
		if (price !== undefined)
			$('#paperPrice').html(price + ' Kč');


		if (Linksoft.Gift._wrappingPaperOnlyAdd) {
			$wrappingPaperOnlyAddElem.html('&nbsp;(balicí papír pouze přiložit)</small>')
		} else {
			$wrappingPaperOnlyAddElem.html('');
		}
	},

	SavePaper: function (self) {
		//console.log(self);
		var thisPrice = $(self).attr('data-gift-price');
		Linksoft.Web.DoAjax(Linksoft.Web.ActionUrls.SaveWrappingPaper, { wrappingPaperId: Linksoft.Gift._wrappingPaperId, wrappingPaperOnlyAdd: Linksoft.Gift._wrappingPaperOnlyAdd }, Linksoft.Gift.UpdateGiftPrice(thisPrice));
	},

	CancelPaper: function () {
		Linksoft.Gift._wrappingPaperId = null;
		Linksoft.Gift._wrappingPaperOnlyAdd = false;

		$('#paperPrice').html('');

		//$('.gift-wrapper input').prop("checked", false);

		Linksoft.Gift.SavePaper();
	}
};

window.GoToWithCategory = function (url) {
	var categoryId = $('.has-submenu.active + .sub-menu .sub-m-cat__item.active').data('cat');
	location.href = url + "&Filters.CategoryId=" + categoryId;
	return;
}
window.BestsellersWithCategory = function (url, articleTypeIds) {
	var categoryId = $('.has-submenu.active + .sub-menu .sub-m-cat__item.active').data('cat');
	if (categoryId) {
		url = url + "/" + categoryId;
	}
	if (articleTypeIds) {
		url = url + "?articleTypeIds=" + articleTypeIds;
	}
	location.href = url;
	return;
}
$(function () {
	if ($('.sel-bookstore').length) {
		$('.sel-bookstore').change(function () {
			var id = $(this).val();
			var query = $.query(location.href);
			if (id && id !== "0") {
				query["prodejna"] = id;
			} else {
				delete query["prodejna"]
			}
			location.href = query;
		});
	}

	$(window).unload(function () {
		//		console.log('windblow.unload, pos=' + $(document).scrollTop());
		history.replaceState(undefined, undefined, location.href.replace(/#.*/, '') + "#pos=" + $(document).scrollTop());
		return;
	});
	/*
	var installedPos = false;
	Linksoft.Web.OnAjax(function () {
		if (installedPos) return;
		$('a[href]').click(function () { })
		installedPos = true;
	}); 
	*/
});
if (window.location.hash && window.location.hash.indexOf("#pos=") === 0) {
	var pos = parseInt(window.location.hash.substring(5));
	//	console.log('after reload there\'s pos = ' + pos);
	if (!isNaN(pos) && pos > 30) {
		$(function () {
			$(document).scrollTop(pos);
		});
		var cnt = 0;
		var timer = window.setInterval(
			function () {
				if ($(document).scrollTop() == 0) {
					$(document).scrollTop(pos);
					window.clearInterval(timer);
				} else if (cnt++ > 10) {
					window.clearInterval(timer);
				}
			},
			500
		);
	}
}
if (!window.performance || window.performance.navigation.type === 2) {
	// page has been hit using back or forward buttons or we don't know so just in case ...
	$(function () {
		Linksoft.Web.LoadByAjax('basket', { url: '/kosik/basket', blanket: false })
	});
}
