/**
 * Created by Daniel on 6/27/2017.
 */
function openPopUp(e) {
    let matches = e.target.matches ? e.target.matches('.sojka__popup-trigger') : event.target.msMatchesSelector('.sojka__popup-trigger');
    if (!matches) return;
    const opener = e.target;
    const wrapper = opener.parentNode;
    const otherSojkaPopUps = document.querySelectorAll('.sojka__popup');
    for (let i = 0; i < otherSojkaPopUps.length; i++) { otherSojkaPopUps[i].classList.remove('is-opened');}
    wrapper.classList.add('is-opened');
}

function closePopUp(e) {
    let matches = e.target.matches ? e.target.matches('.sojka__popup-close') : event.target.msMatchesSelector('.sojka__popup-close');
    if (!matches) return;
    const opener = e.target;
    const wrapper = opener.parentNode.parentNode;
    wrapper.classList.remove('is-opened');
}

document.addEventListener('click', (e) => closePopUp(e));
document.addEventListener('click', (e) => openPopUp(e));

const SojkaDetail = {

    isActive: false,

    getElementsDimensions(element) {
        return {
            width: $(element).width(),
            height: $(element).height()
        }
    },

    getElementsPosition(element) {
        return {
            offsetTop: $(element).offset().top,
            offsetLeft: $(element).offset().left
        }
    },
    positionSojkaPopup(trigger, popup) {
        const triggerPosition = this.getElementsPosition(trigger);
        const triggerDimensions = this.getElementsDimensions(trigger);

        const popupPosition = this.getElementsPosition(popup);
        const popupDimensions = this.getElementsDimensions(popup);

        const popupNewTop = (triggerPosition.offsetTop - popupPosition.offsetTop) + (triggerDimensions.height * 3);
        const popupNewLeft = (triggerPosition.offsetLeft - popupPosition.offsetLeft) - (popupDimensions.width/2) - (triggerDimensions.width/2);

        console.log(popupNewTop, popupNewLeft);

        popup.css({
            'top': `${popupNewTop}px`,
            'left': `${popupNewLeft}px`,
            'right': 'auto'
        })
    },

    resetSojkaPosition(popup) {
        $(popup).removeAttr('style')
    },

    hackInitialTrigger(element) {
        $(element).removeClass('active');
        $(element).find('.tip').remove();
        $('#sojka-hlavni')[0].click();
    },

    init() {
        const $sojkaProductTypeTrigger = $('.product__type-watch');
        if(!$sojkaProductTypeTrigger.length) return;

        const $sojkaTrigger = $('#sojka-hlavni');
        const $sojkaPopup = $sojkaTrigger.next('.pop-up__content');
        const $sojkaCloser = $sojkaPopup.find('.pop-up__close');
        const $sojkaContent = $sojkaPopup.find('.pop-up__main');

        const _this = this;
        $sojkaProductTypeTrigger.on('click', function (e) {
            if(_this.isActive) return;
            const $this = $(this);

            setTimeout(() => {
                _this.hackInitialTrigger($this);
                _this.positionSojkaPopup($this, $sojkaPopup);
                _this.isActive = true;
            },50);

        });

        $(document).on('click', () => {
            if(!this.isActive) return;
            this.resetSojkaPosition($sojkaPopup);
            this.isActive = false;
        });

        $sojkaCloser.on('click', () => {
            if(!this.isActive) return;
            this.resetSojkaPosition($sojkaPopup);
            this.isActive = false;
        });

        $sojkaContent.on('click', (e) => {
            if(!this.isActive) return;
            e.stopPropagation();
        })
    }
};

SojkaDetail.init();