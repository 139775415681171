let timeoutOverflow;
class Dropdown {
	constructor(events) {
		this.scope = '.dropdown';
		this.content = '.dropdown__content';
		this.trigger = '.dropdown__trigger';
		this.transitionEnd = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend';
	}

	isRadioInput(elm) {
		var self = this;
		return $(elm).attr('type') === 'radio' ? true : false;
	}

	closeAll() {
		var self = this;
		$(self.content).removeClass('active');
		$(self.content).css('overflow', 'hidden');
	}

	// isClosed -> boolean
	setContent(isClosed, elm, scope) {
		var self = this;
		var $this = $(elm);
		var $scope = scope === undefined ? $this.closest(self.scope) : $this.closest(scope);
		if ($scope.length === 0) return;

		var content = $scope.find('>' + self.content);
		var trigger = $scope.find('>' + self.trigger);

		const dataUrl = content.attr('data-href');

		function open() {
			$this.addClass('active');
			$scope.addClass('active');
			content.addClass('active');
			trigger.addClass('active');

			// problem in ebooks
			trigger.removeClass('btn--pending');

			timeoutOverflow = setTimeout(() => {
				content.css('overflow', 'visible');
			}, 400);

			$scope.find("input:first").focus();
		}

		function close() {
			$this.removeClass('active');
			$scope.removeClass('active');
			content.removeClass('active');
			trigger.removeClass('active');
			clearTimeout(timeoutOverflow);
			content.css('overflow', 'hidden');
		}

		if (isClosed) {
			if (trigger.data('render-later-done') || trigger.data('render-later-pending')) {
				open();
				return false;
			}

			trigger.data('render-later-pending', true);

			// TODO: it's not optional, it's just for .btn trigger, for next time expand it
			trigger.addClass('btn--pending');

			setTimeout(function () { trigger.data('render-later-pending', false); }, 120000); // 120s

			// load from ajax
			if (dataUrl !== undefined && dataUrl.length) {
				Linksoft.Web.LoadByAjax(null, {
					url: dataUrl,
					":background": true,
					successHandler: function (section) {
						trigger.data('render-later-done', true);
						trigger.data('render-later-pending', false);
						trigger.removeClass('btn--pending');

						content.html(section);
						Linksoft.Web.RunSuccessHandlersPerItem(content);
						setTimeout(() => {
							open();
						}, 100)
					}
				});
			} else {
				open();
			}
		} else {
			close();
		}
	}

	// triggerByChange() {
	//   var self = this;
	//   $(document).on('change', self.trigger, function(){

	//   });
	// }


	triggerByClick() {
		var self = this;
		$(document).on('click', self.trigger, function (e) {
			// e.preventDefault();
			var $this = $(this);
			var $scope = $this.closest(self.scope);

			if ($scope.length === 0) return;

			// open dropdown when button is switcher on/off
			if ($this.hasClass('switcher')) {
				var inputChecked = $this.find('input:checked');
				self.setContent(inputChecked.val() === '1', this, '.dropdown--no-yes');

				return;
			}

			// open dropdown by input check or radio
			if ($this.is('input')) {
				if (self.isRadioInput(this)) {
					self.closeAll();
				}

				self.setContent($this.is(':checked'), this);

				return;
			}


			// from this is opening dropdown by element
			if ($(this).is('a')) {
				e.preventDefault();
			}

			var currentText = $this.text();
			var isOpen = $scope.hasClass('active') ? true : false;

			// in digi audio
			var textHolder = $this.attr('data-text-holder');
			if (textHolder !== undefined) {
				$this.text(textHolder);
				$this.attr('data-text-holder', currentText);
			}

			self.setContent(!isOpen, this);
		});
	}

	destroy() {
		var self = this;
		$(document).off('click', self.trigger);
		self.closeAll();
	}

	init() {
		var self = this;

		self.triggerByClick();
	}
}



if (typeof window.Linksoft.Dropdown !== 'object' ||
	typeof window.Linksoft.Dropdown !== 'function') {
	window.Linksoft.Dropdown = Dropdown;
}


window.globalDropdown = new Dropdown();
globalDropdown.init();

    // $('.dropdown:not(.cart__for-later)').dropdown({ triggerType: 'check' });
    // $('.dropdown--no-yes').dropdown({ triggerType: 'switcher' });

    // $().dropdown();