// used http://codepen.io/shankarcabus/pen/GzAfb

var $ppc = $('.progress-pie-chart'),
    percent = parseInt($ppc.data('percent')),
    deg = 360*percent/100;

if (percent > 50) {
  $ppc.addClass('gt-50');
}

$('.ppc-progress-fill').css('transform','rotate('+ deg +'deg)');
$('.ppc-percents span').html(percent+'%');
