var isBigScreen = window.matchMedia && window.matchMedia("(min-width: 1365px)").matches;
var $searchWhisperer = $('#searchWhisperer');
var $searchWhispererLeft = $('#searchWhispererLeft');
var $searchWhispererRight = $('#searchWhispererRight');
var $searchInput = $('#searchInput');
var timerId = 0;
let itemsTotal = null;
let currentIndex = -1; // nothing is selected by default
let selectorActive = false;
let selectedItem = false;
let $listWrapper;
let selectedLink;
let listScrollHeight;
let listVisibleHeight;
let listScrollTop;
let listOffsetTop;
let selectedOffsetTop;
let currentOffsetHeight;
let scrollBy;
let shouldBeOpen;
let ajaxPending = 0;

function updateSearchInputSize() {

	var $searchInputSubPage = $('.header__search').find('.search-input');
	var logoWidth = isBigScreen ? 480 : 20;

	function focusInit() {
		var headerWidth = $('.header__body').width();
		var $wrap = $searchInputSubPage.parent();
		$searchInputSubPage.on('focus', function () {

			$wrap.css('width', headerWidth - logoWidth).addClass('is-focused');

			// problem with open submenu and it's close button
			$wrap.parent().css('z-index', 1000);

			if (!isBigScreen) {
				$wrap.css('margin-right', '-205px')
			}

			// open again if there any opened result
			if ($searchWhisperer.find('.result__row').length) {
				shouldBeOpen = true;
				setTimeout(function () {
					if (shouldBeOpen) {
						$searchWhisperer.addClass('active');
					}
				}, 400);
			}
		});

	}
	focusInit();
	$(window).on('resize', $.debounce(200, focusInit));

	$searchInputSubPage.on('blur', function () {
		// condition for do nothing when it has content and unfocus
		console.log("searchInputSubPage.on", { val: $(this).val(), this: this });
		if ($(this).val().length === 0) {

			if (ajaxPending === 0) {
				$searchWhisperer.removeClass('active');

				shouldBeOpen = false;
				setTimeout(() => {
					if (!shouldBeOpen && ajaxPending === 0) {

						$(this).parent().attr('style', '').removeClass('is-focused');
						$(this).parent().parent().attr('style', '');
					}
				}, 400);
			}
		}

	});

	// close when click somewhere else
	$(document).on('click', function (e) {
		var isInSearch = $(e.target).closest('.header__search-inner').length > 0;
		var isInSearchResult = $(e.target).closest('#searchWhisperer').length > 0;

		if (isInSearch || isInSearchResult) {
			return;
		}

		if (ajaxPending === 0) {
			$searchWhisperer.removeClass('active');

			shouldBeOpen = false;
			setTimeout(() => {
				if (!shouldBeOpen && ajaxPending === 0) {
					$searchWhisperer.parent().attr('style', '').removeClass('is-focused');
					$searchWhisperer.parent().parent().attr('style', '');
				}
			}, 400);
		}
	});

}
updateSearchInputSize();


// data from ajax
function showWhisperer(data, place) {
	// prevent multiple add class active
	if (!$searchWhisperer.hasClass('active')) {
		$searchWhisperer.addClass('active');
	}

	if (place === 'left') {
		$searchWhispererLeft.html(data)
	} else if (place === 'right') {
		$searchWhispererRight.html(data)
	}

}

function hideWhisperer() {
	$searchWhispererLeft.html('');
	$searchWhispererRight.html('');

	setTimeout(() => {
		$searchWhisperer.removeClass('active');

	}, 400);
}
var lastVal = "";
var counter = 0;

function isRightTaller() {
	var rightContentHeight = $searchWhispererRight.height();
	var boxHeight = $(window).height() - $searchWhispererRight.offset().top;
	return rightContentHeight > boxHeight;
}

function moveSelected(position) {
	let $autocompleteItems = $searchWhispererLeft.find('li');
	$autocompleteItems.each(function (item) {
		$(this).removeClass('is-highlighted');
	});
	let $selectedItem = $($autocompleteItems[position]);
	$selectedItem.addClass('is-highlighted');
}

function getAutocompleteCount() {
	let $autocompleteItems = $searchWhispererLeft.find('li');
	return $autocompleteItems.length;
}
/* prevent cursor from changing position when highlighting is active */
$searchInput.on('keydown keyup', function (e) {
	if (selectorActive && (e.keyCode === 38 || e.keyCode === 40)) {
		e.preventDefault();
	}
});
// init fulltext search
$searchInput.on('keyup', function (e) {
	let thisVal = $(this).val();
	if (timerId > 0) {
		clearTimeout(timerId);
		timerId = 0;
	}
	itemsTotal = getAutocompleteCount();
	let $selectedItem = $('li.is-highlighted');

	selectorActive = itemsTotal > 0;
	selectedItem = $selectedItem.length > 0;

	if (selectedItem) {
		$listWrapper = $selectedItem.parent();
		listScrollHeight = $listWrapper[0].scrollHeight;
		listScrollTop = $listWrapper[0].scrollTop;
		listVisibleHeight = $listWrapper[0].offsetHeight;
		listOffsetTop = $listWrapper[0].offsetTop;
		selectedOffsetTop = $selectedItem[0].offsetTop;
		currentOffsetHeight = listScrollHeight - listVisibleHeight;
		scrollBy = currentOffsetHeight / itemsTotal;
		// console.table([ // nefunguje v IE
		//     ['$selectedItem', $selectedItem],
		//     ['listScrollHeight:', listScrollHeight],
		//     ['listScrollTop:', listScrollTop],
		//     ['listVisibleHeight:', listVisibleHeight],
		//     ['listOffsetTop:', listOffsetTop],
		//     ['selectedOffsetTop:', selectedOffsetTop],
		//     ['currentOffsetHeight', currentOffsetHeight],
		// ]);
	}

	if ((!selectorActive)) {
		switch (e.keyCode) {
			case 13:
				if (thisVal.length > 1) {
					Kosmas.Fulltext.RunQuery(thisVal.trim());
				}
				return;
			default:
				currentIndex = -1;
				moveSelected(currentIndex);
				if (selectedItem) $listWrapper[0].scrollTop = 0;

		}
	} else if (selectorActive) {
		switch (e.keyCode) {
			case 38: // move up
				e.preventDefault();
				currentIndex--;
				if (currentIndex < 0) currentIndex = itemsTotal - 1;
				moveSelected(currentIndex);
				if (selectedItem && listScrollHeight > listVisibleHeight) {
					if (selectedOffsetTop > listScrollHeight / 2 || listOffsetTop === selectedOffsetTop) {
						$listWrapper[0].scrollTop = currentOffsetHeight;
					} else {
						$listWrapper[0].scrollTop = 0;
					}
				}
				return;
			case 40: // move down
				e.preventDefault();
				currentIndex++;
				if (currentIndex > itemsTotal - 1) currentIndex = 0;
				moveSelected(currentIndex);
				if (selectedItem && listScrollHeight > listVisibleHeight) {
					if (selectedOffsetTop > listScrollHeight / 2 && (selectedOffsetTop + scrollBy < listScrollHeight)) {
						$listWrapper[0].scrollTop = currentOffsetHeight;
					} else {
						$listWrapper[0].scrollTop = 0;
					}
				}

				return;
			case 13: // enter
				if (selectedItem) {
					let selectedValue = $selectedItem.text().trim();
					if (selectedValue.length > 1) {
						selectedLink = $selectedItem.find('a').attr('href');
						window.location.href = selectedLink;
					}
				} else {
					Kosmas.Fulltext.RunQuery(thisVal.trim());
				}
				return;
			case 27: // esc
				currentIndex = -1;
				moveSelected(currentIndex);
				if (selectedItem) $listWrapper[0].scrollTop = 0;
				break;

			default:
				currentIndex = -1;
				moveSelected(currentIndex);
				if (selectedItem) $listWrapper[0].scrollTop = 0;
		}
	}
	//$('.product__annotation h2').html($('.product__annotation h2').html() + 'X');
	if (thisVal.length > 2) {

		if (lastVal === thisVal) return;
		timerId = setTimeout(function () {
			lastVal = thisVal;

			//$('.product__annotation h2').html($('.product__annotation h2').html() + 'O');
			// right side
			$(".search-w__right, .search-w__left").addClass("pending");
			var current = ++counter;
			ajaxPending = 2;
			Linksoft.Web.DoSimpleAjax({
				url: '/naseptavac',
				method: 'GET',
				cache: true,
				data: { q: thisVal.trim() },
				successHandler: function (result) {
					if (current === counter) {
						ajaxPending--;
						showWhisperer(result, 'right');
						$(".search-w__right").removeClass("pending");
						$('.h-search__clear').addClass('active');


						if (isRightTaller()) {
							$searchWhispererRight.find('.result__row--books').remove();
						}

						// if is still taller, remove some categories
						if (isRightTaller()) {
							$searchWhispererRight.find('.result__cat:nth-of-type(4) ~ .result__cat').remove();
						}

						// if is still taller, remove translators
						if (isRightTaller()) {
							$searchWhispererRight
								.find('.result__items--authors-trans a:nth-of-type(4) ~ a').remove();
						}

						// if is still taller, remove thematic
						if (isRightTaller()) {
							$searchWhispererRight
								.find('.result__items--thematic a:nth-of-type(4) ~ a').remove();
						}
					}
				},
				failHandler: function () {
					if (current === counter) {
						Linksoft.Web.ShowModalDialog("Chyba!");
						$(".search-w__right").removeClass("pending");
						$('.h-search__clear').addClass('active');
					}
				},
				blanket: false

			});

			// left side
			Linksoft.Web.DoSimpleAjax({
				url: '/naseptavac_slov',
				method: 'GET',
				cache: true,
				data: { q: thisVal.trim() },
				successHandler: function (result) {
					if (current === counter) {
						ajaxPending--;
						showWhisperer(result, 'left');
						$(".search-w__left").removeClass("pending");
						$('.h-search__clear').addClass('active');
					}
				},
				failHandler: function () {
					if (current === counter) {
						Linksoft.Web.ShowModalDialog("Chyba!");
						$(".search-w__left").removeClass("pending");
						$('.h-search__clear').addClass('active');
					}
				},
				blanket: false

			});

		}, 300);
	} else {
		hideWhisperer();
		lastVal = "";
	}
});

function searchByClick() {
	if ($('.header__search-inner').hasClass("is-focused")) {
		Kosmas.Fulltext.RunQuery();
	} else {
		var $searchInputSubPage = $('.header__search').find('.search-input');

		$searchInputSubPage.focus();
		$searchInputSubPage.on("focus");
	}
}
$('#searchButton').click(searchByClick);


Linksoft.SearchTextForItemsHandle = function () {
	var boxWidth = $('.result__item').width();
	var imageWidth = $('.result__item .img__cover--sm').outerWidth() + 20; // 20 is margin-right
	$('.result__item-title').each(function () {
		var $this = $(this);
		var thisWidth = $this.width();

		if (thisWidth + imageWidth > boxWidth) {
			// new style

			$('.result__item a:first-child').addClass('cover-block');

			return false;
		}
	})
}


// clear input
$(document).on('click', '.h-search__clear', function (e) {
	e.preventDefault();
	$searchInput.val('');
	$(this).removeClass('active');
	hideWhisperer();
});

setTimeout(() => {
	if ($searchInput.length && $searchInput.val().length > 0) {
		$('.h-search__clear').addClass('active');
	}
}, 200)