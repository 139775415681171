﻿var ignore = require('./../libs/jquery-ignore');

class FullTextFillter {
	constructor() {
		this.$wrapAll = $('.ft-filter');
		this.$wrap = this.$wrapAll.find('.ft-filter__group ');
		this.$whisperAllWrap = $('#fullTextFilterSelected');
		this.$inputs = this.$wrap.find('input');

		this.byYears = 'yearrange';
		this.byPrice = 'pricerange';
	}

	toggle() {
		var self = this;
		$('#fullTextShow').on('click', function () {
			$(this).toggleClass('arrow-up');
			self.$wrap.toggleClass('active');
		})
	}


	whisperAddButton(label, thisInputId) {
		var $whisperWrap = $('.ft-filter__whisper-results');


		var button = `<span class="ft-whisper__selected ${thisInputId}"
											data-input-id="${thisInputId}">
						  <button class="ft-whisper__remove">
							<span>${label}</span><i class="icon--cross-thin"></i>
						  </button>
						</span>`;


		// radio
		if (thisInputId.indexOf(self.byPrice) > -1) {
			$('[class*="pricerange"]', $whisperWrap).remove();
		}

		$whisperWrap.append(button);
	}

	whisperRemoveButton(inputId, self, isAmout) {
		var $whisperWrap = $('.ft-filter__whisper-results');

		$whisperWrap.find('[data-input-id=' + inputId + ']').remove();

		self.whisperHideLineAfterNothing();
	}

	whisperAdd(self) {
		var $this = $(this);
		var thisInputId = $this.attr('id');
		var label = $this.parent().find('label').ignore('span').text().trim();
		var type = $this.attr('type');

		if (thisInputId.indexOf(self.byYears) > -1) {
			self.whisperAddInputFromTo.call(this, self, self.byYears);
			return;
		}

		if ($this.is(':checked')) {
			self.whisperAddButton(label, thisInputId);
			self.$whisperAllWrap.addClass('active');
		} else { // uncheck
			self.whisperRemoveButton(thisInputId, self);
		}
	}

	whisperAddInputFromTo(self, type) {
		var $this = $(this);
		var $wrap = $this.closest('.ft-filter__group-contents');
		var firstAmount = $wrap.find('.form__input:first-child input').val();
		var secountAmount = $wrap.find('.form__input:first-child ~ .form__input input').val();
		var symbol = type === self.byPrice ? 'Kč' : '';

		firstAmount = firstAmount.length === 0 ? '' : firstAmount;

		if (firstAmount === '' && secountAmount === '') {
			self.whisperRemoveButton(`${type}-custom`, self);
			return;
		}

		// remove previous one
		$(`[class*="${type}"]`, self.$whisperAllWrap).remove();
		self.whisperAddButton(`${firstAmount}-${secountAmount} ${symbol}`, `${type}-custom`);
		self.$whisperAllWrap.addClass('active');
	}

	whisperRemove(self) {
		try {
			Kosmas.Fulltext.ignoreEvents = true;
			var $this = $(this);
			var inputId = $this.parent().attr('data-input-id');
			var $wrapWhiper = $this.closest('.ft-whisper__content');
			var $wrapRow = $this.closest('.ft-whisper__item');


			if (inputId.indexOf('pricerange-custom') > -1) {
				$('#Filters_MinPrice, #Filters_MaxPrice', self.$wrapAll).val('');
			}

			if (inputId.indexOf('yearrange-custom') > -1) {
				$('#Filters_MinYearPublished, #Filters_MaxYearPublished', self.$wrapAll).val('');
			}

			$('#' + inputId, self.$wrapAll).prop('checked', false);
			console.log($('#' + inputId, self.$wrapAll));
			$this.parent().remove();
		} finally {
			Kosmas.Fulltext.ignoreEvents = false;
		}

		Kosmas.Fulltext.AJAX_Ask();

		// console.log('Kosmas.Fulltext.AJAX_Ask');
		if ($wrapWhiper.find('.ft-whisper__selected').length === 0) {
			$wrapRow.removeClass('active');
		}

		self.whisperHideLineAfterNothing();
	}

	whisperHideLineAfterNothing() {
		// hide last row when nothing is selected
		// 1 is .ft-whisper__selected--all, it has .ft-whisper__selected too
		if ($('#fullTextFilterSelected').find('.ft-whisper__selected').length === 0) {
			$('.ft-filter__whisper').removeClass('active');
		}
	}

	whisperRemoveAll() {
		try {
			Kosmas.Fulltext.ignoreEvents = true;
			this.$wrapAll.find('input').prop('checked', false);
			this.$wrapAll.find('input:text,input[type="number"]').val('');
			this.$wrapAll.find('.ft-whisper__item').removeClass('active');
			this.$wrapAll.find('.ft-whisper__selected:not(.ft-whisper__selected--all)').remove();

			//
			Kosmas.Fulltext.AddArticleTypesFilter('');
			$('#fullTextFilterSelected').removeClass('active');
		} finally {
			Kosmas.Fulltext.ignoreEvents = false;
		}

		Kosmas.Fulltext.AJAX_Ask();
	}

	whisperAddAfterRefresh() {
		try {
			Kosmas.Fulltext.ignoreEvents = true;
			var self = this;
			var $inputs = self.$wrapAll.find('input:not(input[type=number]):not([id*="yearrange"])');
			var $inputsNumber = self.$wrapAll.find('#Filters_MaxYearPublished, #Filters_MaxPrice');


			$inputs.each(function () {
				self.whisperAdd.call(this, self);
			});

			$inputsNumber.each(function () {
				var $this = $(this);
				var type = $this.attr('name') === 'Filters.MaxYearPublished' ? self.byYears : self.byPrice;

				if ($this.val().length > 0) {
					self.whisperAddInputFromTo.call(this, self, type);
				}

			});

			// add from inputs


			// hide last row when nothing is selected
			if ($('#fullTextFilterSelected').find('.ft-whisper__selected').length === 0) {
				$('.ft-filter__whisper').removeClass('active');
			}
		} finally {
			Kosmas.Fulltext.ignoreEvents = false;
		}
	}

	whisperInit() {
		var self = this;

		self.whisperAddAfterRefresh();

		// remove items
		self.$wrapAll.on('click', '.ft-whisper__remove:not(.ft-whisper__remove--all)', function () {
			self.whisperRemove.call(this, self);
		});

		self.$wrapAll.on('click', '.ft-whisper__remove--all', function () {
			self.whisperRemoveAll.call(self);
		});


		self.$wrapAll.on('change', 'input', function () {
			self.whisperAdd.call(this, self);
		});


		self.$wrapAll.on('click', '#btn_custom_price_filter', function () {
			self.whisperAddInputFromTo.call(this, self, self.byPrice);
		});


		self.$wrapAll.on('click', '#btn_custom_yearpublished_filter', function () {
			self.whisperAddInputFromTo.call(this, self, self.byYears);
		});
	}

	init() {
		//console.log("fullTextFilter.init");
		var self = this;

		if (self.$wrap.length === 0) return;

		self.toggle();

		//console.log("initialize headers");
		$('.ft-filter__group h2').on("click", function (that) {
			//console.log({that: that, this: this});
			$(this).parent().toggleClass("active");
		});


		$(document).on('click', '.ft-filter__more', function(e){
			e.preventDefault();
			$(this).next().removeClass('hidden');
			$(this).next().find('input[type=text]').focus();
		});

		self.whisperInit();
	}
}

var fullTextFilter = new FullTextFillter;
fullTextFilter.init();

if (typeof window.Linksoft.FulltextFilter !== 'object' ||
	typeof window.Linksoft.FulltextFilter !== 'function') {
	window.Linksoft.FulltextFilter = fullTextFilter;
}

if ($('aside.cat-list__wrap').length > 0) {

	var loadFilters = function () {
		Kosmas.Fulltext.LoadFacets(function () { $('.ft-filter__selects .loading').remove(); $('.ft-filter__selects .flex').show() });
		$('#fullTextShow').unbind('click', loadFilters);
	}

	var hideEmptyTabs = function (onLoad) { 
		//console.log("hide empty filters");
		$('div.ft-filter__group').each(function (i, self) {
			var $self = $(self);
			if ($self.find('div.ft-filter__group-contents:not(:has(input,select,textarea,a))').length) {
				$self.hide();
			} else {
				$self.show();
			}
		});
	};
	$(function () { hideEmptyTabs(true) });
	Linksoft.Web.OnAjax(hideEmptyTabs);
}