class BtnPending {
  constructor (elm) {
    this.$elm = $(elm);
    this.classNamePending = 'btn-text--pending';
    this.classNameDone = 'btn-text--done';
    this.btnLabel = this.$elm.text();
  }

  run() {
    this.$elm.removeClass(this.classNameDone);
    // this.$elm.data("text", this.$elm.text());
    this.$elm.addClass(this.classNamePending);

    this.$elm.text('Ukládám ...');
  }

  loadSuccess(after) {
    this.$elm.removeClass(this.classNamePending);
    this.$elm.addClass(this.classNameDone);

    setTimeout(()=>{
      if (after !== undefined) after.call();
      this.$elm.removeClass(this.classNameDone);
      this.$elm.text(this.btnLabel);
    }, 1000)
  }
}

if ( typeof window.Linksoft.BtnPending !== 'object' ||
      typeof window.Linksoft.BtnPending !== 'function' ) {
  window.Linksoft.BtnPending = BtnPending;
}
