import GetPosition from './getPosition';
import YoutubeInIframe from './../components/youtubeIframe';
var ytIframeClass = '.yt-iframe';

$.fn.popUp = function( options ) {
  var set = $.extend({
    trigger: '.pop-up__toggle',
    close: '.pop-up__close',
    setCenter: true,
    dynamicArrow: false, // not centered one, arrow usual be in center
    beforeOpen: function(){},
    afterOpen: function(){}
  }, options);

  var selector = this.selector;
  var popActiveClassName = 'pop-up--active';



  var open = function( trigger, thisWrap) {
    set.beforeOpen(thisWrap, trigger);
    var $thisWrap = $(thisWrap);
    var content = $thisWrap.find('.pop-up__content');
    var getPosition = new GetPosition();

    // add arrow
    if (content.find('.pop-up__arrow').length === 0) {
      content.append('<i class="pop-up__arrow" />');
    }

    // init youtube in iframe
    if (content.find(ytIframeClass).length > 0) {
      var yt = new YoutubeInIframe(ytIframeClass);
      yt.init();
    }

    // in product detail illustration popup .pop-up--illu
    if (set.dynamicArrow) {
      var wrapOffsetLeft = $thisWrap.position().left;

      $thisWrap.find('.pop-up__arrow').css('left', wrapOffsetLeft + ($thisWrap.outerWidth()/2));
    }


    // remove all opened popup
    $('.' + popActiveClassName).removeClass(popActiveClassName)
    $thisWrap.addClass(popActiveClassName);

    if (set.setCenter) {
      content.css('right', getPosition.center(trigger[0]));
    }

    set.afterOpen(thisWrap, trigger);
  }



  var init = function(trigger, thisWrap) {
    var $thisWrap = $(thisWrap);
    var content = $thisWrap.find('.pop-up__content');
    var dataUrl = content.attr('data-href');
    var trigger = $thisWrap.find(set.trigger);

    if (!$thisWrap.hasClass(popActiveClassName)) {
      open(trigger, thisWrap);

      if ( dataUrl !== undefined && dataUrl.length ) {

        // load content by ajax
        if (trigger.data('render-later-done') || trigger.data('render-later-pending')) {
          open(trigger, thisWrap);
          return false;
        }

        content.append('<div class="loading--sm" />');
        trigger.data('render-later-pending', true);

        setTimeout(function () { trigger.data('render-later-pending', false); }, 120000); // 120s

        // load from ajax
        Linksoft.Web.LoadByAjax(null, {
              url: dataUrl,
              ":background": true,
              successHandler: function (section) {
                  trigger.data('render-later-done', true);
                  trigger.data('render-later-pending', false);

                  content.html(section);
                  Linksoft.Web.RunSuccessHandlersPerItem(content);

                  // add arrow after load content
                  if (content.find('.pop-up__arrow').length === 0) {
                    content.append('<i class="pop-up__arrow" />');
                  }

                  // init youtube in iframe
                  if (content.find(ytIframeClass).length > 0) {
                    // reinit
                    var yt = new YoutubeInIframe(ytIframeClass);
                    yt.init();
                  }
              }
          });
      }
    }
  }

  var destroyYoutube = function() {
        // init youtube in iframe
    if ($('.pop-up--active').find(ytIframeClass).length > 0) {
      var yt = new YoutubeInIframe(ytIframeClass);
      yt.destroy();
    }
  }

  var closeEventInit = function() {
    // warning: wrap alway need class .pop-up
    $(document).on('click', function(e) {
      var $target = $(e.target);

      var $thisWrap = $target.closest('.pop-up');
      var clickOnContent = $thisWrap.length > 0;


      if ( clickOnContent )
        return;

      destroyYoutube();
      $('.pop-up--active').removeClass('pop-up--active');

      // mini cart with fresh item in
      if ($('.m-c__item:first-child', document).hasClass('m-c__item--single')) {
        Linksoft.Cart.MiniCart.showOtherItems();
      }
    });

    // close by close button
    $(document).on('click', '.pop-up__close', function(e){
      e.preventDefault();
      var thisWrap = $(this).closest('.pop-up');

      destroyYoutube();
      thisWrap.removeClass('pop-up--active');
    });
  }
  closeEventInit();


  return $(this).each(function(){
    var wrap = this;

    // console.log($(this));
    // popActiveClassName
    if ( $(wrap).attr('popup-init') === "true" ) return;

    // set this param for prevent calling more times on element
    $(wrap).attr('popup-init', true);

    $(wrap).on('click', set.trigger, function(e){
      e.preventDefault();
      init(this, wrap);
    })
  });
};



