import './cart/shiptmentAndPayment';
import './cart/miniCart';

var isDesktop = window.matchMedia && window.matchMedia("(min-width: 1024px)").matches;

class StickyCartTotal {
  constructor() {
    this.$elm = $('.cart__total');
    this.option = {};
  }

  initStickPlugin() {
    this.$elm.stick_in_parent(this.option);
  }

  init() {
    var self = this;
    this.$elm.parent().css('height', this.$elm.parent().prev().outerHeight());
    self.initStickPlugin();
  }
}


if (isDesktop && $('.cart__total').length ) {
  var stickyCartTotal = new StickyCartTotal();
  stickyCartTotal.init();
}