class GetPosition {
  constructor(elem) {
    var elem = this.elem;
  }

  getOffsetRect(elem) {
    var box = elem.getBoundingClientRect()

    var body = document.body
    var docElem = document.documentElement

    var scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop
    var scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft

    var clientTop = docElem.clientTop || body.clientTop || 0
    var clientLeft = docElem.clientLeft || body.clientLeft || 0

    var top  = box.top +  scrollTop - clientTop
    var left = box.left + scrollLeft - clientLeft

    return { top: Math.round(top), left: Math.round(left) }
  }

  center(elm){
    // just for popup ?
    var $popUpContent = $(elm).next();
    var popUpContentWidth = $popUpContent.outerWidth();

    var elmOffsetLeft = this.getOffsetRect(elm).left;
    var elmWidth = $(elm).innerWidth();
    var windowW = $(window).width();


    // TODO: maybe need condition for popup in right of display
    if ( elmOffsetLeft + (popUpContentWidth / 2) >  windowW) {
      return elmOffsetLeft + elmWidth;
    } else {
      return -(popUpContentWidth - elmWidth) / 2;
    }
  }

}

export default GetPosition;