$.evalInContext = function (js, context) {
    return function () { return eval(js); }.call(context);
};

$.fn.switcher = function (options) {
    var opt = $.extend({
        inputYes: '.switcher__input--yes',
        inputNo: '.switcher__input--no',
        onClassName: 'switcher--on',
        trigger: null, // 'on' or 'off'
        callback: function () { }
    }, options);

    function turnOff($elm, $inputNo) {
        $elm.removeClass(opt.onClassName);
        $inputNo.prop('checked', true);
    }

    function turnOn($elm, $inputYes) {
        $elm.addClass(opt.onClassName);
        $inputYes.prop('checked', true);
    }


    return $(this).each(function () {
        var $this = $(this);

        if (opt.trigger === 'off') {
            var inputNo = $this.find(opt.inputNo);
            turnOff($this, inputNo);
        }
        var callback = $this.data("callback");

        $this.on('click', function () {
            var $self = $(this);
            var inputYes = $self.find(opt.inputYes);
            var inputNo = $self.find(opt.inputNo);

            if (inputYes.is(':checked')) {
                turnOff($self, inputNo)
            } else {
                turnOn($self, inputYes)
            }
            if (opt.callback) {
                if (typeof (opt.callback) == "function") {
                    opt.callback.call(this);
                } else {
                    $.evalInContext(opt.callback, this);
                }
            }
            if (callback) {
                if (typeof (callback) == "function") {
                    callback.call(this);
                } else {
                    $.evalInContext(callback, this);
                }
            }
        });
    });
};
