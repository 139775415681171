import GetPosition from './getPosition';


$.fn.tip = function( options ) {
  let opt = $.extend({
    // ownContent: false,
    onHover: true,
    onClick: false,
    // debug: true,
    positionOfTip: 'right',
    beforeInit: function(){},
    afterInit: function(){}
  }, options);

  let thisSelector = this.selector;
  let getPos = new GetPosition();
  let hoverTimeout = null;
  const classActiveByClick = 'active-by-click';
  const classActiveTrigger = 'tip__trigger--active';
  let delay = 400;

  function showContent(content) {
    return `<div class="tip tip--in-body">
              <i class="tip__arrow"></i>
              <span class="tip__content">${content}</span>
            </div>`;
  }

  function setPosition($tip, trigger) {


      // setTimeout(()=>{


          // let triggerEl = $this.outerHeight();
        let triggerElmWidth = $(trigger).outerWidth();
        let fromTop = getPos.getOffsetRect(trigger).top;
        let scollTop = $(window).scrollTop();

        let thisPosTop = fromTop - scollTop;
        let thisPosLeft = getPos.getOffsetRect(trigger).left;
        let thisPosRight = thisPosLeft + triggerElmWidth;
        let thisPosCenter = getPos.getOffsetRect(trigger).left + triggerElmWidth/2;

      // set tip in middle for get right dimentions
      if (opt.positionOfTip === 'top') {
        $tip
            .addClass('tip--top')
            .css({ left: thisPosCenter }) // just for calculate
      }

        let tipWidth = $tip.outerWidth();
        let tipHeight = $tip.outerHeight();




      // console.log(`top ${thisPosTop}`);
      // console.log(`left ${thisPosRight}`);
      // console.log(`tip center ${thisPosCenter}`);
      // console.log(`trigger width ${triggerElmWidth}`);
      // console.log(`tip width ${tipWidth}`);
      // console.log(`tip fromTop ${fromTop}`);
      // console.log(`tip height ${tipHeight}`);


        if (opt.positionOfTip === 'right') {


          // for tip dont dipslay on right when it's near right eage
          if ( (thisPosRight + tipWidth) >  $(window).width() ) {
            $tip.addClass('tip--left');

              $tip.css({
                top: fromTop,
                left: thisPosLeft - tipWidth - 40
              })

          } else {
              $tip.css({
                top: fromTop,
                left: thisPosRight
              })
          }

          // for vertical center
          $tip.css('margin-top', - tipHeight/2 + 10);
        } else if (opt.positionOfTip === 'top') {
          // 22 more in bottom is for tooltip in fulltext list
          if ( thisPosTop - 30  < tipHeight  ) {
            // tip--bottoms
            $tip.css({
              top: fromTop + $(trigger).outerHeight() + 12,
              left: thisPosCenter
            })
          } else {
            // tip--top
            $tip.css({
              top: fromTop - tipHeight - 18,
              left: thisPosCenter
            })
          }
        }




        if (opt.positionOfTip === 'top' && thisPosTop - 30  < tipHeight) {
            $tip.removeClass('tip--top');
            $tip.addClass('tip--bottom');
        }

        $tip.addClass('active');

        // for animation
        setTimeout(()=>{
          $tip.css({
            pointerEvents: 'auto',
            opacity: 1
          });
        }, 10);
      // }, 10);
  }

  function removeTip(delay) {
    // dont close when it's open by click
    if ($('.'+classActiveByClick, document).length) return;

    hoverTimeout = setTimeout(()=>{
      $('body').find('.tip--in-body').remove();

      $('.'+classActiveTrigger).removeClass(classActiveTrigger);

      $('.tip', document).off('mouseenter', stopClose);
      $('.tip', document).off('mouseleave', removeTipRegular);
      $(thisSelector, document).off('mouseenter', stopClose);
    }, delay);
  }

  function removeTipRegular() {
    removeTip(delay);
  }

  function showTip() {
    if ($(this).hasClass(classActiveByClick)) return;
    let $this = $(this);

    if (hoverTimeout !=null) {
      clearTimeout(hoverTimeout);
    }

    if ($this.hasClass(classActiveTrigger)) {
      return;
    } else {
      removeTip(0);

      setTimeout(()=>{
        $this.addClass(classActiveTrigger);


        $('body').append(showContent($this.attr('data-tip')));

        let $tip = $('body').find('.tip--in-body');
        setPosition($tip, this);
      }, 10);
    }
  }


  function stopClose() {
    clearTimeout(hoverTimeout);
  };


  function hideTip() {
    if (opt.debug) return;

    removeTipRegular();

    $('.tip', document).on('mouseenter', stopClose);
    $('.tip', document).on('mouseleave', removeTipRegular);
    $(thisSelector, document).on('mouseenter', stopClose);
  }

  if (opt.onHover) {
    $(document).on('mouseenter', thisSelector, showTip);
    $(document).on('mouseleave', thisSelector, hideTip);
  }

  if ( opt.onClick || !opt.onHover) {
    // by click
    $(document).on('click', thisSelector, function(e){
      let $this = $(this);



      // TODO: update for sigle onclick
      if ( $('.tip__content', $('body')).length ) {
        $('.tip', $('body')).append(`<a href="#" class="tip__close"></a>`);
      }

      $this.addClass(classActiveByClick);
    });

    $(document).on('click', '.tip__close', function(e){
      e.preventDefault();

      removeTip(0);
      $('.'+classActiveByClick, document).removeClass(classActiveByClick);
    });

    $(document).on('click', function(e){
      let $target = $(e.target);
      let clickOnContent = $target.closest('[class^="tip"]').length > 0;
      let clickOnTrigger = $target.is('[class^="tip__wrap"]');

      let clickOnCloseButton = $target.hasClass('tip__close');
      if ( clickOnContent || clickOnCloseButton || clickOnTrigger )
        return;

      hideTip(thisSelector);
    });
  }
};
