// this one have init after font/image loaded
// so it's init in /Scripts/src/components/itemsEqualHeight.js

var isBigScreen = window.matchMedia && window.matchMedia("(min-width: 1400px)").matches;

class Sticker {
  constructor() {
    this.sticker = $('.cat__up-button', document);
    this.trigger = this.sticker.parent();
    this.isFixed = false;
  }

  fixIt() {
    const self = this;
    var top = self.sticker.offset().top - $(window).scrollTop();

    if (self.isFixed) return;

    let left = this.sticker.offset().left;

    self.sticker.css({
      left: left,
      top: 'auto',
      position: 'fixed'
    });

    self.isFixed = true;
  }

  calculate() {
    const self = this;
    var wrapOffsetBot = self.trigger.offset().top + self.trigger.height();

      var bottomTrigger = $(window).scrollTop() + $(window).height();
      if ( bottomTrigger < self.trigger.offset().top + 1000 ) {
        self.sticker
          .attr('style', '')
          .css({ top: 1000 - 30 - 64 }); // 30 is space from bottom, 64 is it's height
        self.isFixed = false;
      } else if ( bottomTrigger >= wrapOffsetBot ) {
        self.sticker.attr('style', '');
        self.isFixed = false;
      } else {
        self.fixIt();
      }

  }

  onScroll() {
    const self = this;

    $(window).on('scroll', function(){
      self.calculate();
    });
  }

  init() {
    const self = this;

    if (self.sticker.length === 0 || !isBigScreen) return;

  	self.calculate();
  	if (!self.onScrollInstalled) {
  		self.onScroll();
  		self.onScrollInstalled = true;
  	}
  }
}


if ( typeof window.Linksoft.Sticker !== 'object' ||
      typeof window.Linksoft.Sticker !== 'function' ) {
  window.Linksoft.Sticker = new Sticker();
}
