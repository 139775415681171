﻿const trigger = '.toggle-text__trigger';

// set short height too all .toggle_text
$('.toggle-text').each(function(){
  $(this).attr('data-shot-height', $(this).outerHeight() );
});



function toggleText(e) {
  e.preventDefault();
  var $this = $(this);

  var newButtonText = $this.attr('data-text');
  var currentButtonText = $this.text();
  // not nice :/
  newButtonText = newButtonText === undefined ? currentButtonText : newButtonText;

  var wrap = $this.prev(); // toggle button must be next toggle content
  var wrapShortHeight = parseInt(wrap.attr('data-shot-height'));
  var holderText = wrap.attr('data-holder');
  var currentText = wrap.html();

  wrap.css({
    maxHeight: wrapShortHeight,
    overflow: 'hidden'
  });

  $this.html('').html(newButtonText);

  // timeout is for animation
  setTimeout(()=>{
    if ( wrap.hasClass('active') ) {
        wrap.css({ maxHeight: wrapShortHeight });
        wrap.removeClass('active');
        $this.removeClass('active');

      setTimeout(()=>{
        wrap
          .html('')
          .html(holderText);
      }, 400)
    } else {

      wrap
        .html('')
        .wrapInner(`<div class="text-toggle__inner">${holderText}</div>`);


      wrap.css({ maxHeight: wrap.find('.text-toggle__inner').height() });
      wrap.addClass('active');
      $this.addClass('active');
    }


    wrap.attr('data-holder', currentText);
    $this.attr('data-text', currentButtonText);
  }, 100);
}

$(document).on('click', trigger, toggleText);