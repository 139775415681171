// you can find it here /mujkosmas/osobniudaje/

$.fn.formBox = function (options) {
    var opt = $.extend({
        callback: function () { }
    }, options);

    return $(this).each(function () {
        var $self = $(this), 
            $edit_tab = $self.find('.edit-tab'),
            $display_tab = $self.find('.display-tab'),
            $add_link = $self.find('.add-more--user-edit'),
            edit_prefix = $edit_tab.find('input[name="Model_prefix"]').val();

        var switchToEdit = function () {
            $display_tab.addClass("hide");
            $add_link.addClass("hide");
            $edit_tab.removeClass("hide");
			$('html, body').animate({ scrollTop: $display_tab.parents('.display-edit-box').offset().top }, 'slow');
        };
        var switchToDisplay = function () {
            $display_tab.removeClass("hide");
            $add_link.removeClass("hide");
            $edit_tab.addClass("hide");
			$('html, body').animate({ scrollTop: $display_tab.parents('.display-edit-box').offset().top }, 'slow');
        };
        var dataToEdit = function ($box) {
            var display_prefix = $box.find('input').attr("id").replace("_Id","");
            $edit_tab.find(':input[id]').each(function(){
                var $this = $(this);
                if (this.tagName == 'SELECT') {
                    var $tag = $('#' + $this.attr("id").replace(edit_prefix, display_prefix));
                    $this.val($tag.data('value'));
                } else {
                    $this.val($('#' + $(this).attr("id").replace(edit_prefix, display_prefix)).text());
                }
            });
            $('#'+edit_prefix+"_Id").val($('#'+display_prefix+"_Id").val());
            $('#'+edit_prefix+"_Type").val($('#'+display_prefix+"_Type").val());
        };

        var fun_btnSel = function (e) {
            e.preventDefault();
            if ($(this).hasClass("icon--check-full")) {
                alert('already!');
                return;
            }
            var id = $(this).data("id");
            Linksoft.Cart.SelectPrimaryAddress(id);
        }
        var fun_btnEdit = function (e) {
            e.preventDefault();

            dataToEdit($(this).parents('.form-box'));
            switchToEdit();

            //$('html, body').animate({ scrollTop: $('#delivery-addresses').offset().top + 10 }, 'quick');
        };
        var fun_btnRemove = function (e) {
            e.preventDefault();

            var id = $(this).data("id");
            Linksoft.Cart.DisableAddresses(id, id);
        };


        var dataToDisplay = function () {
            var display_prefix = "A" + $('#' + edit_prefix + "_Id").val().replace(/_.*$/, '');
            var id = display_prefix.substr(1);
            if (!$('#' + display_prefix + "_Id").length) {
                // nov� adresa
                var $copy = $($display_tab.find('.form-box')[0]).clone();
                var old_prefix = $copy.attr("id").replace("addr_text-", "A");
                $copy.attr("id", "addr_text-" + id);
                $copy.find('[id^="' + old_prefix + '_"]').each(function () {
                    var $this = $(this);
                    $this.attr("id", $this.attr("id").replace(old_prefix, display_prefix));
                    if ($this.attr("name") && $this.attr("name").startsWith(old_prefix)) {
                        $this.attr("name", $this.attr("name").replace(old_prefix, display_prefix));
                    }
                })
                $display_tab.append($copy);

                var $btnEdit = $copy.find('.form-box__edit'),
                    $btnRemove = $copy.find('.form-box__remove');
                $(".add-more--user-edit").removeClass('hide');

                $btnEdit.on('click', fun_btnEdit);
                $btnRemove.on('click', fun_btnRemove);
            }

            $edit_tab.find(':input[id]').each(function () {
                var $this = $(this);
                if (this.tagName == 'SELECT') {
                    var $tag = $('#' + $this.attr("id").replace(edit_prefix, display_prefix));
                    $tag.text($this.find('option:selected').text());
                    $tag.data('value', $this.val());
                } else {
                    $('#' + $this.attr("id").replace(edit_prefix, display_prefix)).text($this.val());
                }
            });
            $('#' + display_prefix + "_Id").val($('#' + edit_prefix + "_Id").val());
            $('#' + display_prefix + "_Type").val($('#' + edit_prefix + "_Type").val());
            $("#addr_text-" + id).removeClass("hide");
        };

        $display_tab.find('.form-box').each(function () {

            var $box = $(this);
            var
                $btnSel = $box.find('.form-box__primary'),
                $btnEdit = $box.find('.form-box__edit'),
                $btnRemove = $box.find('.form-box__remove');

            $btnSel.on('click', fun_btnSel);
            $btnEdit.on('click', fun_btnEdit);
            $btnRemove.on('click', fun_btnRemove);
        });

        $edit_tab.find('.form-box').each(function () {
            var $box = $(this),
                $btnCancel = $box.find('.form-box__cancel'),
                $btnSave = $box.find('.form-box__save');

            $btnCancel.on('click', function (e) {
                e.preventDefault();

                switchToDisplay();
                //$('html, body').animate({ scrollTop: $self.offset().top - 15 }, 'quick');
            });
            $btnSave.on('saved', function (e) {
                e.preventDefault();

                dataToDisplay();
                switchToDisplay();
                //$('html, body').animate({ scrollTop: $self.offset().top - 15 }, 'quick');
            });

        });

        $add_link.on('click', function (e) {
            e.preventDefault();
            var $self = $(this);

            { // reset form
                var typ = $('#' + edit_prefix + "_Type").val();
                $edit_tab.find(':input[id]').each(function () {
                    $(this).val('');
                });
                $('#' + edit_prefix + "_Type").val(typ);
                $('#' + edit_prefix + "_CountryId").val('0');
            }
            switchToEdit();
        });
    });
};

$('.display-edit-box').formBox();
