﻿class DetailCover {
  constructor() {
  }

  processingCover(width, height) {
    var maxHeight = 444; // viz /styles/pages/product-detail/_book-3d.less
    // var marginTop = (maxHeight - height)/2;
    var ratio = width / height;
    var $book3d = $('.book-3d');
    var isLargeScreen = window.matchMedia && window.matchMedia("(min-width: 1200px)").matches;

    // reduce height and veritcal centered
    $book3d.css({
      height: height
    });

    let imageHeight;

    // set width
    if ( width < 270) {
        // small images, most of time it's .gif one
        // was agreed there are 315px width for all generated cover images
        imageHeight = 315 * 1 / ratio;

        $('.product__cover').css({
            width: 315, height: imageHeight
        });

        // aside was overflowing section below when image was too tall (viz bug #9480);
        $('.product__cover').closest('#productDetail').find('article').css('min-height', imageHeight + 40); // 40 is for the margin
        $('.book-3d__cover').css({backgroundSize: 'cover'});

        // make more space bottom
        $('.product__content').css('min-height', 360);
	  } else if (width >= 270 && width < 315) {
        if (!isLargeScreen) { width = 290; }
        imageHeight = width * 1 / ratio;



        $('.product__cover').css({
            width: width, height: imageHeight
        });

        // aside was overflowing section below when image was too tall (viz bug #9480);
        $('.product__cover').closest('#productDetail').find('article').css('min-height', imageHeight + 40); // 40 is for the margin
        $('.book-3d__cover').css({backgroundSize: 'cover'});

        // make more space bottom
        $('.product__content').css('min-height', 360);
    } else {
      $('.product__cover').css({width: width});
      $('.product__cover, .product figure').css({maxWidth: width});

      // make cover centered
    }

    $('.book-3d__title').css({
      width: height,
      bottom: -height
    });

    // disable animation
    if ( ratio > 1 ) {
      $('.book-3d__inner').addClass('book-static');
    }

    // show again /styles/pages/product-detail/_book-3d.less
    $book3d.css('opacity', 1);
  }

  // get if from http://stackoverflow.com/questions/4851395/how-to-get-the-height-of-a-hidden-image WOW
  getImageDimension(el, onReady) {
      var src = typeof el.attr === 'function' ? el.attr('src') : el.src !== undefined ? el.src : el;

      var image = new Image();
      image.onload = function(){
          if(typeof(onReady) === 'function') {
              onReady({
                  width: image.width,
                  height: image.height
              });
          }
      };
      image.src = src;


  }


  init() {
    var self = this;
    var $detailCover = $('#detailCover');
    if ($detailCover.length === 0) return;

    self.getImageDimension($detailCover, function(d){
      self.processingCover(d.width, d.height);
    })
  }
}

var detailCover = new DetailCover();
detailCover.init();