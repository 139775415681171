// filter in table grid
var $filterToggle = $('.filter__toggle');


if ($filterToggle.length) {
	var $filterParent = $filterToggle.closest('.grid-head');
	var $filterWrap = $filterParent.find('.search-body');

	// for first init
	$filterParent.addClass('filter filter--active');

	$filterToggle.on('click', function () {
		$filterParent.toggleClass('filter--active');
		$filterWrap.toggleClass('inactive');
	});
}


// added button to filter
function initButtonForInput() {
	var $this = $(this);
	$this.after('<button class="icon--calendar" id="' + $this.attr('id') + '_btn"></button>');
}

$('.grid-filter.field-datetime-filter').each(initButtonForInput);
$('.grid-filter.field-date-filter').each(initButtonForInput);


