import './../libs/markerclusterer';

var tabs = require('./../libs/jquery-tabs');

const $gMapWrap = $('#mapOfShops');
var markers = [];
var shops = [];
var shopContent = [];


$('.shop__box-wrap').tabs({
    tabClass: '.shop__tab',
    tabContentClass: '.shop__box'
});

var $imageBox = $('.shop__box--2')
if ($imageBox.length) {
    $imageBox.slick({
        autoplay: true,
        autoplaySpeed: 3000
    })
}


class Map {
    constructor(id, isBigMap) {
        this.id = id;
        this.isBigMap = isBigMap === undefined ? false : true;
        this.shops = [];
        this.shopContent = [];
        this.markers = [];
    }

    getLatLng() {
        var self = this;
        if (self.isBigMap) {
            $('.shop__list option').each(function (i) {

                var lat = parseFloat($(this).attr('data-lat'));
                var lng = parseFloat($(this).attr('data-lng'));
                var latLng = [lat, lng];

                var comment = $(this).attr('data-name-comment');
                if (comment) {
                    comment = '<div>' + comment + '</div>';
                }

                var contentHtml = `
                <h3>
                  <a href="/knihkupectvi/${$(this).attr('data-url')}">
                    ${$(this).attr('data-name')}
                  </a> ${comment}
                </h3>
                <address>
                  <span class="shop__address">${$(this).attr('data-address')}</span><br/>
                  <span class="shop__tel">Tel:&nbsp;${$(this).attr('data-tel')}</span><br/>
                  <span class="shop__email">Email:&nbsp;<a href="mailto:${$(this).attr('data-e-mail')}">${$(this).attr('data-e-mail')}</a>
                  </span>
                </address>
                <div class="shop__open-time">${$(this).attr('data-open-time')}</div>
                <div class="shop__open-hour">${$(this).attr('data-open-hour')}</div>
                <p>${$(this).attr('data-description')}</p>`;

                self.shops.push(latLng);
                self.shopContent.push(contentHtml);

                //TODO: remove this fast hack
                // var $locationText = $(this).find('h3 a');
                // var newText = $locationText.text().replace("Knihkupectví ","");
                // $locationText.text(newText);
            });
        } else {
            var lat = parseFloat($('#' + self.id).attr('data-lat'));
            var lng = parseFloat($('#' + self.id).attr('data-lng'));
            var latLng = [lat, lng];

            self.shops.push(latLng);
        }

    }

    setMarkers(map) {
        var self = this;
        var infoWindow = new google.maps.InfoWindow();
        var image = {
            url: '/images/pin.png',
            size: new google.maps.Size(39, 60),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 60)
        };

        for (var i = 0; i < self.shops.length; i++) {
            var shop = self.shops[i];

            var lat = shop[0];
            var lng = shop[1];

            var marker = new google.maps.Marker({
                position: { lat: lat, lng: lng },
                animation: google.maps.Animation.DROP,
                map: map,
                icon: image
            });

            if (self.isBigMap) {
                // Allow each marker to have an info window
                google.maps.event.addListener(marker, 'click', (function (marker, i) {
                    return function () {


                        // fix problem with positioning google popup
                        // http://stackoverflow.com/questions/26791143/wrong-positioning-of-google-maps-infowindow
                        //create the dummy-marker on first click
                        if (!map.get('dummy')) {
                            map.set('dummy', new google.maps.Marker({ map: map, visible: false }))
                        }
                        var latLng = marker.getPosition();

                        //when the marker is within a cluster
                        if (!marker.getMap()) {
                            //set position of the dummy
                            map.get('dummy').setPosition(latLng);
                            //use dummy as infowindow-anchor
                            marker = map.get('dummy');
                        }

                        infoWindow.setContent(self.shopContent[i]);
                        infoWindow.open(map, marker);
                    }
                })(marker, i));

                self.markers.push(marker);
            }
        }

        $('.shop__list').on('change', function (e) {
            $(this).css('max-width', '500px');
            var i = $('.shop__list option:selected').index();


            google.maps.event.trigger(self.markers[i], 'click');
        });

        // $('.shop__list h3 > a').each(function(i){
        // });

        var clusterStyles = [
            {
                textSize: 16,
                textColor: 'white',
                url: '/images/round/round1.png',
                height: 47,
                width: 47
            }
        ];

        var markerCluster = new MarkerClusterer(map, self.markers, {
            styles: clusterStyles,
            imagePath: '/images/round/round'
        });

    }

    initGmap() {
        var self = this;

        self.getLatLng();

        var optBig = {
            zoom: 8,
            center: { lat: 49.7556898, lng: 15.8265969 }
        }

        var optOne = {
            zoom: 10,
            center: { lat: self.shops[0][0], lng: self.shops[0][1] }
        }
        var option = self.isBigMap ? optBig : optOne;
        var map = new google.maps.Map(document.getElementById(self.id), option);

        self.setMarkers(map);
    }

    shopListShowHide() {
        $('.shop__list').on('click', 'li:not(.active)', function () {
            $('.shop__list li').addClass('in-active');
            $(this)
                .removeClass('in-active')
                .addClass('active');
            $('.shop__list')
                .addClass('just-one')
                .append(`<a class="link-more link-more--down" href="">Zobrazit další</a>`);
        });

        $('.shop__list').on('click', '.link-more', function (e) {
            e.preventDefault();



            $('.shop__list li')
                .removeClass('in-active')
                .removeClass('active');
            $(this).remove();
            $('.shop__list').removeClass('just-one');
        });
    }

    init() {
        var self = this;

        if ($('#' + self.id).length === 0) return;

        self.shopListShowHide();

        // hack static page style
        $('.page__static > .container')
            .removeClass('container');
        $('.page__static').removeClass('page__static')


        window.linksoftInitGmap = function () {
            self.initGmap();
        }

        var googleInitCode = '<script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD9xnx6xzDiwveuzHApDmEJ-KxNQabixgE&callback=window.linksoftInitGmap"></script>';
        $(googleInitCode).appendTo(document.body);
    }
}

if ($('#mapOfShops').length) {
    var bigMap = new Map('mapOfShops', 'bigMap');
    bigMap.init();
} else {
    var showKde = false;

    if ($('#shopGmap').data('lat')) {
        var shopMap = new Map('shopGmap');
        shopMap.init();
        showKde = true;
    } else {
        $($(".shop__switcher .shop__tab")[0]).hide();
    }
    if ($('.shop__box--2 img').length == 0) {
        $($(".shop__switcher .shop__tab")[1]).hide();
    } else {
        showKde = true;
    }

    if (!showKde) {
        $('.box.shop__box-wrap').parent().hide();
        $('h2.box__title:contains("POPIS")').parent().parent().removeClass("col-md-push-6").addClass("col-md-push-3");
    }
}