﻿$.fn.sticky = function (options) {
  var opt = $.extend({
    fixElm: '> nav',
    callback: function () { }
  }, options);


  return $(this).each(function(){
    var $self = $(this),
        $fixElm = $self.find(opt.fixElm),
        offsetTop = $self.offset().top;

    // add height to wrap, make it "placeholder"
    // prevent page jumping
    $self.css('height', $fixElm.outerHeight());

    function setSticky() {
      if ($(window).scrollTop() > offsetTop) {
        $fixElm.addClass('fixed');
      } else {
        $fixElm.removeClass('fixed');
      }

    }

    function init() {

      setSticky();
      $(window).on('scroll', setSticky);
      opt.callback($self, $fixElm);
    }
    init();
  });
};
