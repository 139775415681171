class YoutubeInIframe {
	constructor(elm) {
		this.$elm = $(elm)
	}

	destroy() {
		this.$elm.each(function(){
			$(this).find('iframe').remove();
		});
	}

	init() {
		this.$elm.each(function(){
			var $this = $(this);
			var link = $this.attr('data-yt-src');
			var iframe = `<iframe src="${link}" width="640" height="360"></iframe>`;
			if ( $this.find('iframe').length ) return;
			$this.append(iframe);
		})
	}
}

export default YoutubeInIframe;