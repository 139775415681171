var tip = require('./../libs/jquery-tip');

// this one make heart icon never fall to second line
// when text is long, heart icon will always behind last word

const $wrap = $('.title-with-heart');
const $title = $wrap.find('> *:first-child');
const word = $title.text().split(" ");

$title.html('');
$.each(word, function(i, w){
  var w = w.trim();
  if (w.length == 0) return true;

  $title.append($("<span>").text(w + ' '));
});

var $lastSpan = $title.find('span:last-child');
$lastSpan.text($lastSpan.text().trim());
$lastSpan.append(`&nbsp;<a class="pop-up__toggle" href="">
	<i data-tip="Přidat do oblíbených" class="icon--heart tip--shuffle"></i></a>`)


// fix for this crazy name
// /autor/42041/lenka-hornakova-civade/
$wrap.each(function(){
  var $this = $(this);
  var parentW = $this.parent().width();
  var thisW = $this.width();

  if ( parentW < thisW ) {
    $this.find('h1 span:last-child').css('white-space', 'normal');
  }
});


$('.tip--shuffle').tip({
	// debug: true,
    positionOfTip: 'top',
    beforeInit: function(){
    	if ( $('.pop-up--favorite').hasClass('pop-up--has-content') ) {
    		$('.tip--shuffle').attr('data-tip', 'Odebrat z oblíbených')
    	} else {
    		$('.tip--shuffle').attr('data-tip', 'Přidat do oblíbených')
    	}
    }
})