window.Watch = {

    data: {},

    maxPriceChanged() {
        const value = Number($('#max-price').val());

        if(value > this.articlePrice)
            $('#max-price').val(this.articlePrice);
        if(value < 1)
            $('#max-price').val(1);

        this.wdCheckboxesChanges[5] = true;
    },

    getUserEmail() {
        this.data.anonymousUserEmail = $('#anonymous-user-email').val();
        this.data.dbCurrentUsersEmail = $('#dbCurrentUsersEmail').text();
        if(this.data.isDbCurrentUserIsRegistered) {
            return this.data.dbCurrentUsersEmail;
        } else {
            return this.data.anonymousUserEmail;
        }
    },

	saveWatchDogs() {
		if (!this.data.isDbCurrentUserIsRegistered) {
			var $email = $('#anonymous-user-email');
			if ($email.val() === '' || !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test($email.val())) {
				$email.addClass("input-validation-error");
				console.log('invalid email');
				return;
			} else {
				$email.removeClass("input-validation-error");
			}
		}

        this.allWatchDogMasks = [];

        if(this.wdCheckboxesChanges[0])
            if($('#checkbox-1:checked').length > 0)
                this.allWatchDogMasks.push(this.data.WatchDogVariantMasksThisVariant);
            else
                this.deleteWatchDogByMask(this.data.WatchDogVariantMasksThisVariant);
        if(this.wdCheckboxesChanges[1])
            if($('#checkbox-2:checked').length > 0)
                this.allWatchDogMasks.push(this.data.WatchDogVariantMasksAnyVariant);
            else
                this.deleteWatchDogByMask(this.data.WatchDogVariantMasksAnyVariant);
        if(this.wdCheckboxesChanges[2])
            if($('#checkbox-3:checked').length > 0)
                this.allWatchDogMasks.push(this.data.WatchDogVariantMasksPrinted);
            else
                this.deleteWatchDogByMask(this.data.WatchDogVariantMasksPrinted);
        if(this.wdCheckboxesChanges[3])
            if($('#checkbox-4:checked').length > 0)
                this.allWatchDogMasks.push(this.data.WatchDogVariantMasksEbook);
            else
                this.deleteWatchDogByMask(this.data.WatchDogVariantMasksEbook);
        if(this.wdCheckboxesChanges[4])
            if($('#checkbox-5:checked').length > 0)
                this.allWatchDogMasks.push(this.data.WatchDogVariantMasksAudio);
            else
                this.deleteWatchDogByMask(this.data.WatchDogVariantMasksAudio);

        this.allWatchDogMasks.forEach(mask => this.saveWatchDog(this.data.ModelArticleId, this.getUserEmail(), this.data.ModelCategory, null, mask));

        let _maxPrice = null;

        if(this.wdCheckboxesChanges[6]) {
            if($('#checkbox-7:checked').length > 0) {
                _maxPrice = this.articlePrice;
            }
        }

        if(this.wdCheckboxesChanges[5]) {
            if($('#checkbox-6:checked').length > 0) {
                _maxPrice = parseFloat($('#max-price').val());
                if(_maxPrice === Infinity || isNaN(_maxPrice) || _maxPrice === undefined) {
                    _maxPrice = null;
                    alert("Bylo zadano neplatne cislo");
                }
            }
        }

        if(this.wdCheckboxesChanges[5] || this.wdCheckboxesChanges[6]) {
            if (_maxPrice === null) {
                this.deleteWatchDogWithMaxPrice();
            } else {
                this.saveWatchDog(this.data.ModelArticleId, this.getUserEmail(), this.data.ModelCategory, _maxPrice, this.data.WatchDogVariantMasksAnyVariant);
            }
        }

        $('#sojka').closest('.pop-up').addClass('pop-up--has-content');

    },

    saveWatchDog(_articleId, _email, _fromcat, _maxPrice, _variantMasks) {
        // this one must be here, because Linksoft.BtnPending is come from script from bottom page, sorry
        const btnPending = new Linksoft.BtnPending('#saveWatchDogBtn'); // from /Scripts/src/components/btnPending.js
        btnPending.run();
        // console.log('articleId:' + _articleId, 'email:' + _email, 'fromCat:' + _fromcat, 'maxPrice:' + _maxPrice, 'variantMask:' + _variantMasks);

        Linksoft.Web.DoAjax({
            url: Linksoft.Web.ActionUrls.SetWatchDog,
            data: {articleId: _articleId, email: _email, fromCat: _fromcat, maxPrice : _maxPrice, variantMasks: _variantMasks },
            blanket: false,
			successHandler: () => btnPending.loadSuccess(() => this.$saveWatchDogBtn.hide())
        });
    },

    deleteWatchDogByMask(_variantMasks) {
        const btnPending = new Linksoft.BtnPending('#saveWatchDogBtn');
        btnPending.run();
        Linksoft.Web.DoAjax( {
            url: Linksoft.Web.ActionUrls.DeleteWatchDogByMask,
            data: { articleId: this.data.ModelArticleId, variantMasks: _variantMasks  },
            blanket: false,
            successHandler: () => btnPending.loadSuccess(() => this.$saveWatchDogBtn.hide())
        });
    },

    deleteWatchDogWithMaxPrice() {
        const btnPending = new Linksoft.BtnPending('#saveWatchDogBtn');
        btnPending.run();
        Linksoft.Web.DoAjax({
            url: Linksoft.Web.ActionUrls.DeleteWatchDogWithMaxPrice,
            data: { articleId: this.data.ModelArticleId },
            blanket: false,
            successHandler: () => btnPending.loadSuccess(() => this.$saveWatchDogBtn.hide())
        });
    },

    init() {

        /* GET DATA OR PREVENT FROM RUNNING */
        this.$articlePrice = $('#articlePrice');
        if(!this.$articlePrice.length) return;

        this.data.articlePrice = parseInt(this.$articlePrice.text());
        this.dbCurrentUserIsRegisteredText = $('#dbCurrentUserIsRegistered').text().toLowerCase();
        this.data.isDbCurrentUserIsRegistered = (this.dbCurrentUserIsRegisteredText === 'true');
        this.data.dbCurrentUsersEmail = $('#dbCurrentUsersEmail').text();
        this.data.WatchDogVariantMasksThisVariant = parseInt($('#WatchDogVariantMasksThisVariant').text());
        this.data.WatchDogVariantMasksAnyVariant = parseInt($('#WatchDogVariantMasksAnyVariant').text());
        this.data.WatchDogVariantMasksPrinted = parseInt($('#WatchDogVariantMasksPrinted').text());
        this.data.WatchDogVariantMasksEbook = parseInt($('#WatchDogVariantMasksEbook').text());
        this.data.WatchDogVariantMasksAudio = parseInt($('#WatchDogVariantMasksAudio').text());
        this.data.ModelArticleId = parseInt($('#ModelArticleId').text());
        this.data.ModelCategory = parseInt($('#ModelCategory').text());
        this.data.anonymousUserEmail = $('#anonymous-user-email').val();
        this.wdCheckboxesChanges = [];
        this.$saveWatchDogBtn = $('#saveWatchDogBtn');

        /* TYPECHECKING */
        // for (const key of Object.keys(this.data)) {
        //     console.log(key, this.data[key], typeof this.data[key]);
        // }

        this.$saveWatchDogBtn.hide();
        $('input').on('change paste', () => this.$saveWatchDogBtn.show());
        for (let i = 0; i < 7; i++) { this.wdCheckboxesChanges.push(false);}
        this.$saveWatchDogBtn.on('click', (e) => {
            e.preventDefault();
            this.saveWatchDogs()
        });
    }
};

Watch.init();