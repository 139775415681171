import './../libs/jquery-tabs';
import RandomGenerator from './randomGenerator';


class Menu {
  constructor(elm) {
      this.$elm = $(elm);
  }

  choosenTitleInit() {
    var $wrap = $('#choosen__one');
    var $items = $wrap.find('.book__item')
    var itemCount = $items.length;
    var randomGenerator = new RandomGenerator(0, itemCount);

    for (var i = 0; i < $items.length; i++) {
      // console.log(randomGenerator.get());
    }

  }

  showSubMenu() {
    var self = this;

    function showSubMenuProcedure(e) {
      //console.log('procedure xXX');
      if("ontouchstart" in document.documentElement) {
        e.preventDefault();
      }
      var $this = $(this);
      //var $this = thisParam;

      self.$elm.find('.main-nav__item').removeClass('active');
      $this.addClass('active');

      var $renderContent = $this.parent().find('.render-later');


      $renderContent.each(function(){
          var $loader = $(this);
          var url = $loader.data('href');
          var loader_id = $loader.attr('id');
          if (!loader_id) {
              loader_id = "showSumMenuAUtO" + (Menu._showSubMenuIdCounter++).toString();
              $loader.attr('id', loader_id)
          }

          if ($loader.data('render-later-done') || $loader.data('render-later-pending')) return;
              $renderContent.addClass('loading');
              $loader.data('render-later-pending', true);
              setTimeout(function () { $loader.data('render-later-pending', false); }, 120000); // 120s
            Linksoft.Web.LoadByAjax(loader_id, {
              url: url,
              cache: true,
              ":background": true,
              successHandler: function (section) {
                  $loader.data('render-later-done', true);
                  $loader.data('render-later-pending', false);
                  // self.choosenTitleInit()

                  $loader.removeClass('loading');
                  // reinit tabs
                  $('.sub-menu__inner').tabs();
              }
          });
      });
    }
    //self.$elm.on('mouseover', '.main-nav__item.has-submenu', showSubMenuProcedure);
    //self.$elm.on('click', '.main-nav__item.has-submenu', showSubMenuProcedure);
    var mouseoutTimer; // timer to hide after mouse out
      self.$elm.on('mouseleave', '.main-nav__item.has-submenu,.sub-menu', function (e) {
        //console.log('off');
        mouseoutTimer = setTimeout(function() {
          $(document).click();
          //self.close();
          //console.log('off timer');
        }, 1000); // timeout 1 second before it disappears after you leave the popup
      });
      self.$elm.on('mouseenter', '.main-nav__item.has-submenu,.sub-menu', function (e) {
        clearTimeout(mouseoutTimer);
      });
      self.$elm.on('mouseenter', '.main-nav__item.has-submenu', showSubMenuProcedure);
      self.$elm.on('click', '.main-nav__item.has-submenu', showSubMenuProcedure);
  }

  switchingCat() {
    var self = this;
    self.$elm.on('click', '.sub-m-cat__item', function() {
      var $this = $(this);
      $this.parent().find('.sub-m-cat__item').removeClass('active');
      $this.addClass('active')
    });
  }

  close() {
    var self = this;
      // close by click on document

    self.$elm.on('click', '.sub-menu__close', function(){
      $('.main-nav__item').removeClass('active');
    })

    $(document).on('click', function(e) {
      var $target = $(e.target);

      var clickOnTrigger = $target.hasClass('main-nav__item');
      var clickOnContent = $target.closest('.sub-menu').length > 0;

      if ( clickOnContent || clickOnTrigger )
        return;

      $('.main-nav__item').removeClass('active');
    });
  }

  init() {
    Menu._showSubMenuIdCounter = 1;
    this.showSubMenu();
    this.switchingCat();
    this.close();

    $('.sub-menu__inner').tabs();
  }
}

var menu = new Menu('.main-nav');
menu.init();

export default Menu;

