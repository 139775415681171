var $youtubeVideo = $('.youtube-video');

if ($youtubeVideo.length) {
  var waypoint = new Waypoint({
    element: $youtubeVideo,
    handler: function(direction) {
      if ($youtubeVideo.attr('src').length === 0)
        $youtubeVideo.attr('src', $youtubeVideo.attr('data-src'));
    },
    offset: '100%'
  });
}
