﻿$.fn.activeSection = function( options ) {
  var opt = $.extend({
    link: 'a',
    offset: 150,
  	sectionWrap: '.section__wrap',
  	callbefore: function () { },
  	callback: function () { }
  }, options);


  return $(this).each(function(){
    var $self = $(this),
        links = $self.find('li');


    function scrollToSection(e) {
      e.preventDefault();

      var scrollAnchor = $(this).attr('href');
      var anchor = document.querySelector(scrollAnchor)

	    opt.callbefore(this,anchor);

	    smoothScroll.animateScroll(anchor, null, { "offset": opt.offset - 50, callback: opt.callback });
    }

    function setAtive() {
      var windScroll    = $(window).scrollTop(),
          $wrap         = $(opt.sectionWrap),
          wrapOffsetTop = $wrap.offset().top,
          wrapHeight    = $wrap.height(),
          wrapOffsetBot = wrapHeight + wrapOffsetTop,
          relativeTop   = windScroll - wrapOffsetTop + opt.offset,
          isBetweenWrap = windScroll > wrapOffsetTop - opt.offset*2 && windScroll <  wrapOffsetBot;

      $(opt.sectionWrap).find('[data-section-active]').each(function(i){
        // console.log('windScroll: '+windScroll +'  xx: ' + xx);
        if ($(this).position().top <= relativeTop && isBetweenWrap) {
          links
            .removeClass('active');
          links.addClass('inactive');
          links.eq(i)
            .removeClass('inactive')
            .addClass('active');
        }
      });

      if (!isBetweenWrap) {
        links
          .removeClass('inactive')
          .removeClass('active');
      }
    }

    function init() {
      $self.on('click', opt.link, scrollToSection);
      $(window).on('scroll', $.throttle(200, setAtive));
    }

    init();
  });
};
