﻿import GetPosition from './../libs/getPosition';
var getPosition = new GetPosition();

class MiniCart {
	constructor(url) {
		this.url = url;
		this.hideBox;
		this.hideOneItem;
	}

	update(onSuccess) {
		//TODO: add loader
		var self = this;
		$.ajax({
			url: self.url,
			data: self.data,
			type: 'GET',
			error: function () {
			},
			success: function (data) {
				self.data = null;

				$(document).find('#minicart').html('');
				$(document).find('#minicart').append(data);

				// this one init just after use function showStatic() or show()
				$('#basket-in').addClass('pop-up--active')
				if (onSuccess !== undefined) onSuccess();
			}
		});
	};

	scrollToItem(id) {
		var $wrap = $(document).find('.m-c__items');
		$wrap.animate({
			scrollTop: $('#miniCartItem-' + id).position().top - 10
		}, 1000);
	}

	closeMiniCart() {
		$('#basket-in').removeClass('pop-up--active');
	}

	show(onSuccess) {
		this.update(onSuccess);
	};

	showAddedItem() {
		$('#basket-in').addClass('pop-up--active');
		var $itemFirstChild = $('.m-c__item.m-c__added', document);
		var $itemOthers = $('.m-c__item:not(.m-c__added)', document);
		var $miniCart = $('#minicart', document);

		// make added item blink, follow /styles/pages/cart/_mini-cart.less
		$itemFirstChild.addClass('m-c__item--single')
		$itemOthers.addClass('in-active');
		$miniCart.find('.m-c__normal').addClass('hide');
		$miniCart.find('.m-c__add').removeClass('hide');
	}

	showOtherItems() {
		var $itemFirstChild = $('.m-c__item.m-c__added', document);
		var $itemOthers = $('.m-c__item:not(.m-c__added)', document);
		var $miniCart = $('#minicart', document);

		$itemFirstChild.removeClass('m-c__item--single')
		$itemOthers.removeClass('in-active');
		$miniCart.find('.m-c__normal').removeClass('hide');
		$miniCart.find('.m-c__add').addClass('hide');
	}

	closePersonalMenuPopup(element) {
		const $personalMenuPopUp = element.closest('.header__body').find('.header__button');
		$personalMenuPopUp.removeClass('pop-up--active');
	}

	// this function show cart after click on button add to cart!!!
	showStatic(articleId, defectTypeId) {
		var self = this;
		// for what is this.data????
		this.data = { articleId: articleId, defectTypeId: defectTypeId };
		var triggerBox = $('#basket-in');
		var $box = $('#basket-in .mini-cart__content');
		var $header = $('.header');
		var headerHeight = $header.outerHeight();
		var right = $(window).width() - (getPosition.getOffsetRect(triggerBox[0]).left + triggerBox.width());


		function resetPostion() {
			$box.removeClass('fixed').attr('style', '');
		}

		function setFixed() {
			var scrollTop = $(window).scrollTop();

			if (scrollTop > headerHeight) {
				$box.addClass('fixed')
					.css('right', right);
			} else {
				resetPostion()
			}
		}
		setFixed();

		this.show(function () {

			self.showAddedItem();

			// prevent previous close
			clearTimeout(self.hideBox);

			// hide box
			self.hideBox = setTimeout(() => {
				// hide animation
				$box.css('opacity', 0);

				setTimeout(() => {
					self.closeMiniCart();

					self.showOtherItems();

					resetPostion();
					$(window).off('scroll', setFixed);
				}, 300); // this time must be same /styles/pages/cart/_mini-cart.less


			}, 5000);

		});


		$(window).on('scroll', setFixed);
	};

	init() {
		var self = this;
		var pending = false;
		var hidePopupTimeout;
		if (!$('body').hasClass("view-cart-index")) {
			$(document).on('mouseover', '#basket-in', function (e) {
				clearTimeout(hidePopupTimeout);
				var $target = $(e.target);

				self.closePersonalMenuPopup($target);

				if ($target.hasClass('pop-up__close')) return;
				if ($('#minicart .loading--sm').length) {

					if ($('body').data('loading-minicart')) return;
					$('body').data('loading-minicart', 1);
					if ($('#minicart .loading--sm:visible').length == 0) {
						self.show();
					}
				} else {
					$('#basket-in').addClass('pop-up--active');
				}
			});
			$(document).on('mouseleave', '#basket-in', function (e) {
				hidePopupTimeout = setTimeout(() => {
					self.closeMiniCart();
				}, 1000);
			})
		}

		$(document).on('mouseover', '.mini-cart__content', function () {
			clearTimeout(self.hideBox);
			clearTimeout(self.hideOneItem);
			clearTimeout(hidePopupTimeout);
		});

		$(document).on('mouseleave', '.mini-cart__content', function (e) {
			if (!$('.m-c__item:first-child', document).hasClass('m-c__item--single')) return;

			// close and clear content
			self.hideOneItem = setTimeout(() => {
				self.closeMiniCart();
				self.showOtherItems();
			}, 1000)
		});
	};


}


Linksoft.Cart.MiniCart = new MiniCart('/cart/minicart');

Linksoft.Cart.MiniCart.init();