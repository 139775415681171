class NewReview {
	constructor() {
		this.$id = $('#showReviewForm');
		this.articleId = this.$id.attr('data-id');
		this.formCat = this.$id.attr('data-form-cat');
		this.url = "/Detail/ReviewEditor?articleId=" + this.articleId + "&fromCat=" + this.formCat;

		this.formWrapId = '#newreviewcontainer';
		this.submitBtnId = '#sendNewReview';
		this.isOpen = false;
	}

	showForm() {
		var self = this;

		$(self.formWrapId).addClass('active');
		self.$id
			.removeClass('btn--green')
			.addClass('btn--grey')
		self.setSave();

	}

	closeForm(e) {
		var self = this;

		e.preventDefault();

		$(self.formWrapId)
			.removeClass('active');

		self.$id
			.removeClass('btn--grey')
			.addClass('btn--green')

	}

	validate() {
		var self = this;

		$('#review_body').on('keyup', $.debounce(200, function () {
			if ($(this).val().trim().length > 0) {
				$('.p-r__send').addClass('active');
			} else {
				$('.p-r__send').removeClass('active');
			}
		}));
	}

	setSave() {
		var self = this;
		var isClick = false;

		$(self.submitBtnId).on('click', function (e) {
			e.preventDefault();

			if (isClick) return;


			var rating = $('#hidden-rating').val();
			var thisForm = $(this).closest('form');
			var text = thisForm.find('textarea').val();

			$(self.submitBtnId).addClass('btn--pending');

			Linksoft.Web.DoAjax({
				url: Linksoft.Web.ActionUrls.StoreReview,
				data: {
					articleId: self.articleId,
					rating: rating,
					title: '',
					text: text,
					nick: ''
				},
				blanket: false,
				success: function (result, textStatus, jqXHR) {
					$(self.submitBtnId).removeClass('btn--pending');
					// clear textarea

					$('#review-editor-form').addClass('hidden');
					thisForm.find('textarea').val('');

					Linksoft.Web.HandleReplacements()(result, textStatus, jqXHR);

					// remove add comment box after comment
					$('.p-r__add-box').remove();
					$('.p-r__form', document).addClass('p-r__form--no-arrow');
					if (! $('#user-reviews article[data-pending="True"]').length) {
						$('.p-r__thx p').hide();
					}
					$('.p-r__thx').removeClass('hidden');

					// reinit stars and disable choises
					window.Linksoft.SetRating.init();
					$('.rating__stars--readonly').barrating('readonly', true);
				}
			});

			isClick = true;
		});
	}

	init() {
		var self = this;

		self.$id.find('.rating__stars').barrating('readonly', true);

		if (self.$id.length === 0) return;

		self.validate();

		self.$id.on('click', function (e) {
			e.preventDefault();
			self.showForm();
		});

		$(document).on('click', '.btn__close, .p-r__thx .btn', function (e) {
			self.closeForm(e);
		});
	}
}

var newReview = new NewReview();
newReview.init();



$(document).on('click', '#toReview', function (e) {
	e.preventDefault();
	smoothScroll.animateScroll($('#reviewReader')[0], null, { "offset": 100 });
});

$(document).on('click', '#newReviewTop', function (e) {
	e.preventDefault();
	smoothScroll.animateScroll($('#reviewReader')[0], null, { "offset": 100 });

	setTimeout(() => {
		newReview.showForm();
	}, 200)
});