class ArticlePopup {
    constructor() {
        this.popUp = $('.article__popup');
    }

    showPopup(element) {
        const $popup = $(element).parent().find(this.popUp);
        $popup.addClass('visible');
    }

    hidePopup(element) {
        const $popup = $(element).parent().find(this.popUp);
        $popup.removeClass('visible');
    }
}

const newArticlePopup = new ArticlePopup();

$('.prod-detail__last-column').on("mouseenter", function (e) {
    const $this = $(this);
    newArticlePopup.showPopup($this);
});

$('.prod-detail__last-column').on("mouseleave", function (e) {
    const $this = $(this);
    newArticlePopup.hidePopup($this);
});

class GridPopup extends ArticlePopup {
    constructor() {
        super();
    }

    getPopUpPosition(popup) {
        return {
            offsetTop: ($(popup).offset().top - $(window).scrollTop()),
            offsetLeft: $(popup).offset().left,
            offsetRight: ($(window).width() - ($(popup).offset().left + $(popup).outerWidth())),
            offsetBottom: ($(window).height() - (($(popup).offset().top - $(window).scrollTop()) + $(popup).height())),
        }
    }
	positionPopUp(popup) {
		if (popup.length == 0) return;

        const popUpPosition = this.getPopUpPosition(popup);

        const isTopLeft = popUpPosition.offsetLeft > 0 && popUpPosition.offsetBottom > 0;
        const isTopRight = popUpPosition.offsetLeft < 0 && popUpPosition.offsetBottom > 0;
        const isBottomLeft = popUpPosition.offsetLeft > 0 && popUpPosition.offsetBottom < 0;
        const isBottomRight = popUpPosition.offsetLeft < 0 && popUpPosition.offsetBottom < 0;

        switch(true) {
            case isTopLeft:
                $(popup).addClass('topleft');
                break;
            case isTopRight:
                $(popup).addClass('topright');
                break;
            case isBottomLeft:
                $(popup).addClass('bottomleft');
                break;
            case isBottomRight:
                $(popup).addClass('bottomright');
                break;
            default:
                console.log('popup position is weird', {popup: popup});
        }

    }
    resetPopUpPosition(popup) {
        $(popup).removeClass('topleft topright bottomleft bottomright')
    }
}
module.exports = GridPopup;