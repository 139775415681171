import './product-detail/review';
import './product-detail/cover';
import './product-detail/watch';

import GetPosition from './libs/getPosition';
var getPosition = new GetPosition();


var tabs = require('./libs/jquery-tabs');
var sticky = require('./libs/jquery-sticky');
var activeSection = require('./libs/jquery-activeSection');
var popUp = require('./libs/jquery-popUp');

$('.product__content .tabs').tabs();

function productStickyInit() {
    $('.sticky').sticky({
        callback: function($elm, $fixElm){
            function makeStatic() {
                var winHeight = $(window).height();
                var elmOffsetBottom = $elm.offset().top + $elm.height();
                var hitBottom = $(window).scrollTop() + winHeight >= elmOffsetBottom;

                if ( hitBottom && $(window).scrollTop() < $elm.offset().top) {
                    $fixElm.addClass('static');
                } else {
                    $fixElm.removeClass('static')
                }
            }

            makeStatic();
            $(window).on('scroll', makeStatic);
        }
    });


}
productStickyInit();

// reinit sticky after resize window
$(window).on('resize', $.throttle(200, productStickyInit));

let $productNav = $('#productNav');
if ($productNav.length) {
    $productNav.activeSection();

    if ( $('li', $productNav).length > 5 ) {
        $productNav.addClass('p-nav__anchors--smaller')
    }
}


$('.pop-up--illu').popUp({
    setCenter: false,
    dynamicArrow: true,
    afterOpen: function(wrap, trigger) {
        var $wrap = $(wrap);
        var popupHeight = $('.pop-up__main', $wrap).height();
        var winScrollBot = $(window).scrollTop() + $(window).height();
        var contentOffsetBot = getPosition.getOffsetRect($('.pop-up__main', $wrap)[0]).top + popupHeight;

        // make window scroll down when popup it's not visible,
        // maybe add this to jquery-popUp.js?
        if ( contentOffsetBot > winScrollBot ) {
            smoothScroll.animateScroll( popupHeight + 100 );
        }

    }
});

// this one prevent setup number of tabs in product detail
// default is <nav class="tabs__navs--4">
// and we need nav class has same amount by tabs
function changeNumberOfTabs() {

  var productTabs = $('.product__tabs');
  if (productTabs.length === 0) return;

  var tabs = productTabs.find('a');

  productTabs
    .removeClass('tabs__navs--4')
    .addClass('tabs__navs--' + tabs.length)
}

changeNumberOfTabs();
