class SetRating {
  constructor(elm) {
    this.elm = elm;
  }

  init() {
    var self = this;
    $(self.elm).barrating('show', {
        theme: 'default',
        onSelect: function (value, text, event) {
            if (typeof (event) !== 'undefined') {
                var exec = this.$elem.data("setrating");
				exec = exec.replace(/\{0\}/g, value);
                eval(exec);
            } else {
          }
        }
    });
  }
}

var setrating = new SetRating('.rating__stars');
setrating.init();

$('.rating__stars--readonly').barrating('readonly', true);

if ( typeof window.Linksoft.setrating !== 'object' ||
      typeof window.Linksoft.setrating !== 'function' ) {
  window.Linksoft.SetRating = setrating;
}

export default setrating;

