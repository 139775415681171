var $catMenu = $('#catMenu');

function CatMenu() {
  if ($catMenu.length === 0) return;

  // $catMenu.find('a').on('click', function(e){
  //   e.preventDefault();
  // });

  // var $showAllBtn = $('.cat-list__link');
  // var $firstlevelInactive = $catMenu.find('.cat__level-1.in-active');
  // var $active = $catMenu.find('.active:not(.cat__level-1)');

  // // show parents
  // $active.parents().removeClass('in-active');
  // // show siblins
  // $active.parent().find('li').removeClass('in-active');


  // $showAllBtn.on('click', function(e){
  //   e.preventDefault();

  //   $firstlevelInactive.removeClass('in-active');

  //   $showAllBtn.hide();
  // });
}

CatMenu();