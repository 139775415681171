import '../scripty'; // to be able to use ES6
import './components/dropdown';
import './components/menu';
import './components/catMenu';
// import './components/animation';
import './components/progressPieChart';
import setrating from './components/rating';
import './components/map';
import './components/formBox';
// import './components/searchInput';
import './components/filter';
import './components/fullTextFilter';
import './components/lightbox';
// import './components/itemsEqualHeight';
import './components/showVideo';
import './components/toggleText';
import './components/heartPosition';
import './components/lowerFirstLetter';
import './components/fulltextSearch';
import './components/btnPending';
import './components/sticker';
import './components/sojkaPopup';
import './components/lazy';
import './components/fulltextSearchAdvanced';
import './cart/scrollTo'

import './homepage';
import './productDetail';
import './cart';
import './mujKosmas';
import './components/article-popup';
import GridPopup from './components/article-popup';

import GetPosition from './libs/getPosition';
import YoutubeInIframe from './components/youtubeIframe';

var switcher = require('./libs/jquery-switcher');
var tabs = require('./libs/jquery-tabs');
// var tip = require('./libs/jquery-tip');
var popUp = require('./libs/jquery-popUp');

var getPosition = new GetPosition();

var slickOpt = function (items) {
	return {
		lazyLoad: 'ondemand',
		slidesToShow: items,
		slidesToScroll: items,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: items - 1,
					slidesToScroll: items - 1
				}
			},
			{
				breakpoint: 1024,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: items - 2,
					slidesToScroll: items - 2
				}
			}, {
				breakpoint: 767,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: items - 3,
					slidesToScroll: items - 3
				}
			}, {
				breakpoint: 480,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: items - 4,
					slidesToScroll: items - 4
				}
			}]
	}
};

var vykladniOptions = function (items) {
	return {
		lazyLoad: 'ondemand',
		slidesToShow: items,
		slidesToScroll: items,
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: items + 3,
					slidesToScroll: items + 3
				}
			}, {
				breakpoint: 1200,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: items + 2,
					slidesToScroll: items + 2
				}
			}, {
				breakpoint: 1024,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: items,
					slidesToScroll: items
				}
			}, {
				breakpoint: 767,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: 3,
					slidesToScroll: items - 3
				}
			}, {
				breakpoint: 480,
				settings: {
					lazyLoad: 'ondemand',
					slidesToShow: 2,
					slidesToScroll: items - 2
				}
			}]
	}
};
window.Linksoft.vykladniOptions = vykladniOptions;



// TODO: Jendo, doumentace je zde http://kenwheeler.github.io/slick/
/*
$('.tabs--teasers .active .books').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
	// 'zde musis zjistit, kterej z nich je v tom radku';
});
*/

function logArticleThumbView(event, slick, currentSlide) {
	var idsStr = '';
	var index = 0;
	var showWindowId = $('.tabs__content.active:last').data('sw-id');
	$('.slick-active').each(function () {
		var currId = $(this).data("id");
		if (!(currId == null || currId == "" || currId == undefined)) {
			if (index > 0) {
				idsStr += ',';
			}
			idsStr += currId;
			index++;
		}
	});

	$.ajax({
		dataType: 'html',
		cache: false,
		data: { showWindowId: showWindowId, articleIds: idsStr },
		type: 'POST',
		url: Linksoft.Web.ActionUrls.RegisterThumbnailView
	});
}

var mainTeasersOpt = {
	lazyLoad: 'ondemand',
	slidesToShow: 9,
	slidesToScroll: 9,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 7,
				slidesToScroll: 7
			}
		},
		{
			breakpoint: 1024,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 5,
				slidesToScroll: 5
			}
		}, {
			breakpoint: 767,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 4,
				slidesToScroll: 4
			}
		}, {
			breakpoint: 480,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 2,
				slidesToScroll: 2
			}
		}]
};

const booksFromAuthor = {
	lazyLoad: 'ondemand',
	slidesToShow: 4,
	slidesToScroll: 4,
	responsive: [
		{
			breakpoint: 1199,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 3,
				slidesToScroll: 3
			}
		},
		{
			breakpoint: 1023,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 4,
				slidesToScroll: 4
			}
		},
		{
			breakpoint: 767,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 3,
				slidesToScroll: 3
			}
		}, {
			breakpoint: 480,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 2,
				slidesToScroll: 2
			}
		}]
};

// this one for fulltext grid
if (typeof window.Linksoft.CatGridInit !== 'object' ||
	typeof window.Linksoft.CatGridInit !== 'function') {
	window.Linksoft.CatGridInit = function ($context) {
		if (!$context) { $context = $('document'); }

		// $context.find('.grid-items .g-item__figure:not(.g-item__figure--in-text)').tip({
		// 	ownContent: true,
		// 	positionOfTip: 'top'
		// });

		setrating.init();

		$context.find('.rating__stars--readonly').barrating('readonly', true);

		Linksoft.Sticker.init();
	};
}

function initVideoInIframe($context) {
	// just for youtube video outside dropdown
	if ($context.find('.yt-iframe').length === 0) return;

	$context.find('.yt-iframe').each(function () {
		var $this = $(this);

		// just for items outside popup
		if ($this.closest('.pop-up__content').length === 0) {
			// same in /Scripts/src/components/youtubeIframe.js
			// in future update this (maybe :D)
			var link = $this.attr('data-yt-src');
			var iframe = `<iframe src="${link}" width="640" height="360"></iframe>`;
			if ($this.find('iframe').length) return;
			$this.append(iframe);
		}
	});
}

// teaser in cat
var $teaserCat = $('#teaser-cat');
$teaserCat.slick({
	lazyLoad: 'ondemand',
	centerMode: false,
	variableWidth: false,
	slidesToShow: 3,
	responsive: [
		{
			breakpoint: 1023,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 480,
			settings: {
				lazyLoad: 'ondemand',
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}]
});
// teaser in klub hp
var $klubHPteaser = $('#klubHPteaser');
$klubHPteaser.slick();
// tabs in klub
function tableTabs() {
	var $context = $('#bodyTabs');
	if ($context.find('.tabs').length) {

		$context.find('.tabs').each(function (i, elem) {
			$(elem).tabs();
		});
	}
}
tableTabs();

Linksoft.Web.OnLoadAndOnAjax(function ($context) {

	$context.find('.tabs--teasers, .tabs--teasers-flat').on('afterChange', logArticleThumbView);
	//$('.slick-arrow').on('click', logArticleThumbView);
	$context.on('click', '.slick-arrow', null, logArticleThumbView)

	// for teaser with 5 books in row
	$context.find('.section__books .books:not(.books--4)').slick(slickOpt(6));



	// for teaser with 4 books in row
	$context.find('.section__books .books--4').slick(booksFromAuthor);

	if ($context.find('.tabs--teasers').length) {
		$context.find('.tabs--teasers .active .books').slick(vykladniOptions(6));
		// console.log("tabs--teasers.tabs");
		$context.find('.tabs--teasers').each(function (i, elem) {
			var lastAfter = -999;
			// console.log("tabs--teasers.elem", {elem: elem});

			$(elem).tabs({
				beforeInit: function (a, b, c) {
					var newId = b.data('tab-id');
					var activeId = $(elem).find('.active').data('tab-id');
					lastAfter = activeId;
					// console.log("tabs--teasers.beforeInit(" + newId + "," + activeId + ")", { a: a, b: b, c: c});
					if (newId === activeId) return;
					// console.log("  process");
					$(elem).find('.active .books').slick('unslick');
				},
				afterInit: function (a, b, c) {
					var activeId = $(elem).find('.active').data('tab-id');
					// console.log("tabs--teasers.afterInit(" + activeId + ")", { a: a, b: b, c: c });
					if (lastAfter === activeId) return;
					// console.log("  process");
					$(elem).find('.active .books').slick(vykladniOptions(6));
					logArticleThumbView();
				}
			})
		});
	}
	// first view
	if ($context.find('.tabs--teasers-flat').length) {
		$context.find('.tabs--teasers-flat .active .books')
			.slick(mainTeasersOpt);

		$context.find('.tabs--teasers-flat').tabs({
			beforeInit: function ($wrap, $content) {
				// for smooth switching
				// $('.books', $content).css({opacity: 0});

				$('.tabs--teasers-flat .active .books', document).slick('unslick');
			},
			afterInit: function () {
				var $activeCarousel = $('.tabs--teasers-flat .active .books', document);

				$activeCarousel.slick(mainTeasersOpt);

				// for smooth switching
				// setTimeout(() => {
				//     $activeCarousel.css({opacity: 1});
				// }, 300 )

				logArticleThumbView();
			}
		});
	}

	$context.find('.books, .books--4').on('lazyLoaded', function (e, s, img) {
		$(img).closest('.book__item-inner').addClass('end-loading');
	});


	$context.find('.books, .books--4').on('lazyLoadError', function (e, s, img) {
		$(img).closest('.book__item-inner').addClass('end-loading');
	});

	$context.find('.tabs--default').tabs();

	{

		// muj kosmas - audio
		$context.find('.switcher').switcher();

		$context.find('.tip__wrap').tip();
		$context.find('.tip__wrap--bot').tip({
			positionOfTip: 'bottom'
		});

		$context.find('.tip__wrap--top:not(.tip__wrap--on-click)').tip({
			positionOfTip: 'top'
		})

		$context.find('.tip__wrap--on-click').tip({
			onClick: true, // open on click too
			positionOfTip: 'top'
		})

		// .pop-up--illu is in productDetail.js
		$context.find('.pop-up:not(.header__oko):not(#basket-in):not(.title-with-heart):not(.pop-up--illu)').popUp();
		$context.find('.title-with-heart').popUp({
			setCenter: false,
			beforeOpen: function (thisWrap, trigger) {
				var $content = $(thisWrap).find('.pop-up__content');
				var wrapOffsetLeft = $(thisWrap).offset().left;
				var triggerOffsetLeft = $('.title-with-heart .pop-up__toggle').offset().left;
				// centering to icon heart
				$content.css({
					left: triggerOffsetLeft - wrapOffsetLeft - ($content.outerWidth() / 2) + getPosition.center(trigger[0])
				});
			}
		});

		$context.find('.header__oko').popUp({
			setCenter: false,
			afterOpen: function (wrap, trigger) {
				var $wrap = $(wrap);
				var $content = $(wrap).find('.oko-menu');
				var $trigger = $(trigger);
				var right = $('.header').width() - ($trigger.outerWidth() + $(trigger).offset().left - $('.header').offset().left);

				// make oko popup align right to screen
				$content.css('right', -right + 20); // 5 magic pixels
			}
		});

		$context.find('#basket-in').popUp({ setCenter: false });

		$context.find('select').not('.rating__stars, .select-no-style, .field-selectbox-filter, .shop__list').selectric();
	}

	// $context.find('.dropdown:not(.cart__for-later)').dropdown({ triggerType: 'check' });
	// $context.find('.dropdown--no-yes').dropdown({ triggerType: 'switcher' });

	// // muj kosmas - audio
	// $context.find('.g-item__col-left, .cart__for-later').dropdown();

	// $context.find('.switcher').switcher();

	// $context.find('.tip__wrap').tip();

	// $context.find('.pop-up').popUp();

	// $context.find('.grid-items .grid-item').tip({
	//     ownContent: true,
	//     positionOfTip: 'top'
	// });
	$context.find('.magnific__iframe').magnificPopup({
		disableOn: 700,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,

		fixedContentPos: false
	});

	$context.find('.magnific__gallery').each(function () {
		// the containers for all your galleries
		$(this).magnificPopup({
			delegate: 'a', // the selector for gallery item
			type: 'image',
			gallery: {
				enabled: true
			}
		});
	});
	const newGridPopUp = new GridPopup();

	$context.find('.g-item__figure').on("mouseenter", function (e) {
		const $articlePopup = $(this).find('.article__popup');
		newGridPopUp.positionPopUp($articlePopup);
	});

	$context.find('.g-item__figure').on("mouseleave", function (e) {
		const $articlePopup = $(this).find('.article__popup');
		newGridPopUp.resetPopUpPosition($articlePopup);
	});

	$context.find('.open-delivery-dialog').magnificPopup({
		type: 'inline',
		showCloseBtn: false,
		callbacks: {
			open: function (a, b, c) {
				console.log("magnificPopup", { a: a, b: b, c: c, ths: this });
				var setup = this.content.data("setup");
				var init = this.content.data("init");
				if (setup && !this.content.hasClass("initialized")) {
					eval(setup);
					this.content.addClass("initialized")
				}
				if (init) {
					eval(init);
				}
			},
			close: function () {
				// Will fire when popup is closed
			}
			// e.t.c.
		}
	});

	window.Linksoft.CatGridInit($context);
	initVideoInIframe($context);
});

smoothScroll.init();
