import './../libs/markerclusterer';

console.logT = function (msg, params) {
	if (params === undefined) {
		console.log((new Date()).format("HH:mm:ss.ff") + ': ' + msg);
	} else {
		console.log((new Date()).format("HH:mm:ss.ff") + ': ' + msg, params);
	}
};

let pickupId = $('#orderShipmentBody').data('pickup-id');
let $list = $('#deliveryDialogList', document);
let $gmap = $('#deliveryDialogMap', document);
let $pic = $('#deliveryDialogPic', document);
let $checkboxes = $('#deliveryProviderCheckboxes input', document);
let $filterTextbox = $('#deliveryFilterTextbox', document);
let $selectedPoints = $('.selectedPointPersonal', document);
let $selectedPoint = $('#selectedPointPersonal', document);
const mapCenter = {
	0: { lat: 49.7556898, lng: 15.8265969 },
	175: { lat: 48.6603512, lng: 19.6716652 },
	113: { lat: 47.0946647, lng: 19.6167335 },
};

let isMapOn = false;

let isInit = false;
let deliveryPointsInternal = null;
let deliveryPointsExternal = null;
let internalExternal = null;
let currentCountry;
let pointsForCountry = {};
let mapPoints = [];
let markers = [];
let map, markerCluster;
let aboveFirstItem = 0;

class DeliveryPointList {
	constructor() {
	}

	getInternalDeliveryPoints() {
		if (deliveryPointsInternal) {
			return;
		} else {
			return $.ajax({
				url: '/kosik/DeliveryPoints?isExternal=false',
				error: function () {
				},
				success: function (data) {
					deliveryPointsInternal = data;
				}
			});
		}
	}

	getExternalDeliveryPoints() {
		if (deliveryPointsExternal) {
			return;
		} else {
			return $.ajax({
				url: '/kosik/DeliveryPoints?isExternal=true',
				error: function () {
				},
				success: function (data) {
					deliveryPointsExternal = data;
				}
			});
		}
	}

	emptyList() {
		$list.empty().addClass('loading');
	}

	resetCountrySpecificStuff() {
		currentCountry = $('#country').val();

		//ajax updated the whole section, the variables point to tags that no longer exist
		console.logT("reset jquery vars");
		$list = $('#deliveryDialogList', document);
		$gmap = $('#deliveryDialogMap', document);
		$pic = $('#deliveryDialogPic', document);
		$checkboxes = $('#deliveryProviderCheckboxes input', document);
		$filterTextbox = $('#deliveryFilterTextbox', document);
		$selectedPoints = $('.selectedPointPersonal', document);
		$selectedPoint = $('#selectedPointPersonal', document);
	}

	initList() {
		const self = this;
		self.emptyList();

		console.logT('init called');
		if (currentCountry === undefined) {
			// no country yet
			console.logT('no country');

			this.resetCountrySpecificStuff();
		} else if (currentCountry === $('#country').val()) {
			// same country as last time
			console.logT('same country');
		} else {
			// different country than last time
			console.logT('switch country');
			if (internalExternal) {
				pointsForCountry[currentCountry] = internalExternal;
				console.logT('save points');
			}
			internalExternal = deliveryPointsInternal = deliveryPointsExternal = pointsForCountry[$('#country').val()];

			this.resetCountrySpecificStuff();
			map = undefined;
			markerCluster = undefined;
			this.setGMap();
		}

		if (internalExternal) {
			console.logT("had data");
			console.logT("rendering " + internalExternal.length + " points");
			self.renderPointers(internalExternal);
			self.showList();
			aboveFirstItem = $list.find('.dropdown:first').position().top;

			/*
						console.logT("clicking selected point (had data)");
						var selectedId = $("#deliveryPointId").val();
						if (selectedId) {
							$('#d-d-i--' + selectedId + ' .dropdown__trigger').click();
						}
			*/
		} else {
			console.logT("needs to load data");
			$.when(
				self.getInternalDeliveryPoints(),
				//				self.getExternalDeliveryPoints()
			)
				.done(function () {
					console.logT("got data");
					internalExternal = deliveryPointsInternal //.concat(deliveryPointsExternal);
					console.logT("rendering " + internalExternal.length + " points");
					self.renderPointers(internalExternal);
					console.logT("rendered " + internalExternal.length + " points");
					self.showList();
					aboveFirstItem = $list.find('.dropdown:first').position().top;
					/*
										console.logT("clicking selected point (loaded data)");
										var selectedId = $("#deliveryPointId").val();
										if (selectedId) {
											$('#d-d-i--' + selectedId + ' .dropdown__trigger').click();
										}
					*/
				});
		}
	}

	_find(filter) {
		var found = 0;
		for (var data of internalExternal) {
			if (filter(data)) {
				found++;
				$('#d-d-i--' + data.Id).removeClass("filtered");
			} else {
				$('#d-d-i--' + data.Id).addClass("filtered");
			}
		}
		return found;
	}

	filterList() {
		var filterString = $filterTextbox.val().trim().toLowerCase();
		if (filterString) {
			var filter;
			var match;
			if ((match = filterString.match(/^(\d\d\d) ?(\d\d)$/))) {
				let zip = match[1] + ' ' + match[2];
				filter = (data) => data.ZIP === zip;
			} else if ((match = filterString.match(/^(\d\d\d) ?(\d)$/))) {
				let zip = match[1] + ' ' + match[2];
				filter = (data) => data.ZIP.startsWith(zip);
			} else if ((match = filterString.match(/^[0-9 ]+$/))) {
				let zip = match[0].replace(' ', '');
				filter = (data) => data.ZIP.startsWith(zip);
			} else {
				filter = (data) => data.City.toLowerCase().startsWith(filterString);
			}

			if (this._find(filter) === 0) {
				filter = (data) => data.Street.toLowerCase().startsWith(filterString) || data.Name.toLowerCase().startsWith(filterString);
				if (this._find(filter) === 0) {
					filter = (data) => data.City.toLowerCase().includes(filterString) || data.Street.toLowerCase().includes(filterString) || data.Name.toLowerCase().includes(filterString);
					this._find(filter);
				}
			}

		} else {
			for (var data of internalExternal) {
				$('#d-d-i--' + data.Id).removeClass("filtered");
			};
		}
		this.showList();
	}

	showList() {
		$('.d-d-i.filtered', $list).addClass('hidden');

		$checkboxes.each(function () {
			var $this = $(this);
			var provider = $this.data("provider-code");
			if ($this.is(':checked')) {
				console.logT("provider " + provider + " is checked");
				$('.d-d-i:not(.filtered).d-d-i--p-' + provider, $list).removeClass('hidden');
			} else {
				console.logT("provider " + provider + " is NOT checked");
				$('.d-d-i--p-' + provider, $list).addClass('hidden');
			}
		});

		this.initGmap();
		this.hideDetail();
	}

	renderPointers(data) {
		const self = this;

		var servicePrice = parseInt($('#deliveryService' + pickupId).data("price"));
		//console.logT("data52", { data: data[52] });

		// reset
		mapPoints = [];

		$list = $('#deliveryDialogList', document);
		console.logT("remove class loading", $list);
		$list.removeClass('loading');
		$list.empty();

		console.logT("add points");
		var html = '';
		$.each(data, function (i, val) {
			//			console.logT(" start");
			let isInternal = val.IsInternal ? true : false;
			let imgUrl = isInternal ? '/images/svg/logo.svg' : '/Content/Layout/img/DeliveryPoint_' + val.ProviderCode + '.png';
			let itemClass = isInternal ? 'd-d-i--p-INT' : 'hidden d-d-i--p-' + val.ProviderCode;
			let intPrice = parseInt(val.Price);
			let price = servicePrice > 0 || intPrice > 0 ? (servicePrice + intPrice) + '&nbsp;Kč' : 'zdarma';
			let express = val.IsExpress ? `<div class="d-d-i__mid"><span class="tip__wrap--top" data-tip="TIPTEXT">Expres</span></div>` : '';
			if (express) {
				if (val.Id == 26491) {
					express = express.replace("TIPTEXT", "U výdejního místa v centrálním skladu v Horoměřicích se expediční doba obvykle pohybuje kolem 2 hodin.");
				} else {
					express = express.replace("TIPTEXT", "Pokud objednáte ve všední den do 10:00, balíček pro vás bude připraven ještě ten samý den po 17:00.");
				}
			}
			let tel = val.Phone1 != null ? `<br><span class="red">Tel: ${val.Phone1}</span>` : '';
			let email = val.Email != null ? `<br><a href="mailto:${val.Email}">${val.Email}</a>` : '';
			let city = val.City != null ? `${val.City}, ` : '';

			if (!val.Monday) { val.Monday = "Zavřeno"; } else { val.Monday = val.Monday.replace(', ', '<br/>') }
			if (!val.Tuesday) { val.Tuesday = "Zavřeno"; } else { val.Tuesday = val.Tuesday.replace(', ', '<br/>') }
			if (!val.Wednesday) { val.Wednesday = "Zavřeno"; } else { val.Wednesday = val.Wednesday.replace(', ', '<br/>') }
			if (!val.Thursday) { val.Thursday = "Zavřeno"; } else { val.Thursday = val.Thursday.replace(', ', '<br/>') }
			if (!val.Friday) { val.Friday = "Zavřeno"; } else { val.Friday = val.Friday.replace(', ', '<br/>') }
			if (!val.Saturday) { val.Saturday = "Zavřeno"; } else { val.Saturday = val.Saturday.replace(', ', '<br/>') }
			if (!val.Sunday) { val.Sunday = "Zavřeno"; } else { val.Sunday = val.Sunday.replace(', ', '<br/>') }

			var header = isInternal
				? `${val.Name}, ${val.ZIP} - ${val.Street}`
				: `${val.City}, ${val.ZIP}, ${val.Street} - ${val.Name} `;
			if (val.Comment) {
				header += "<br/><b class=\"red\">" + val.Comment + "</b>";
			}
			//			console.logT(" middle");

			val.details = `
                <span class="p-p-i__picker">
                    <strong>${val.Name}</strong><br>
                    ${val.Street},<br>
                    ${city}${val.ZIP}<br>
                    <button data-id="${val.Id}" data-index="${i}" data-cashcost="${val.CashOnDeliveryCost}" class="btn btn--sm">Zvolit prodejnu</button>
                </span>
                ${tel}
                ${email}
                <div class="shop__time">
                    <table class="store_hours">
                        <tr><td>Pondělí</td><td>${val.Monday}</td></tr>
                        <tr><td>Úterý</td><td>${val.Tuesday}</td></tr>
                        <tr><td>Středa</td><td>${val.Wednesday}</td></tr>
                        <tr><td>Čtvrtek</td><td>${val.Thursday}</td></tr>
                        <tr><td>Pátek</td><td>${val.Friday}</td></tr>
                        <tr><td>Sobota</td><td>${val.Saturday}</td></tr>
                        <tr><td>Neděle</td><td>${val.Sunday}</td></tr>
                    </table>
					<div class="extra_store_hours">${val.SpecialOpeningHours}</div>
                </div>
`;

			html += `
            <div class="dropdown d-d-i ${itemClass}" id="d-d-i--${val.Id}"
                    data-i="${i}"
                    data-id="${val.Id}"
                    data-lat="${val.Lat}"
                    data-lng="${val.Lng}"
                    data-img="${val.PictureUrl ? val.PictureUrl : ""}"
                    data-is-internal="${isInternal}">
                <div class="dropdown__trigger bar bar--lines">
                    <div class="d-d-i__first">
                        <img src="${imgUrl}" />
                        <strong>${header}</strong>
                    </div>
                    ${express}
                    <div class="d-d-i__last">${price}</div>
                    <i class="pikto--plus"></i>
                </div>
                <div class="dropdown__content dropdown__content--no-border">
                </div>
            </div>
`;
		});
		console.logT(" built");
		$list.append(html);
		console.logT(" appended");


		$('.dropdown__trigger', $list).on('click', function () {
			const $this = $(this);

			if (!$this.hasClass('active')) {
				// close other dropdowns
				$('.dropdown', $list).removeClass('active');
				$('.dropdown__trigger', $list).removeClass('active');
				$('.dropdown__content', $list).removeClass('active').attr('style', '');
			}

			var i = $this.parent().data("i");
			if (internalExternal[i].details) {
				var $content = $this.parent().find('.dropdown__content');
				$content.html(internalExternal[i].details);
				$content.find('.btn').on('click', function () {
					const $this = $(this);

					$('#deliveryService' + pickupId).val($this.data('id'));

					console.logT(`vybral jsem ${$this.data('id')}`);
					$("#deliveryPointId").val($this.data('id'));
					/*
					var cashCost = $this.data("cashcost");
					console.log("cashCost=" + cashCost, { "$this": $this });
					if (cashCost) {
						$('#orderPayment label[for="paymentOption14"] .c-check__price').html(cashCost + "&nbsp;Kč");
					} else {
						$('#orderPayment label[for="paymentOption14"] .c-check__price').html("");
					}
					*/
					Linksoft.Cart.ChangeDeliveryService(5, $this.data('id'));
					$('#deliveryDialogList').empty();
					$.magnificPopup.close();
					$('#deliveryService' + pickupId).prop('checked', true);

					self.showSelected(data[$this.data('index')]);
				});
				delete internalExternal[i].details;
			}

			setTimeout(() => {
				if ($this.hasClass('active')) {
					self.showDetail($this.parent().data('img'), $this);
				} else {
					self.hideDetail();
				}
			}, 50);
		});
		console.logT("dropdown__trigger installed");
	}


	showDetail(imgUrl, $elm) {
		let bounds = new google.maps.LatLngBounds();
		let loc = new google.maps.LatLng($elm.parent().data('lat'), $elm.parent().data('lng'));
		bounds.extend(loc);
		map.fitBounds(bounds);
		map.panToBounds(bounds);
		map.setZoom(14);

		// $list.addClass('delivery-dialog__list--open');
		$pic.empty();
		if (imgUrl) {
			$pic.append(`<img src="${imgUrl}" style="width: 100%;height: auto;">`);
		}

		$list.animate({
			scrollTop: $list.scrollTop() + $elm.offset().top - $list.offset().top
		}, 400);

	}

	hideDetail() {
		// $list.removeClass('delivery-dialog__list--open');
		$pic.empty();
	}

	showSelected(point) {
		var servicePrice = parseInt($('#deliveryService' + pickupId).data("price"));
		let price = servicePrice > 0 || parseInt(point.Price) > 0 ? (servicePrice + point.Price) + '&nbsp;Kč' : 'zdarma';
		let express = point.IsExpress ? 'Expres' : '';

		const selectedHtml = `
          <div class="open-delivery-dialog__holder">
            <div class="open-delivery-dialog__selected">
                <div>
                    <span><strong>${point.Name} - ${point.Street}, ${point.City}</strong>
                    (<a href="#" onclick="$('#deliveryServiceSection${pickupId}i').click()">změnit</a>)</span>
                </div>
                <div class="c-check__express">${express}</div>
                <div class="c-check__price">${price}</div>
            </div>
          </div>
		`;


		$selectedPoints.empty(); // all providers
		$selectedPoint.append(selectedHtml); // internal
	}


	setMarkers() {
		const self = this;
		const infoWindow = new google.maps.InfoWindow();
		mapPoints = [];

		// get mapPoints
		$('.d-d-i:not(.hidden)', $list).each(function () {
			const $this = $(this);
			let latLang = [
				parseFloat($this.data('lat')),
				parseFloat($this.data('lng')),
				$this.data('is-internal'),
				$this.data('id')
			];
			mapPoints.push(latLang);
		});

		if (markerCluster != null) {
			markerCluster.clearMarkers();
			markers = [];
		}

		for (let i = 0; i < mapPoints.length; i++) {
			let shop = mapPoints[i];

			let lat = shop[0];
			let lng = shop[1];
			let isInternal = shop[2]; // for pointer icons
			let imgUrl = isInternal ? '/images/pin.png' : '/images/pin-ext.png';

			let marker = new google.maps.Marker({
				position: { lat: lat, lng: lng },
				animation: google.maps.Animation.DROP,
				map: map,
				icon: {
					url: imgUrl,
					size: new google.maps.Size(39, 60),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(0, 60)
				}
			});


			// from google map
			google.maps.event.addListener(marker, 'click', (function (marker, i) {
				return function () {
					let $detail = $('#d-d-i--' + shop[3], $list);
					let imgUrl = $detail.data('img');

					$('.dropdown__trigger', $detail).trigger('click');
					// self.showDetail(imgUrl, $detail);
				}
			})(marker, i));

			markers.push(marker);
		}



		let clusterStyles = [
			{
				textSize: 16,
				textColor: 'white',
				url: '/images/round/round1.png',
				height: 47,
				width: 47
			}
		];

		console.logT('call new MarkerClusterer', { map: map, markers: markers });
		markerCluster = new MarkerClusterer(map, markers, {
			styles: clusterStyles,
			imagePath: '/images/round/round'
		});

		console.logT("clicking selected point (setGMap)");
		var selectedId = $("#deliveryPointId").val();
		if (selectedId) {
			$('#d-d-i--' + selectedId + ' .dropdown__trigger').click();
		}
	}

	setGMap() {
		const self = this;

		const option = {
			zoom: 6,
			center: mapCenter[currentCountry] || mapCenter[0]
		}

		console.logT("set map");
		map = new google.maps.Map($gmap[0], option);

		self.setMarkers();
	}

	initGmap() {

		if (isMapOn) {
			this.setMarkers();
			return;
		}

		// set same height

		window.linksoftInitDialogGmap = () => {
			this.setGMap();
		}

		var googleInitCode = '<script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD9xnx6xzDiwveuzHApDmEJ-KxNQabixgE&callback=window.linksoftInitDialogGmap"></script>';
		$(googleInitCode).appendTo(document.body);

		isMapOn = true;
	}




	init() {
		const self = this;

		//if ( isInit ) return;

		self.initList();
		$(document).on("keydown", (event) => {
			if ($list.is(':visible')) {
				if (event.keyCode == 40) { // down
					var isActive = $list.find('.dropdown.active').length > 0;
					var pos = $list.scrollTop();
					console.logT("now", { pos: pos, aboveFirstItem: aboveFirstItem });
					for (var item of $list.find('.dropdown:not(.hidden)')) {
						var $item = $(item);
						console.logT("top", $item.position().top - aboveFirstItem);
						if ($item.position().top - aboveFirstItem > 0) {
							$list.scrollTop(pos + $item.position().top - aboveFirstItem);
							if (isActive) $item.find('.dropdown__trigger').trigger('click');
							return;
						}
					}
				} else if (event.keyCode == 38) { // up
					var isActive = $list.find('.dropdown.active').length > 0;
					var pos = $list.scrollTop();
					if (pos == 0) return;
					console.logT("now", { pos: pos, aboveFirstItem: aboveFirstItem });
					var list = $list.find('.dropdown:not(.hidden)');
					for (var id in list) {
						var $item = $(list[id]);
						console.logT("top", { item: $item, top: $item.position().top - aboveFirstItem });
						if ($item.position().top - aboveFirstItem >= -2) { // rozbalené jsou kousek posunuté
							if (id == 0) return;
							console.logT("scrollTo", { item: $(list[id - 1]), top: $(list[id - 1]).position().top - aboveFirstItem });
							$list.scrollTop(pos + ($(list[id - 1]).position().top - aboveFirstItem));
							if (isActive) $(list[id - 1]).find('.dropdown__trigger').trigger('click');
							return;
						}
					}
				}
			}
		});

		// input update
		$checkboxes.on('change', () => {
			this.showList();
		});

		$filterTextbox.on('input', () => {
			this.filterList();
		});

		//
		// $list.on('click', function(){
		//     const $this = $(this);
		//     setTimeout(()=>{
		//         if ( $('.dropdown').hasClass('active') ) {
		//             $this.addClass('delivery-dialog__list--open');
		//         } else {
		//             $this.removeClass('delivery-dialog__list--open');
		//         }
		//     }, 100);
		// });

		//isInit = true;
	}
}

if (typeof window.Linksoft.DeliveryPointList !== 'object' ||
	typeof window.Linksoft.DeliveryPointList !== 'function') {
	window.Linksoft.DeliveryPointList = new DeliveryPointList;
}

window.Linksoft.DeliveryPointProvider = {
	KS_Setup: function () {
		var link = document.createElement("link");
		link.rel = "stylesheet";
		link.href = "https://www.ppl.cz/sources/map/main.css";

		// Create a script element to load the main.js file
		var script = document.createElement("script");
		script.src = "https://www.ppl.cz/sources/map/main.js";

		// Add the script+href link to the document head
		document.head.appendChild(link);
		document.head.appendChild(script);

		document.addEventListener("ppl-parcelshop-map", (event) => {
			console.log("Vybraný parcel shop:", event.detail);

			var point = event.detail;

			const selectedHtml = `
			  <div class="open-delivery-dialog__holder">
				<div class="open-delivery-dialog__selected">
					<div>
						<span><strong>${point.name} - ${point.street}, ${point.city}</strong>
						(<a href="#" onclick="$('#deliveryServiceSection${pickupId}eKS').click()">změnit</a>)</span>
					</div>
				</div>
			  </div>
			`;


			$selectedPoints.empty(); // all providers
			$('#selectedPointPersonalKS', document).append(selectedHtml); // PPL

			Linksoft.Cart.ChangeDeliveryServiceExternal(5, $('#countryId').val(), 'KS', point.code);

			$.magnificPopup.close()
		});
		console.log("setup PP with listeneR");
	},
	KS_Init: function () {
		console.log("init PPL");
	},
	X2_Setup: function () {
		console.log("setup Packetaa");
		var script = document.createElement("script");
		script.src = "https://widget.packeta.com/v6/www/js/library.js";

		document.head.appendChild(script);
	},
	X2_Init: function () {
		console.log("init Packeta");

		if (!window.Packeta) {
			window.setTimeout(window.Linksoft.DeliveryPointProvider.X2_DoInit, 500);
		} else {
			window.Linksoft.DeliveryPointProvider.X2_DoInit();
		}
	},
	X2_DoInit: function () {
		if (!window.Packeta) {
			window.setTimeout(window.Linksoft.DeliveryPointProvider.X2_DoInit, 500);
			return;
		}

		window.Linksoft.DeliveryPointProvider.X2_opened = new Date();
		var $container = $('#packeta-map');
		Packeta.Widget.pick(
			$container.data('apikey'),
			window.Linksoft.DeliveryPointProvider.X2_Handle,
			{
				webUrl: 'https://www.kosmas.cz',
				appIdentity: 'kosmas-14224',
				apiKey: $container.data('apikey'),
				country: $container.data('countries'),
				language: 'cs'
			},
			$container[0]
		);

	},
	X2_Handle: function (point) {
		console.log('selected point', point)

		if (((new Date()) - window.Linksoft.DeliveryPointProvider.X2_opened) < 1000) {
			return;
		}

		if (point) {
			const selectedHtml = `
			  <div class="open-delivery-dialog__holder">
				<div class="open-delivery-dialog__selected">
					<div>
						<span><strong>${point.place} - ${point.nameStreet}</strong>
						(<a href="#" onclick="$('#deliveryServiceSection${pickupId}eX2').click()">změnit</a>)</span>
					</div>
				</div>
			  </div>
			`;


			$selectedPoints.empty(); // all providers
			$('#selectedPointPersonalX2', document).append(selectedHtml); // PPL

			Linksoft.Cart.ChangeDeliveryServiceExternal(5, $('#countryId').val(), 'X2', point.id);
		}

		$.magnificPopup.close()
	},
}